import { useMutation, useQuery } from '@apollo/client'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Alert,
  Button,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
  Spinner,
} from 'reactstrap'
import AppSplash from '../../components/AppSplash'
import Image from '../../components/Image'
import { GET_NFT_BY_ID, EDIT_NFT, RESEND_NFT } from './actions'
import { FaRocket } from 'react-icons/fa'
import { statusColors } from '../../utils/status'
import { toast } from 'react-toastify'
import Confirm from '../../components/Confirm'
import ReactTooltip from 'react-tooltip'

export default function NFTEditor({ nftId, toggle }) {
  const [link, setLink] = useState()
  const [skipBackgroundRemoval, setSkipBackgroundRemoval] = useState(false)
  const [status, setStatus] = useState()
  const [statusMsg, setStatusMsg] = useState()
  const [resendDialogIsOpen, setResendDialogIsOpen] = useState(false)

  const { loading, data, error } = useQuery(GET_NFT_BY_ID, {
    variables: {
      id: nftId,
    },
    onCompleted: ({ nftById }) => {
      setLink(nftById?.link)
      setStatus(nftById?.status)
      setStatusMsg(nftById?.statusMsg)
      setSkipBackgroundRemoval(nftById?.skipBackgroundRemoval)
    },
    fetchPolicy: 'network-only',
  })

  const [editNFT, { loading: loadingEdit }] = useMutation(EDIT_NFT, {
    onError: (error) => {
      toast.error(error?.message)
    },
    onCompleted: ({ editNFT }) => {
      setLink(editNFT?.link)
      setSkipBackgroundRemoval(editNFT?.skipBackgroundRemoval)
      toast.success('NFT has been saved!')
    },
  })

  const [resendNft, { loading: loadingResend }] = useMutation(RESEND_NFT, {
    onError: (error) => {
      toast.error(error.message)
    },
    onCompleted: ({ resendNFT }) => {
      setStatus(resendNFT?.status)
      setStatusMsg(resendNFT?.statusMsg)
      toast.success('NFT has been resent!')
    },
  })

  if (loading) {
    return <AppSplash />
  }

  if (error) {
    return <Alert color="danger">An error ocurred. {error.message}</Alert>
  }

  const { originalData, user, nftCollection, token, finalLink, chain } =
    data.nftById

  const toggleResendDialog = () => setResendDialogIsOpen(!resendDialogIsOpen)

  return (
    <div className="p-3">
      <ReactTooltip effect="solid" id="skip-bg-tooltip">
        This option skips the BG removal for collections that have BG removal
        enabled.
        <div>
          This is useful to manually remove the BG and skip the BG removal from
          admin.
        </div>
      </ReactTooltip>
      <Confirm
        isOpen={resendDialogIsOpen}
        toggle={toggleResendDialog}
        message={
          <div className="text-center">
            Do you want to resend the NFT to the production creator?
            <div className="text-danger">
              This will create products inside the maddies store.
            </div>
          </div>
        }
        onYesClicked={() => {
          resendNft({
            variables: {
              id: nftId,
            },
          })
        }}
      />
      <Row>
        <Col md={2}>
          <Image src={link} />
          {link !== originalData.link && (
            <>
              <hr />
              <Image src={originalData.link} subtitle="original" />
            </>
          )}
          {finalLink && (
            <>
              <hr />
              <Image src={finalLink} subtitle="final link" />
            </>
          )}
        </Col>
        <Col>
          <FormGroup>
            <Label>Image link</Label>
            <Input
              value={link}
              onChange={({ target: { value } }) => setLink(value)}
            />
          </FormGroup>
          <Row>
            <Col>
              <FormGroup>
                <Label>Token</Label>
                <Input value={token} disabled />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>Status</Label>
                <div>
                  <Button block disabled color={statusColors[status]}>
                    {status}
                  </Button>
                </div>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup inline>
                <Label>Collection</Label>
                <Link to={`/collections/${nftCollection?.id}`} target="_blank">
                  <Input value={nftCollection?.identifier} disabled />
                </Link>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup inline>
                <Label>User</Label>
                <Input value={`${user?.userId} - ${user?.userName}`} disabled />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>
                  <span style={{ color: 'transparent' }}>T</span>
                </Label>
                <InputGroup>
                  <Button
                    onClick={() => {
                      setSkipBackgroundRemoval(!skipBackgroundRemoval)
                    }}
                    color={skipBackgroundRemoval ? 'success' : 'secondary'}
                  >
                    {skipBackgroundRemoval ? 'Yes' : 'No'}
                  </Button>
                  <InputGroupText data-tip="" data-for="skip-bg-tooltip">
                    Skip Background Removal
                  </InputGroupText>
                </InputGroup>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup inline>
                <Label>Chain</Label>
                <Input value={chain} disabled />
              </FormGroup>
            </Col>
          </Row>
          {statusMsg && (
            <Alert className="mt-3 mb-3 text-center" color="warning">
              {statusMsg}
            </Alert>
          )}
          <hr />
          <Row>
            <Col md={3}>
              <Button
                block
                color="secondary"
                outline
                onClick={toggleResendDialog}
              >
                {loadingResend && <Spinner size="sm" />} Re-send <FaRocket />
              </Button>
            </Col>
            <Col md={{ offset: 5, size: 2 }}>
              <Button
                block
                color="primary"
                onClick={() => {
                  editNFT({
                    variables: {
                      id: nftId,
                      link,
                      skipBackgroundRemoval,
                    },
                  })
                }}
              >
                {loadingEdit && <Spinner size="sm" />} Save
              </Button>
            </Col>
            <Col>
              <Button block color="secondary" onClick={toggle}>
                Close
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}
