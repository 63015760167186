import React, { useEffect, useState } from 'react'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  Badge,
  FormGroup,
  Label,
  Input,
  Progress,
  Table,
  Spinner,
} from 'reactstrap'
import { connect } from 'react-redux'
import Select from 'react-select'
import { RiDeleteBinLine } from 'react-icons/ri'
import { toast } from 'react-toastify'
import Confirm from '../../components/Confirm'
import axios from 'axios'

const options = [
  { value: 'add-ethereum', label: 'ETH' },
  { value: 'add-tezos', label: 'XTZ' },
  { value: 'add-solana', label: 'SOL' },
]

const CollectionDownloader = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [collections, setCollections] = useState([])
  const [contractAddress, setContractAddress] = useState('')
  const [chain, setChain] = useState('Chain')
  const [extension, setExtension] = useState('')
  const [slug, setSlug] = useState('')
  const [subRoute, setSubRoute] = useState('')

  const [loading, setLoading] = useState(false)
  const [confirmDeletionIsOpen, setConfirmDeletionIsOpen] = useState(false)
  const [onYesClickedRemoveDownload, setOnYesClickedRemoveDownload] = useState(
    () => {}
  )

  const bodys = {
    'add-ethereum': {
      contract_address: contractAddress,
      chain,
      extension,
    },
    'add-tezos': {
      contract_address: contractAddress,
      chain,
      extension,
      slug,
    },
    'add-solana': {
      chain,
      extension,
      slug,
    },
  }

  const toggle = () => setIsOpen(!isOpen)
  const handleChange = (e) => {
    setSubRoute(e.value)
    setChain(e.label)
  }

  const fetchCollections = async () => {
    setLoading(true)
    try {
      // eslint-disable-next-line no-undef
      const url = new URL(`${process.env.REACT_APP_API_URL}/downloader/list`)
      const { data } = await axios.get(url)
      setCollections(data)
    } catch (error) {
      toast.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  const add = async (route) => {
    // eslint-disable-next-line no-undef
    const url = new URL(`${process.env.REACT_APP_API_URL}/downloader/${route}`)
    try {
      await axios.post(url, bodys[route])
      toast.success('Collection added for download.')
      setTimeout(() => {
        fetchCollections()
      }, 2000)
    } catch (error) {
      toast.error(`Error adding collection for download. ${error.message}`)
    }
  }

  const deleteCollection = async (name) => {
    const url = new URL(
      // eslint-disable-next-line no-undef
      `${process.env.REACT_APP_API_URL}/downloader/delete/${name}`
    )
    try {
      await axios.delete(url)
      toast.success(`Collection download removed. (${name})`)
      setTimeout(() => {
        fetchCollections()
      }, 2000)
    } catch (error) {
      toast.error(
        `Error deleting download process. (${name}) - ${error.message}`
      )
    }
  }

  const Status = ({ status }) => {
    let statusColor = 'secondary'
    if (status === 'online') {
      statusColor = 'success'
    }
    return (
      <Badge color={statusColor} pill>
        {status}
      </Badge>
    )
  }

  useEffect(() => {
    fetchCollections()

    // Re-fetch every 1min
    const interval = setInterval(() => {
      fetchCollections()
    }, 60 * 1000)

    return () => clearInterval(interval)
  }, [])

  return (
    <div>
      <Confirm
        message={`Do you really want to delete the download?`}
        onYesClicked={onYesClickedRemoveDownload}
        isOpen={confirmDeletionIsOpen}
        toggle={() => setConfirmDeletionIsOpen((v) => !v)}
      />
      <div className="d-flex gap-2">
        <Button color="primary" onClick={toggle}>
          Download
        </Button>
        <Button onClick={fetchCollections}>
          Refresh {loading && <Spinner size="sm" />}
        </Button>
      </div>

      <Modal isOpen={isOpen} size="lg" toggle={toggle}>
        <ModalHeader
          className="bg-white sticky-top"
          toggle={toggle}
        ></ModalHeader>
        <ModalBody>
          <Row>
            <Col sm={12} md={3} style={{ zIndex: 3 }}>
              <FormGroup>
                <Label for="contractAddressField">Contract Address</Label>
                <Input
                  value={contractAddress}
                  onChange={(e) => setContractAddress(e.target.value)}
                  id="contractAddressField"
                  placeholder="Contract Address"
                  disabled={chain === 'SOL'}
                />
              </FormGroup>
            </Col>
            <Col sm={12} md={3} style={{ zIndex: 3 }}>
              <FormGroup>
                <Label for="extensionField">Extension</Label>
                <Input
                  value={extension}
                  onChange={(e) => setExtension(e.target.value)}
                  id="extensionField"
                  placeholder="Extension"
                />
              </FormGroup>
            </Col>
            <Col sm={12} md={3} style={{ zIndex: 3 }}>
              <FormGroup>
                <Label for="slugField">Slug</Label>
                <Input
                  value={slug}
                  onChange={(e) => setSlug(e.target.value)}
                  id="slugField"
                  placeholder="Slug"
                  disabled={chain === 'ETH'}
                />
              </FormGroup>
            </Col>
            <Col sm={12} md={3} style={{ zIndex: 3 }}>
              <Label for="chainField">Chain</Label>
              <Select
                defaultValue={{ label: chain, value: subRoute }}
                options={options}
                onChange={(e) => handleChange(e)}
              />
            </Col>
          </Row>
          <Button
            color="primary"
            size="sm"
            onClick={() => add(subRoute)}
            disabled={chain === ''}
          >
            Download
          </Button>
        </ModalBody>
      </Modal>
      <Table responsive style={{ paddingTop: '20px' }}>
        <thead>
          <tr>
            <th>
              <b>Name</b>
            </th>
            <th>
              <b>PID</b>
            </th>
            <th>
              <b>Status</b>
            </th>
            <th>
              <b>Progress</b>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {collections?.map((item) => {
            return (
              <tr key={`${item.name}-${item.pid}`}>
                <td>{item.name}</td>
                <td>{item.pid}</td>
                <td>
                  <Status status={item.status} />
                </td>
                <td>
                  <Progress
                    animated={item.log.progress < 100}
                    color={item.log.progress === 100 ? 'success' : 'primary'}
                    value={item.log.progress}
                  >
                    {item.log.progress}%
                  </Progress>
                </td>
                <td>
                  <Button
                    outline
                    size="sm"
                    color="danger"
                    onClick={() => {
                      setConfirmDeletionIsOpen(true)
                      setOnYesClickedRemoveDownload(
                        () => () => deleteCollection(item.name)
                      )
                    }}
                  >
                    <RiDeleteBinLine />
                  </Button>
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </div>
  )
}
const mapStateToProps = (state) => ({
  userType: state?.user?.user?.type,
})

export default connect(mapStateToProps)(CollectionDownloader)
