import moment from 'moment'
import React, { useState } from 'react'
import { Badge, Col, CardGroup, Modal, Row } from 'reactstrap'
import { BsCalendar } from 'react-icons/bs'
import Avatar from 'react-avatar'

import NFT from './NFT'
import NFTEditor from './NFTEditor'

const NFTRequestItem = ({ user, createdAt, nfts, tokenFilter }) => {
  const momentCreatedAt = moment(parseInt(createdAt))
  const userInfo = `${user?.userId} - ${user?.userName}`
  const [isEditorOpen, setIsEditorOpen] = useState(false)
  const [nftId, setNftId] = useState(null)

  const toggleEditorModal = () => {
    setIsEditorOpen(!isEditorOpen)
  }

  return (
    <>
      <Modal size="lg" isOpen={isEditorOpen} toggle={toggleEditorModal}>
        <NFTEditor nftId={nftId} toggle={toggleEditorModal} />
      </Modal>
      <Row>
        <Col md={2}>
          <Avatar
            className="cursor-pointer"
            round
            name={user?.userName}
            size="2em"
            textSizeRatio={3}
          />
          <div>
            <Badge title={userInfo}>{userInfo}</Badge>
          </div>
        </Col>
        <Col md={2}>
          <span>
            <BsCalendar className="text-primary" />
            <div>
              {momentCreatedAt.format('YYYY-MM-DD')}
              <div>{momentCreatedAt.format('HH:mm:ss')}</div>
            </div>
          </span>
        </Col>

        <Col>
          <CardGroup>
            {nfts.map((nft) => (
              <NFT
                key={nft.id}
                id={nft.id}
                onClick={() => {
                  setNftId(nft.id)
                  toggleEditorModal()
                }}
                nftCollection={nft.nftCollection}
                token={nft.token}
                link={nft.link}
                status={nft.status}
                statusMsg={nft.statusMsg}
                highlight={tokenFilter === nft.token}
                isNotInTokenFilter={tokenFilter ? tokenFilter !== nft.token : false}
              />
            ))}
          </CardGroup>
        </Col>
      </Row>
    </>
  )
}

export default NFTRequestItem
