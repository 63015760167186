/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import {
  Input,
  Label,
  FormGroup,
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Spinner,
  Alert,
} from 'reactstrap'
import logo from '../../assets/logo.svg'
import { connect } from 'react-redux'

import { AUTHENTICATE, authSuccess } from './actions'
import { Redirect } from 'react-router-dom'

import { useMutation } from '@apollo/client'

const LoginPage = ({ onAuthSuccess, isLogged }) => {
  const [password, setPassword] = useState('')
  const [username, setUsername] = useState('')
  const [errorMessage, setErrorMessage] = useState(false)

  const [authenticate, { loading }] = useMutation(AUTHENTICATE, {
    onCompleted: (data) => {
      localStorage.setItem('AUTH_TOKEN', data?.authenticate?.token)
      onAuthSuccess({
        token: data?.authenticate?.token,
        username: data?.authenticate?.username,
        type: data?.authenticate?.type,
      })
    },
    onError: (err) => {
      if (err.message === 'Unauthorized') {
        setErrorMessage('Invalid username or password')
      } else {
        setErrorMessage(
          'We had errors connecting with the server. Please, try again later.'
        )
      }
    },
  })

  return (
    <Container className="mt-5">
      {isLogged && <Redirect to="/" />}
      <Row>
        <Col md={{ size: 5, offset: 4 }}>
          <Card className="mt-5">
            <CardBody>
              <div className="text-center mb-3">
                <img width="200em" src={logo} alt="" />
              </div>
              <FormGroup>
                <Label for="usernameField">Username</Label>
                <Input
                  name="username"
                  id="usernameField"
                  placeholder="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </FormGroup>
              <FormGroup>
                <Label for="passwordField">Password</Label>
                <Input
                  type="password"
                  name="password"
                  id="passwordField"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      authenticate({
                        variables: { username, password },
                      })
                    }
                  }}
                  placeholder="password"
                />
              </FormGroup>
              <Button
                block
                color="primary"
                onClick={() => {
                  authenticate({
                    variables: { username, password },
                  })
                }}
              >
                {loading && <Spinner size="sm" />}
                Login
              </Button>
            </CardBody>
            <CardBody>
              {errorMessage && (
                <Alert className="text-center" color="danger">
                  {errorMessage}
                </Alert>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

const mapStateToProps = (state) => ({
  isLogged: state.user.isLogged,
})

const mapDispatchToProps = (dispatch) => ({
  onAuthSuccess: ({ token }) => {
    dispatch(authSuccess({ token }))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)
