import moment from 'moment'
import React from 'react'
import Avatar from 'react-avatar'
import { BsCalendar } from 'react-icons/bs'
import ReactTooltip from 'react-tooltip'
import { Badge, Col, Row } from 'reactstrap'
import Image from '../../components/Image'

const ArtistRequestItem = ({
  user,
  createdAt,
  imageLink,
  status,
  statusColor,
}) => {
  const momentCreatedAt = moment(createdAt)
  const userInfo = `${user.userId} - ${user.userName}`
  const duration = momentCreatedAt.fromNow()
  return (
    <Row>
      <ReactTooltip effect="solid" />
      <Col md={2}>
        <Avatar
          className="cursor-pointer"
          round
          name={user.userName}
          size="2em"
          textSizeRatio={2}
        />
        <div>
          <Badge color="secondary">{userInfo}</Badge>
        </div>
      </Col>
      <Col md={2}>
        <span>
          <BsCalendar
            className="text-primary"
            data-tip={momentCreatedAt.format('YYYY-MM-DD HH:mm:ss')}
          />
          <div>{duration}</div>
        </span>
      </Col>
      <Col md={1}>
        <Image src={imageLink} lowRes resizeOptions={{ width: 200 }} />
      </Col>
      <Col md={1} className="text-end">
        <Badge color={statusColor}>{status}</Badge>
      </Col>
    </Row>
  )
}

export default ArtistRequestItem
