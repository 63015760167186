import React, { useState } from 'react'
import {
  Col,
  Input,
  Row,
  Alert,
  Button,
  ListGroup,
  ListGroupItem,
  Spinner,
} from 'reactstrap'
import { useMutation, useQuery } from '@apollo/client'
import Select from 'react-select'
import { GET_COLLECTIONS } from '../Collection/actions'
import { toast } from 'react-toastify'
import Confirm from '../../components/Confirm'
import { GET_PRODUCTS } from '../Product/actions'
import { GET_NFT_USERS } from '../NFTUser/actions'
import {
  DELETE_UPDATER_ACTION,
  GET_UPDATER_ACTIONS,
  RECREATE_UPDATER_ACTION,
} from './actions'
import AppSplash from '../../components/AppSplash'
import UpdaterActionItem from './UpdaterActionItem'

const Updater = () => {
  const [collection, setCollection] = useState()
  const [token, setToken] = useState('')
  const [product, setProduct] = useState()
  const [user, setUser] = useState()
  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = useState(false)
  const [recreateDialogIsOpen, setRecreateDialogIsOpen] = useState(false)

  const [recreateUpdaterAction, { loading: loadingRecreate }] = useMutation(
    RECREATE_UPDATER_ACTION,
    {
      onError: (e) => {
        toast.error(e.message)
      },
    }
  )

  const [deleteUpdaterAction] = useMutation(
    DELETE_UPDATER_ACTION,
    {
      onError: (e) => {
        toast.error(e.message)
      },
    }
  )

  const {
    loading,
    error,
    data: { collections } = {},
  } = useQuery(GET_COLLECTIONS)

  const {
    loading: loadingProducts,
    error: errorProducts,
    data: { products } = {},
  } = useQuery(GET_PRODUCTS)

  const {
    loading: loadingUsers,
    error: errorUsers,
    data: { nftUsers } = {},
  } = useQuery(GET_NFT_USERS)

  const {
    loading: loadingUpdaterActions,
    error: errorUpdaterActions,
    data: { updaterActions } = {},
  } = useQuery(GET_UPDATER_ACTIONS, {
    pollInterval: 10 * 1000, // 10 secs
  })

  if (loading || loadingProducts || loadingUsers || loadingUpdaterActions) {
    return <AppSplash />
  }

  if (error || errorProducts || errorUsers || errorUpdaterActions) {
    return (
      <Alert color="">
        Error loading collections from server. {error?.message}{' '}
        {errorProducts?.message} {errorUsers?.message}{' '}
        {errorUpdaterActions?.message}
      </Alert>
    )
  }

  const collectionOptions = collections.map((col) => ({
    value: col.id,
    label: col.identifier,
  }))

  const productOptions = products.map((p) => ({
    value: p.id,
    label: p.name,
  }))

  const nftUsersOptions = nftUsers.map((u) => ({
    value: u.id,
    label: `${u.userId} - ${u.userName}`,
  }))

  const generalMessage = (
    <strong>
      <div>
        <div className="mb-2">
          Filters{' '}
          <span className="text-primary">(Please review carefully)</span>
        </div>
        <div>
          Product:{' '}
          <span className={product?.name ? 'text-primary' : 'text-danger'}>
            {product?.name}
            {!product?.name && <span>All products (no filter applied)</span>}
          </span>
        </div>
        <div>
          Collection:{' '}
          <span
            className={collection?.identifier ? 'text-primary' : 'text-danger'}
          >
            {collection?.identifier}
            {!collection?.identifier && (
              <span>All collections (No filter applied)</span>
            )}
          </span>
        </div>
        <div>
          Token:{' '}
          <span className={token ? 'text-primary' : 'text-danger'}>
            {token}
            {!token && <span>All tokens (No filter applied)</span>}
          </span>
        </div>
        <div>
          User:{' '}
          <span className={user ? 'text-primary' : 'text-danger'}>
            {user?.userName}
            {!user && <span>All users (No filter applied)</span>}
          </span>
        </div>
      </div>
      <hr />
    </strong>
  )

  const disableAction =
    (!product && !collection && !token && !user) || (token && !collection)

  const toggleDeleteDialog = () => setDeleteDialogIsOpen(!deleteDialogIsOpen)
  const toggleRecreateDialog = () =>
    setRecreateDialogIsOpen(!recreateDialogIsOpen)

  return (
    <div className="m-3">
      <Confirm
        isOpen={deleteDialogIsOpen}
        toggle={toggleDeleteDialog}
        msgHeader="Product Updater"
        message={
          <div>
            <div>
              Do you really want to <b className="text-danger">REMOVE</b> the
              products?
            </div>
            <hr />
            {generalMessage}
            <span className="text-warning">
              <h3>
                <b>WARNING</b>{' '}
              </h3>
            </span>
            <div>
              <b className="text-danger">
                This action cannot be reverted and is processed in background.
                It affects production products REMOVING products on maddies
                store and platforms (Printify/Gooten).
              </b>
            </div>
          </div>
        }
        onYesClicked={() =>
          deleteUpdaterAction({
            variables: {
              filters: {
                productName: product?.name,
                collectionInitials: collection?.initials,
                collectionToken: (token || '').trim(),
                userName: user?.userName,
              },
            },
          })
        }
      />
      <Confirm
        isOpen={recreateDialogIsOpen}
        toggle={toggleRecreateDialog}
        msgHeader="Product Updater"
        message={
          <div>
            <div>Do you really want to RE-CREATE all products again?</div>
            <hr />
            {generalMessage}
            <span className="text-warning">
              <h3>
                <b>WARNING</b>{' '}
              </h3>
            </span>
            <div>
              <b className="text-danger">
                This action cannot be reverted and is processed in background.
                It affects production products CREATING products on maddies
                store and platforms (Printify/Gooten).
              </b>
            </div>
          </div>
        }
        onYesClicked={() =>
          recreateUpdaterAction({
            variables: {
              filters: {
                productName: product?.name,
                collectionInitials: collection?.initials,
                collectionToken: (token || '').trim(),
                userName: user?.userName,
              },
            },
          })
        }
      />
      <Row>
        <Col xs={12} md={1}>
          <Input
            placeholder="Token"
            value={token}
            onChange={(e) => setToken(e.target.value)}
          />
        </Col>
        <Col xs={12} md={2}>
          <Select
            placeholder="User Filter"
            value={
              user
                ? {
                    value: user.id,
                    label: `${user.userId} - ${user.userName}`,
                  }
                : null
            }
            isClearable
            options={nftUsersOptions}
            onChange={(selected) => {
              setUser(
                selected ? nftUsers.find((u) => u.id === selected.value) : null
              )
            }}
          />
        </Col>
        <Col xs={12} md={3}>
          <Select
            placeholder="Collection Filter"
            value={
              collection
                ? {
                    value: collection.id,
                    label: collection.identifier,
                  }
                : null
            }
            isClearable
            options={collectionOptions}
            onChange={(selected) => {
              setCollection(
                selected
                  ? collections.find((col) => col.id === selected.value)
                  : null
              )
            }}
          />
        </Col>
        <Col xs={12} md={3}>
          <Select
            placeholder="Product Filter"
            value={
              product
                ? {
                    value: product.id,
                    label: product.name,
                  }
                : null
            }
            isClearable
            options={productOptions}
            onChange={(selected) => {
              setProduct(
                selected ? products.find((p) => p.id === selected.value) : null
              )
            }}
          />
        </Col>

        <Col className="text-end">
          <Button
            disabled={disableAction}
            color="danger"
            onClick={toggleDeleteDialog}
          >
            Delete
          </Button>{' '}
          <Button
            disabled={disableAction}
            color="primary"
            onClick={toggleRecreateDialog}
          >
            {loadingRecreate && <Spinner size="sm" />} Re-create
          </Button>
        </Col>
      </Row>
      <hr />
      <ListGroup>
        {updaterActions?.map((ua) => (
          <ListGroupItem key={ua.id}>
            <UpdaterActionItem {...ua} />
          </ListGroupItem>
        ))}
      </ListGroup>
    </div>
  )
}

export default Updater
