export const Gooten = {
  value: 'gooten',
  label: 'Gooten',
}
export const Printify = {
  value: 'printify',
  label: 'Printify',
}
export const Madswag = {
  value: 'madswag',
  label: 'Madswag',
}