import React from 'react'
import { MdVerified } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { Badge, Button } from 'reactstrap'
import copy from 'copy-to-clipboard'
import { FaPaypal, FaEthereum } from 'react-icons/fa'
import { toast } from 'react-toastify'
import ReactTooltip from 'react-tooltip'
import styled from 'styled-components'

const EmailInfo = styled.span`
  border: 1px solid transparent;
  padding: 0.2em;
  transition: border-width 1s linear;
  &:hover {
    border: 1px solid #6c757d;
  }
`

export const ReportItem = ({
  vendor,
  page,
  onClick,
  officialCollection,
  status,
  index,
  limit,
  commission,
  paidCommission,
}) => {
  return (
    <tr onClick={onClick}>
      <td>{index + 1 + (page - 1) * limit}</td>
      <td>{vendor?.userId}{vendor?.userName ?? ''}</td>
      <td>
        <EmailInfo
          className="rounded"
          onClick={(e) => {
            copy(vendor?.email)
            toast.info(`${vendor?.email} copied to clipboard`)
            e.stopPropagation()
          }}
        >
          {vendor?.email}
        </EmailInfo>{' '}
        {vendor?.paypal && (
          <>
            <ReactTooltip
              id={`commission-paypal-tooltip-${vendor.id}`}
              effect="solid"
            >
              Copy paypal account
            </ReactTooltip>
            <Button
              data-tip=""
              className="rounded-circle"
              data-for={`commission-paypal-tooltip-${vendor.id}`}
              outline
              size="sm"
              onClick={(e) => {
                copy(vendor?.paypal)
                toast.info(`paypal account copied`)
                e.stopPropagation()
              }}
            >
              <FaPaypal className="cursor-pointer" />
            </Button>
          </>
        )}{' '}
        {vendor?.wallets?.map((wallet) => (
          <span key={wallet.id}>
            <ReactTooltip
              id={`commission-${wallet.chain}-tooltip-${vendor.id}`}
              effect="solid"
            >
              Copy {wallet.chain} wallet account
            </ReactTooltip>
            <Button
              data-tip=""
              className="rounded-circle"
              data-for={`commission-${wallet.chain}-tooltip-${vendor.id}`}
              outline
              size="sm"
              onClick={(e) => {
                copy(vendor?.wallet.id)
                toast.info(`ethereum wallet copied`)
                e.stopPropagation()
              }}
            >
              <FaEthereum className="cursor-pointer" />
            </Button>
          </span>
        ))}
      </td>
      <td>
        <div className="d-flex gap-1 align-items-center">
          {officialCollection?.identifier && (
            <MdVerified className="text-primary" size="1.3em" />
          )}{' '}
          {officialCollection?.identifier && (
            <Link
              to={`/collections/${officialCollection?.id}`}
              target="_blank"
              onClick={(e) => e.stopPropagation()}
            >
              {officialCollection?.identifier}
            </Link>
          )}
        </div>
      </td>

      <td>
        <Badge
          className={status.color !== 'danger' ? 'opacity-75' : ''}
          color={status.color}
        >
          {status.text}
        </Badge>
      </td>
      <td className={`${commission ? 'table-warning' : ''} text-end`}>
        {commission?.toFixed(2)}
      </td>
      <td className={`${paidCommission ? 'table-success' : ''} text-end`}>
        {paidCommission?.toFixed(2)}
      </td>
    </tr>
  )
}
