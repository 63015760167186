import { gql } from "@apollo/client"

export const GET_PACKAGES = gql`
  query GetPackages {
    packages {
      id
      name
      products {
        id
        name
        image
      }
    }
  }
`

export const GET_PACKAGE_BY_ID = gql`
  query GetPackageById($id: String) {
    packageById(id: $id) {
      id
      name
      products {
        id
        name
        image
      }
    }
  }
`
export const DELETE_PACKAGE = gql`
  mutation DeletePackage($id: String) {
    deletePackage(id: $id)
  }
`
export const ADD_NEW_PACKAGE = gql`
  mutation AddNewPackage($name: String, $products: [String]) {
    addNewPackage(
      name: $name
      products: $products
    ) {
      id
      name
      products {
        id
        name
        image
      }
    }
  }
`

export const EDIT_PACKAGE = gql`
  mutation EditPackage($id: String, $name: String, $products: [String]) {
    editPackage(
      id: $id
      name: $name
      products: $products
    ) {
      id
      name
      products {
        id
        name
        image
      }
    }
  }
`
