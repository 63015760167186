import { useQuery } from '@apollo/client'
import axios from 'axios'
import moment from 'moment'
import React from 'react'
import { FiDownload } from 'react-icons/fi'
import { toast } from 'react-toastify'
import {
  Badge,
  Button,
  Col,
  ListGroup,
  ListGroupItem,
  Progress,
  Row,
} from 'reactstrap'
import AppSplash from '../../components/AppSplash'
import { GET_EXPORTED_COMMISSION_LIST } from './actions'

const ExportedList = () => {
  const { data: { exportedCommissionList } = {}, loading } = useQuery(
    GET_EXPORTED_COMMISSION_LIST,
    { pollInterval: 5 * 1000, fetchPolicy: 'network-only' }
  )

  if (loading) {
    return <AppSplash />
  }

  const downloadExportedFile = ({ fileUrl }) => {
    axios
      .get(fileUrl, { responseType: 'blob' })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'commission-export.csv') //or any other extension
        document.body.appendChild(link)
        link.click()
      })
      .catch((e) => {
        toast.error(`Could not download exported file. ${e?.message}`)
      })
  }

  return (
    <div>
      <ListGroup>
        {exportedCommissionList?.map((comm) => {
          let statusColor = 'info'

          if (comm?.status === 'done') {
            statusColor = 'success'
          } else if (comm?.status === 'error') {
            statusColor = 'danger'
          }

          return (
            <ListGroupItem key={comm.id}>
              <div className="mb-1">
                {comm?.progress !== 100 && <Progress style={{height: '0.5em'}} value={comm?.progress} />}
              </div>
              <Row>
                <Col md={5}>
                  <Row>
                    <Col>
                      {moment(comm.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                    </Col>
                    <Col md={4}>
                      <Badge color={statusColor}>{comm?.status}</Badge>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  {comm?.query?.vendor && (
                    <div>vendor: {comm?.query?.vendor}</div>
                  )}{' '}
                  {comm?.query?.period && (
                    <div>
                      period:{' '}
                      {comm?.query?.period?.startDate && (
                        <span>
                          {moment.utc(comm?.query?.period?.startDate).format(
                            'YYYY-MM-DD'
                          )}
                        </span>
                      )}
                      {comm?.query?.period?.endDate && (
                        <span>
                          {' '}-{' '}
                          {moment.utc(comm?.query?.period?.endDate).format(
                            'YYYY-MM-DD'
                          )}
                        </span>
                      )}
                    </div>
                  )}
                </Col>
                <Col md={1} className="text-end">
                  <Button
                    outline
                    size="sm"
                    onClick={() =>
                      downloadExportedFile({ fileUrl: comm?.fileUrl })
                    }
                  >
                    <FiDownload />
                  </Button>
                </Col>
              </Row>
            </ListGroupItem>
          )
        })}
      </ListGroup>
    </div>
  )
}

export default ExportedList
