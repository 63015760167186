import { gql } from "@apollo/client";

export const GET_BLUEPRINT_PROVIDERS = gql`
  query GetBlueprintProviders($blueprintId: Int) {
    blueprintProviders(blueprintId: $blueprintId) {
      id
      title
    }
  }
`;

export const GET_PRODUCT_VARIANTS = gql`
  query GetProductVariants($blueprintId: Int, $provider: Int) {
    productVariants(blueprintId: $blueprintId, provider: $provider) {
      id
      variants {
        id
        title
        options {
          color
          size
          shape
          surface
        }
      }
    }
  }
`;
