import { useQuery } from '@apollo/client'
import moment from 'moment'
import React from 'react'
import { Badge, Col, Progress, Row, Spinner } from 'reactstrap'
import { statusColors } from '../../utils/status'
import { GET_COMMISSION_STATUS } from './actions'
import { BsClock } from 'react-icons/bs'

export default function CommissionStatus({ hideWhenStopped = false }) {
  const { data: { processStatus } = {} } = useQuery(GET_COMMISSION_STATUS, {
    pollInterval: 5000,
    fetchPolicy: 'network-only',
    variables: {
      name: 'commission-calculation',
    },
  })

  const duration = moment(processStatus?.updatedAt).from(moment(processStatus?.createdAt), true)

  const start = processStatus?.data?.startDate
    ? moment.utc(processStatus?.data?.startDate).format('YYYY-MM-DD')
    : ''
  const end = processStatus?.data?.endDate
    ? moment.utc(processStatus?.data?.endDate).format('YYYY-MM-DD')
    : ''
  
  if (hideWhenStopped && processStatus?.status !== 'running') {
    return null
  }
  
  return (
    <>
      <Row>
        <Col md={3}>
          Commission Status:{' '}
          <Badge
            className="pb-1"
            style={{ verticalAlign: 'text-bottom' }}
            color={statusColors[processStatus?.status]}
          >
            {processStatus?.status}
          </Badge>{' '}
          {processStatus?.status === 'running' && (
            <Spinner color="primary" size="sm" />
          )}
        </Col>
        <Col>
          <BsClock style={{ verticalAlign: 'text-bottom' }} /> {duration}
        </Col>
        <Col sm={12} md={3}>
          From: <Badge style={{ verticalAlign: 'text-bottom' }}>{start}</Badge>{' '}
          To: <Badge style={{ verticalAlign: 'text-bottom' }}>{end}</Badge>
        </Col>
      </Row>
      <Row className="mt-1">
        <Col>
          <Progress multi max={processStatus?.prepared}>
            <Progress
              bar
              color="success"
              value={processStatus?.progress}
              className="h-100"
            >
              {processStatus?.progress === 100
                ? parseInt(processStatus?.progress)
                : processStatus?.progress?.toFixed(2)}
              %
            </Progress>
            <Progress
              className="opacity-25"
              bar
              color="primary"
              value={processStatus?.prepared - processStatus?.progress}
            />
          </Progress>
        </Col>
      </Row>
    </>
  )
}
