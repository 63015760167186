/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import CreatableSelect from 'react-select/creatable'
import { Card, CardBody, CardHeader, Collapse } from 'reactstrap'
import ReactSelect from 'react-select'
import VariantEditor from '../PrintifyInfo/VariantEditor'
import { Row } from 'reactstrap'
import { Col } from 'reactstrap'
import { Badge } from 'reactstrap'
import { Input } from 'reactstrap'
import { FormGroup } from 'reactstrap'
import { Label } from 'reactstrap'
import { Modal } from 'reactstrap'
import { IoSettingsSharp } from 'react-icons/io5'
import { Button } from 'reactstrap'
import ReactTooltip from 'react-tooltip'
import { isEqual } from 'lodash'
import { BsFillCircleFill } from 'react-icons/bs'
import { FaPlus, FaTrash } from 'react-icons/fa'
import Confirm from '../Confirm'
import ColorCircle from '../ColorCircle'

const defaultMadswagInfo = {
  variants: {},
  variantsProps: {},
  variantsPropsKey: null,
  views: {},
}

const MadswagInfo = ({ onChange, madswagInfo }) => {
  const initialMadswagInfo = madswagInfo ?? defaultMadswagInfo

  const possibleVariants = ['color', 'size', 'shape', 'surface']

  const [variantToAddSelected, setVariantToAddSelected] = useState()
  const [madswagInfoState, setMadswagInfoState] = useState({
    ...initialMadswagInfo,
    variants: initialMadswagInfo.variants ?? {},
    variantsProps: initialMadswagInfo.variantsProps ?? {},
    views: initialMadswagInfo.views ?? { front: { id: 'front' } },
  })
  const [variantKeyToSetup, setVariantKeyToSetup] = useState()

  const [variantEditorIsOpen, setVariantEditorIsOpen] = useState(false)

  const [isOpenVariantRemover, setIsOpenVariantRemover] = useState(false)
  const [variantToRemove, setVariantToRemove] = useState(null)
  const [viewsSettingsOpen, setViewsSettingsOpen] = useState(false)

  const toggleVariantRemoverConfirm = () => setIsOpenVariantRemover((v) => !v)

  const toggleVariantEditor = () => setVariantEditorIsOpen((v) => !v)
  const toggleViewsSettings = () => setViewsSettingsOpen((v) => !v)

  const handleMadswagInfoChange = (newValue) => {
    if (onChange) {
      onChange(newValue)
    }
    setMadswagInfoState(newValue)
  }

  const getVariantProps = (vKey) => {
    const variantPropsLoaded = madswagInfoState.variantsProps
      ? madswagInfoState.variantsProps[vKey]
      : []
    if (!madswagInfoState.variants) {
      return []
    }

    let possibleVariantProps =
      madswagInfoState.variants[vKey]?.map((vItem) => ({
        variant: vItem,
        price: '',
        contrast: '',
      })) ?? []

    possibleVariantProps = possibleVariantProps?.map((vProp) => {
      const props = variantPropsLoaded?.find(
        (vLoaded) => vLoaded.variant === vProp.variant
      )
      if (props) {
        return props
      }
      return vProp
    })

    return possibleVariantProps
  }

  const getVariantLabel = (vKey, variant) => {
    if (madswagInfoState?.variantsProps) {
      const vKeyProps = madswagInfoState?.variantsProps[vKey]
      const variantProp = vKeyProps?.find((p) => p.variant === variant)

      return (
        <div className="d-flex gap-1 align-items-center">
          {variantProp?.colorValue && (
            <ColorCircle color={variantProp?.colorValue} />
          )}{' '}
          {variant}
          {variantProp?.maddiesCost && (
            <>- ${variantProp.maddiesCost?.toFixed(2)}</>
          )}
        </div>
      )
    }
  }

  const variantKeys = Object.keys(madswagInfoState.variants ?? {}).filter(
    (vKey) => madswagInfoState.variants[vKey] != null
  )

  const addNewVariant = () => {
    if (!variantKeys.includes(variantToAddSelected)) {
      setVariantToAddSelected(null)
      handleMadswagInfoChange({
        ...madswagInfoState,
        variants: {
          ...madswagInfoState.variants,
          [variantToAddSelected]: [],
        },
      })
    }
  }

  const removeVariant = (variantKey) => {
    const variantsNew = { ...madswagInfoState.variants }

    delete variantsNew[variantKey]

    handleMadswagInfoChange({
      ...madswagInfoState,
      variants: variantsNew,
    })
  }

  return (
    <Card>
      <Confirm
        msgHeader={
          <span>
            Do you want to remove the variant{' '}
            <b className="text-danger">{variantToRemove}</b>?`
          </span>
        }
        message={`This may lost all the configs when product is saved.`}
        isOpen={isOpenVariantRemover}
        toggle={toggleVariantRemoverConfirm}
        onYesClicked={() => {
          removeVariant(variantToRemove)
        }}
      />
      <Modal
        isOpen={variantEditorIsOpen}
        size="lg"
        toggle={toggleVariantEditor}
      >
        <VariantEditor
          variantKey={variantKeyToSetup}
          variantsSelected={
            madswagInfoState?.variants
              ? madswagInfoState?.variants[variantKeyToSetup]
              : []
          }
          views={madswagInfoState?.views}
          variantsProps={getVariantProps(variantKeyToSetup)}
          onChange={(variantProps) => {
            const newState = {
              ...madswagInfoState,
              variantsProps: {
                ...madswagInfoState.variantsProps,
                [variantKeyToSetup]: variantProps.map((vObj) => ({
                  ...vObj,
                  price: typeof vObj.price === 'number' ? vObj.price : null,
                })),
              },
            }
            handleMadswagInfoChange(newState)
          }}
        />
      </Modal>
      <CardHeader>Madswag Info</CardHeader>
      <CardBody>
        <Row>
          <Col xs={6} md={2}>
            <FormGroup>
              <Label for="costFieldMadswag">Maddies Cost</Label>
              <Input
                id="costFieldMadswag"
                type="number"
                value={madswagInfoState.maddiesCost ?? ''}
                onChange={(e) => {
                  const newValue = e.target.valueAsNumber
                  handleMadswagInfoChange({
                    ...madswagInfoState,
                    maddiesCost: isNaN(newValue) ? null : newValue,
                  })
                }}
              />
            </FormGroup>
          </Col>
          <Col xs={6} md={2}>
            <FormGroup>
              <Label for="extraCostValueField">(+1 view)price</Label>
              <Input
                id="extraCostValueField"
                type="number"
                value={madswagInfoState.extraLayerCost ?? ''}
                onChange={(e) => {
                  const newValue = e.target.valueAsNumber
                  handleMadswagInfoChange({
                    ...madswagInfoState,
                    extraLayerCost: isNaN(newValue) ? null : newValue,
                  })
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <hr />
        <h5>Views</h5>
        <Row>
          <Col xs={12} md={4}>
            <FormGroup>
              <CreatableSelect
                isMulti
                value={
                  Object.keys(madswagInfoState?.views)?.map((v) => ({
                    value: v,
                    label: v,
                  })) ?? null
                }
                onChange={(selected) => {
                  const newViews = {}

                  selected.forEach((v) => {
                    newViews[v.value] = {
                      ...newViews[v.value],
                      id: v.value,
                    }
                  })

                  handleMadswagInfoChange({
                    ...madswagInfoState,
                    views: newViews,
                  })
                }}
              />
            </FormGroup>
          </Col>
          <Col>
            <Button
              color="secondary"
              onClick={toggleViewsSettings}
              className="mb-3"
            >
              Settings
            </Button>
          </Col>
        </Row>
        <Collapse isOpen={viewsSettingsOpen}>
          <Row>
            {Object.keys(madswagInfoState.views)?.map((view) => {
              return (
                <Col xs={12} md={6} key={view}>
                  <Card>
                    <CardHeader>{view}</CardHeader>
                    <CardBody>
                      <Row>
                        <Col>
                          <FormGroup row>
                            <Label md={3}>Label</Label>
                            <Col>
                              <Input
                                value={madswagInfoState.views[view]?.label}
                                onChange={(e) => {
                                  handleMadswagInfoChange({
                                    ...madswagInfoState,
                                    views: {
                                      ...(madswagInfoState.views ?? {}),
                                      [view]: {
                                        ...(madswagInfoState.views[view] ?? {}),
                                        label: e.target.value,
                                      },
                                    },
                                  })
                                }}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label md={3}>Image</Label>
                            <Col>
                              <Input
                                value={madswagInfoState.views[view]?.image}
                                onChange={(e) => {
                                  handleMadswagInfoChange({
                                    ...madswagInfoState,
                                    views: {
                                      ...(madswagInfoState.views ?? {}),
                                      [view]: {
                                        ...(madswagInfoState.views[view] ?? {}),
                                        image: e.target.value,
                                      },
                                    },
                                  })
                                }}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label md={3}>Command</Label>
                            <Col>
                              <Input
                                value={madswagInfoState.views[view]?.command}
                                type="textarea"
                                onChange={(e) => {
                                  handleMadswagInfoChange({
                                    ...madswagInfoState,
                                    views: {
                                      ...(madswagInfoState.views ?? {}),
                                      [view]: {
                                        ...(madswagInfoState.views[view] ?? {}),
                                        command: e.target.value,
                                      },
                                    },
                                  })
                                }}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label md={3}>Canvas</Label>
                            <Col>
                              <Row>
                                <Col>
                                  <FormGroup>
                                    <Label for={`${view}-prop-canvas-width`}>
                                      Width
                                    </Label>
                                    <Input
                                      id={`${view}-prop-canvas-width`}
                                      placeholder="Width"
                                      type="number"
                                      value={
                                        madswagInfoState.views[view]?.canvas
                                          ?.width
                                      }
                                      onChange={(e) => {
                                        handleMadswagInfoChange({
                                          ...madswagInfoState,
                                          views: {
                                            ...(madswagInfoState.views ?? {}),
                                            [view]: {
                                              ...(madswagInfoState.views[
                                                view
                                              ] ?? {}),
                                              canvas: {
                                                ...(madswagInfoState.views[view]
                                                  ?.canvas ?? {}),
                                                width:
                                                  e.target.valueAsNumber ??
                                                  null,
                                              },
                                            },
                                          },
                                        })
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col>
                                  <FormGroup>
                                    <Label for={`${view}-prop-canvas-height`}>
                                      Height
                                    </Label>
                                    <Input
                                      id={`${view}-prop-canvas-height`}
                                      placeholder="Height"
                                      type="number"
                                      value={
                                        madswagInfoState.views[view]?.canvas
                                          ?.height
                                      }
                                      onChange={(e) => {
                                        handleMadswagInfoChange({
                                          ...madswagInfoState,
                                          views: {
                                            ...(madswagInfoState.views ?? {}),
                                            [view]: {
                                              ...(madswagInfoState.views[
                                                view
                                              ] ?? {}),
                                              canvas: {
                                                ...(madswagInfoState.views[view]
                                                  ?.canvas ?? {}),
                                                height:
                                                  e.target.valueAsNumber ??
                                                  null,
                                              },
                                            },
                                          },
                                        })
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label md={3}>Size</Label>
                            <Col>
                              <Row>
                                <Col>
                                  <FormGroup>
                                    <Label for={`${view}-prop-sizes-width`}>
                                      Width
                                    </Label>
                                    <Input
                                      id={`${view}-prop-sizes-width`}
                                      placeholder="Width"
                                      type="number"
                                      value={
                                        madswagInfoState.views[view]?.size
                                          ?.width
                                      }
                                      onChange={(e) => {
                                        handleMadswagInfoChange({
                                          ...madswagInfoState,
                                          views: {
                                            ...(madswagInfoState.views ?? {}),
                                            [view]: {
                                              ...(madswagInfoState.views[
                                                view
                                              ] ?? {}),
                                              size: {
                                                ...(madswagInfoState.views[view]
                                                  ?.size ?? {}),
                                                width:
                                                  e.target.valueAsNumber ??
                                                  null,
                                              },
                                            },
                                          },
                                        })
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col>
                                  <FormGroup>
                                    <Label for={`${view}-prop-sizes-height`}>
                                      Height
                                    </Label>
                                    <Input
                                      id={`${view}-prop-sizes-height`}
                                      placeholder="Height"
                                      type="number"
                                      value={
                                        madswagInfoState.views[view]?.size
                                          ?.height
                                      }
                                      onChange={(e) => {
                                        handleMadswagInfoChange({
                                          ...madswagInfoState,
                                          views: {
                                            ...(madswagInfoState.views ?? {}),
                                            [view]: {
                                              ...(madswagInfoState.views[
                                                view
                                              ] ?? {}),
                                              size: {
                                                ...(madswagInfoState.views[view]
                                                  ?.size ?? {}),
                                                height:
                                                  e.target.valueAsNumber ??
                                                  null,
                                              },
                                            },
                                          },
                                        })
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label md={3}>Viewport</Label>
                            <Col>
                              <Row>
                                <Col>
                                  <FormGroup>
                                    <Label for={`${view}-prop-viewport-width`}>
                                      Width
                                    </Label>
                                    <Input
                                      id={`${view}-prop-viewport-width`}
                                      placeholder="Width"
                                      type="number"
                                      value={
                                        madswagInfoState.views[view]?.viewport
                                          ?.width
                                      }
                                      onChange={(e) => {
                                        handleMadswagInfoChange({
                                          ...madswagInfoState,
                                          views: {
                                            ...(madswagInfoState.views ?? {}),
                                            [view]: {
                                              ...(madswagInfoState.views[
                                                view
                                              ] ?? {}),
                                              viewport: {
                                                ...(madswagInfoState.views[view]
                                                  ?.viewport ?? {}),
                                                width:
                                                  e.target.valueAsNumber ??
                                                  null,
                                              },
                                            },
                                          },
                                        })
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col>
                                  <FormGroup>
                                    <Label for={`${view}-prop-viewport-height`}>
                                      Height
                                    </Label>
                                    <Input
                                      id={`${view}-prop-viewport-height`}
                                      placeholder="Height"
                                      type="number"
                                      value={
                                        madswagInfoState.views[view]?.viewport
                                          ?.height
                                      }
                                      onChange={(e) => {
                                        handleMadswagInfoChange({
                                          ...madswagInfoState,
                                          views: {
                                            ...(madswagInfoState.views ?? {}),
                                            [view]: {
                                              ...(madswagInfoState.views[
                                                view
                                              ] ?? {}),
                                              viewport: {
                                                ...(madswagInfoState.views[view]
                                                  ?.viewport ?? {}),
                                                height:
                                                  e.target.valueAsNumber ??
                                                  null,
                                              },
                                            },
                                          },
                                        })
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Col>
                          </FormGroup>
                          <FormGroup row>
                            <Label md={3}>Offset</Label>
                            <Col>
                              <Row>
                                <Col>
                                  <FormGroup>
                                    <Label for={`${view}-prop-viewport-x`}>
                                      X
                                    </Label>
                                    <Input
                                      id={`${view}-prop-viewport-x`}
                                      placeholder="X"
                                      type="number"
                                      value={
                                        madswagInfoState.views[view]?.offset?.x
                                      }
                                      onChange={(e) => {
                                        handleMadswagInfoChange({
                                          ...madswagInfoState,
                                          views: {
                                            ...(madswagInfoState.views ?? {}),
                                            [view]: {
                                              ...(madswagInfoState.views[
                                                view
                                              ] ?? {}),
                                              offset: {
                                                ...(madswagInfoState.views[view]
                                                  ?.offset ?? {}),
                                                x:
                                                  e.target.valueAsNumber ??
                                                  null,
                                              },
                                            },
                                          },
                                        })
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col>
                                  <FormGroup>
                                    <Label for={`${view}-prop-viewport-y`}>
                                      Y
                                    </Label>
                                    <Input
                                      id={`${view}-prop-viewport-y`}
                                      placeholder="Y"
                                      type="number"
                                      value={
                                        madswagInfoState.views[view]?.offset?.y
                                      }
                                      onChange={(e) => {
                                        handleMadswagInfoChange({
                                          ...madswagInfoState,
                                          views: {
                                            ...(madswagInfoState.views ?? {}),
                                            [view]: {
                                              ...(madswagInfoState.views[
                                                view
                                              ] ?? {}),
                                              offset: {
                                                ...(madswagInfoState.views[view]
                                                  ?.offset ?? {}),
                                                y:
                                                  e.target.valueAsNumber ??
                                                  null,
                                              },
                                            },
                                          },
                                        })
                                      }}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Col>
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              )
            })}
          </Row>
        </Collapse>
        <hr />
        <h5>Variants</h5>
        <Row>
          <Col xs={10} md={4}>
            <ReactSelect
              value={
                variantToAddSelected
                  ? { value: variantToAddSelected, label: variantToAddSelected }
                  : null
              }
              placeholder="New variant"
              options={possibleVariants
                .filter((v) => !variantKeys.includes(v))
                .map((v) => ({ value: v, label: v }))}
              onChange={(selected) => {
                setVariantToAddSelected(selected.value)
              }}
            />
          </Col>
          <Col xs={2} md={1}>
            <Button
              onClick={addNewVariant}
              block
              disabled={!variantToAddSelected}
            >
              <FaPlus />
            </Button>
          </Col>
          <Col>
            <Row>
              <Col>
                <div className="d-flex align-items-center h-100 justify-content-end">
                  <div>Variant for pricing</div>
                </div>
              </Col>
              <Col>
                <ReactSelect
                  options={variantKeys.map((vKey) => ({
                    label: vKey,
                    value: vKey,
                  }))}
                  isClearable
                  onChange={(selected) => {
                    handleMadswagInfoChange({
                      ...madswagInfoState,
                      variantsPropsKey: selected?.value ?? null,
                    })
                  }}
                  value={
                    madswagInfoState?.variantsPropsKey
                      ? {
                          label: madswagInfoState?.variantsPropsKey,
                          value: madswagInfoState?.variantsPropsKey,
                        }
                      : null
                  }
                />
              </Col>
            </Row>
          </Col>
        </Row>
        {variantKeys.map((variantKey) =>
          madswagInfoState.variants[variantKey] ? (
            <Row key={variantKey} className="mt-2">
              <Col md={1}>
                <Badge>{variantKey}</Badge>
              </Col>
              <Col>
                <CreatableSelect
                  isMulti
                  value={madswagInfoState.variants[variantKey]?.map((v) => ({
                    value: v,
                    label: getVariantLabel(variantKey, v),
                  }))}
                  onChange={(variantList) => {
                    handleMadswagInfoChange({
                      ...madswagInfoState,
                      variants: {
                        ...madswagInfoState.variants,
                        [variantKey]: variantList.map((v) => v.value),
                      },
                    })
                  }}
                />
              </Col>
              <Col xs={2} md={1}>
                <ReactTooltip
                  id={`configure-variant-props-button-${variantKey}`}
                >
                  Configure prices per variant
                </ReactTooltip>
                <Button
                  data-tip=""
                  data-for={`configure-variant-props-button-${variantKey}`}
                  block
                  onClick={() => {
                    setVariantKeyToSetup(variantKey)
                    toggleVariantEditor()
                  }}
                >
                  <IoSettingsSharp />{' '}
                  {!isEqual(
                    madswagInfoState?.variantsProps[variantKey] ?? [],
                    (madswagInfo.variantsProps ?? {})[variantKey] ?? []
                  ) && (
                    <span className="text-warning">
                      <BsFillCircleFill />
                    </span>
                  )}
                </Button>
              </Col>
              <Col xs={2} md={1}>
                <Button
                  block
                  color="danger"
                  outline
                  onClick={() => {
                    setVariantToRemove(variantKey)
                    toggleVariantRemoverConfirm()
                  }}
                >
                  <FaTrash />
                </Button>
              </Col>
            </Row>
          ) : null
        )}
        <hr />
        <h5>Defaults</h5>
        <Row>
          <Col>
            <FormGroup row>
              <Label>Default Variant</Label>
              <Col>
                <Row>
                  <Col>
                    <Input
                      type="select"
                      onChange={(e) => {
                        const [defaultValue] = madswagInfoState.variants[e.target.value]
                        handleMadswagInfoChange({
                          ...madswagInfoState,
                          defaults: {
                            ...madswagInfoState.defaults,
                            variant: {
                              key: e.target.value,
                              value: defaultValue,
                            },
                          },
                        })
                      }}
                      value={madswagInfoState.defaults?.variant?.key ?? null}
                    >
                      <option value={null}>none</option>
                      {Object.entries(madswagInfoState.variants)
                        .filter((e) => e[1] != null)
                        .map(([key]) => (
                          <option key={key} value={key}>
                            {key}
                          </option>
                        ))}
                    </Input>
                  </Col>
                  <Col>
                    <Input
                      type="select"
                      onChange={(e) => {
                        handleMadswagInfoChange({
                          ...madswagInfoState,
                          defaults: {
                            ...madswagInfoState.defaults,
                            variant: {
                              ...madswagInfoState.defaults.variant,
                              value: e.target.value,
                            },
                          },
                        })
                      }}
                      value={madswagInfoState.defaults?.variant?.value ?? null}
                    >
                      {madswagInfoState.variants[
                        madswagInfoState.defaults?.variant?.key
                      ]?.map((value) => (
                        <option key={value}>{value}</option>
                      ))}
                    </Input>
                  </Col>
                </Row>
              </Col>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup row>
              <Label>Default View</Label>
              <Col md={6}>
                <Row>
                  <Col>
                    <Input
                      type="select"
                      onChange={(e) => {
                        handleMadswagInfoChange({
                          ...madswagInfoState,
                          defaults: {
                            ...madswagInfoState.defaults,
                            view: e.target.value,
                          },
                        })
                      }}
                      value={madswagInfoState.defaults?.view ?? null}
                    >
                      <option value={null}>none</option>
                      {Object.keys(madswagInfoState.views)
                        .map((key) => (
                          <option key={key} value={key}>
                            {key}
                          </option>
                        ))}
                    </Input>
                  </Col>
                </Row>
              </Col>
            </FormGroup>
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default MadswagInfo
