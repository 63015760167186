import { gql } from '@apollo/client'

export const GET_ORDER_LIST = gql`
  query GetOrders(
    $page: Int
    $limit: Int
    $search: String
    $vendor: String
    $startDate: Float
    $endDate: Float
  ) {
    orders(
      page: $page
      limit: $limit
      search: $search
      vendor: $vendor
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      wooOrderId
      vendor {
        userId
        userName
      }
      status
      allProductsMatched
      data {
        id
        date_created_gmt
        date_modified_gmt
        total
        discount_total
        customer_id
      }
    }
  }
`
export const GET_TOTAL_ORDERS = gql`
  query GetTotalOrders(
    $search: String
    $vendor: String
    $startDate: Float
    $endDate: Float
  ) {
    totalOrders(
      search: $search
      vendor: $vendor
      startDate: $startDate
      endDate: $endDate
    )
  }
`

export const GET_ORDER_BY_ID = gql`
  query GetOrderById($id: String) {
    orderById(id: $id) {
      id
      wooOrderId
      vendor {
        userId
        userName
      }
      status
      allProductsMatched
      products {
        id
        productMatched {
          id
          name
        }
        commission
        cost
        data {
          name
          product_id
          parent_name
          price
          subtotal
          total
          quantity
          total_tax
          vendor_id
          vendor_username
          vendor_commision
          official_merch
          collection_owner_commission
          collection_uuid
          collection_name
          collection_owner
          admin_productid
        }
      }
      data {
        id
        date_created_gmt
        date_modified_gmt
        total
        discount_total
        customer_id
      }
    }
  }
`

export const SAVE_ORDER = gql`
  mutation SaveOrder($id: String, $products: [OrderProductInput]) {
    saveOrder(id: $id, products: $products) {
      id
      allProductsMatched
      products {
        id
        productMatched {
          id
          name
        }
      }
    }
  }
`

export const APPLY_ALL_PRODUCT_NAME_TO_MATCHED = gql`
  mutation ApplyAllProductNameToMatched(
    $orderId: String
    $productName: String
    $productMatched: String
  ) {
    applyAllProductNameToMatched(
      orderId: $orderId
      productName: $productName
      productMatched: $productMatched
    ) {
      id
      allProductsMatched
      products {
        id
        productMatched {
          id
          name
        }
      }
    }
  }
`
