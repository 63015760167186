/* eslint-disable react/prop-types */
import React, { forwardRef, useState } from 'react'
import { Input } from 'reactstrap'
import moment from 'moment'
import ReactDatePicker from 'react-datepicker'

const DateRangeCustomInput = forwardRef(({ value, onClick }, ref) => {
  return (
    <Input
      placeholder="from - to date"
      onClick={onClick}
      ref={ref}
      defaultValue={value}
      value={value}
    />
  )
})

DateRangeCustomInput.displayName = 'DateRangeCustomInput'

export const DatePickerRange = ({ period, onChange, allowEmpty = false }) => {
  const [startDateDisplay, setStartDateDisplay] = useState(
    period?.startDate
      ? moment.utc(period?.startDate).local(true).toDate()
      : (!allowEmpty && moment().startOf('month').toDate())
  )
  const [endDateDisplay, setEndDateDisplay] = useState(
    period?.endDate
      ? moment.utc(period?.endDate).local(true).toDate()
      : (!allowEmpty && moment().endOf('month').toDate())
  )

  const onChangeDateRange = (dates) => {
    const [start, end] = dates
    setStartDateDisplay(
      start ? moment(start.getTime()).startOf('day').toDate() : null
    )
    setEndDateDisplay(end ? moment(end.getTime()).endOf('day').toDate() : null)
    if (onChange) {
      onChange({ start, end })
    }
  }

  return (
    <ReactDatePicker
      isClearable
      onChange={onChangeDateRange}
      startDate={startDateDisplay}
      endDate={endDateDisplay}
      customInput={<DateRangeCustomInput />}
      selectsRange
      dateFormat="yyyy-MM-dd"
    />
  )
}
