/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import _ from 'lodash'
import { FaExternalLinkAlt, FaTrashAlt } from 'react-icons/fa'
import { GoAlert } from 'react-icons/go'
import { Link, Redirect } from 'react-router-dom'
import {
  ListGroupItem,
  Row,
  Col,
  ListGroup,
  Button,
  Spinner,
  Alert,
  Badge,
} from 'reactstrap'
import Confirm from '../../components/Confirm'
import Select from 'react-select'
import ImgAdjustmentEditor from './ImgAdjustmentEditor'
import AppSplash from '../../components/AppSplash'

import { GET_PRODUCT_BY_ID } from './actions'
import Image from '../../components/Image'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'

const ProdSpecCard = styled.div`
  ${({ active }) => (!active ? 'background: rgba(234, 220, 188, 0.4);' : '')}
  ${({ archived }) => (archived ? 'background: rgba(234, 188, 188, 0.4);' : '')}
  padding: 1em;
`

const ProductSpecItem = ({
  specId,
  productDef,
  adjustments,
  addNewSpec,
  updateSpec,
  deleteSpec,
  isCreating,
  productsToAddNew,
  testProductWithAdjustment,
}) => {
  let productDefOrig = productDef

  if (isCreating && productsToAddNew.length > 0) {
    productDefOrig = productsToAddNew[0].id
  }

  const [productDefState, setProductDefState] = useState(productDefOrig)
  const [deleteSpecConfirmIsOpen, setDeleteSpecConfirmIsOpen] = useState(false)

  const { loading, error, data } = useQuery(GET_PRODUCT_BY_ID, {
    variables: { id: productDefState },
  })

  const { imageAdjustment } = adjustments

  const [imgAdjustmentsState, setImageAdjustmentsState] = useState(
    _.omit(imageAdjustment, '__typename')
  )

  if (
    loading ||
    (isCreating && (!productsToAddNew || productsToAddNew.length === 0))
  ) {
    return <AppSplash />
  }

  if (!data?.productById) {
    return (
      <Col xs={12} md={6} className="text-center p-4 border">
        <GoAlert size="6em" className="text-warning" />
        <div>Product Def not Found for product spec &quot;{specId}&quot;</div>
        <div className="text-info">
          Contact the system admin to review this entry
        </div>
      </Col>
    )
  }

  if (error) {
    if (error.message === 'Unauthorized') {
      return <Redirect to="/login" />
    }
    return (
      <div className="text-center m-4">
        <Alert color="danger">An error has ocurred :(</Alert>
      </div>
    )
  }

  const {
    id: productDefId,
    name,
    image,
    isActive,
    isArchived,
  } = isCreating
    ? productsToAddNew.find((p) => p.id === productDefState) || {}
    : data.productById || {}

  const productOptions = (productsToAddNew || []).map((p) => ({
    value: p.id,
    label: p.name,
  }))

  const toggleDeleteSpecConfirm = () =>
    setDeleteSpecConfirmIsOpen(!deleteSpecConfirmIsOpen)

  return (
    <div
      className={`border ${isCreating ? 'new-product-spec' : ''} col-xs-12
      col-md-6`}
      style={{ padding: 0 }}
    >
      <Confirm
        isOpen={deleteSpecConfirmIsOpen}
        toggle={toggleDeleteSpecConfirm}
        message={`Do you really want to remove the adjustment for ${name}?`}
        onYesClicked={() => deleteSpec({ id: specId })}
      />
      <ProdSpecCard
        active={isActive ? 'true' : ''}
        archived={isArchived ? 'true' : ''}
      >
        <Row className="mb-2">
          <Col xs={12} md={2} className="d-flex justify-content-center">
            {loading ? (
              <Spinner size="sm" />
            ) : (
              <div style={{ maxWidth: '8em' }}>
                <Image src={image} />
              </div>
            )}
          </Col>
          <Col style={{ zIndex: isCreating ? 10 : 'auto' }}>
            {isCreating && (
              <Select
                onChange={(selected) => {
                  setProductDefState(selected.value)
                }}
                className="text-dark"
                options={productOptions}
                defaultValue={
                  productOptions.length > 0 ? productOptions[0] : undefined
                }
                value={productOptions.find(
                  (po) => po.value === productDefState
                )}
              />
            )}
            {!isCreating && (
              <span>
                {name}{' '}
                <Link to={`/products/${productDefId}`} target="_blank">
                  <FaExternalLinkAlt />
                </Link>
              </span>
            )}{' '}
            <div>
              {!isActive && (
                <Badge color="danger" className="opacity-75">
                  Inactive
                </Badge>
              )}{' '}
              {isArchived && <Badge color="danger">Archived</Badge>}
            </div>
          </Col>
          <Col>
            <Button
              size="sm"
              className="float-end"
              style={{ marginLeft: '0.25em' }}
              color="primary"
              onClick={() => {
                if (isCreating) {
                  addNewSpec({
                    productDef: productDefState,
                    imageAdjustment: imgAdjustmentsState,
                  })
                  setProductDefState(
                    productsToAddNew.find((p) => p.id !== productDefState).id
                  )
                } else {
                  updateSpec({
                    productDef: productDefState,
                    imageAdjustment: imgAdjustmentsState,
                  })
                }
              }}
            >
              {/*isSavingSpec || isUpdatingSpec ? (
                      <Spinner size="sm" />
                    ) : (
                      ''
                    )*/}
              {isCreating ? 'Create' : 'Update'}
            </Button>
            {!isCreating && (
              <Button
                size="sm"
                className="float-end"
                color="danger"
                onClick={() => toggleDeleteSpecConfirm()}
              >
                {/*isDeletingSpec && <Spinner size="sm"/>*/} <FaTrashAlt />
              </Button>
            )}
          </Col>
        </Row>
        <Row>
          <Col>
            <ListGroup>
              {_.map(imgAdjustmentsState, (value, key) => (
                <ImgAdjustmentEditor
                  key={`${key}-${specId}`}
                  propValue={value}
                  propKey={key}
                  origValue={imageAdjustment[key]}
                  onValueChange={(e) => {
                    setImageAdjustmentsState({
                      ...imgAdjustmentsState,
                      [key]: parseFloat(e.target.value),
                    })
                  }}
                />
              ))}
              {isArchived && (
                <ReactTooltip
                  id={`${specId}-tooltip-archived-product`}
                  effect="solid"
                  className="text-center"
                >
                  Archived products will not be created anyway.
                  <div className="text-warning">
                    If you want to test on staging mode an archived
                    <div>
                      product please change the archive option to &apos;Yes&apos; on the
                      product options.
                    </div>
                  </div>
                </ReactTooltip>
              )}
              <ListGroupItem
                data-tip=""
                data-for={`${specId}-tooltip-archived-product`}
                active
                tag="button"
                action
                className={`text-center ${isArchived ? 'opacity-50' : ''}`}
                onClick={() => {
                  if (!isArchived) {
                    testProductWithAdjustment({
                      productDef: productDefState,
                      imageAdjustment: imgAdjustmentsState,
                    })
                  }
                }}
              >
                Test Product With Adjustment
              </ListGroupItem>
            </ListGroup>
          </Col>
        </Row>
      </ProdSpecCard>
    </div>
  )
}

export default ProductSpecItem
