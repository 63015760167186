import { gql } from '@apollo/client'

export const GET_OFFICIAL_MERCHS = gql`
  query GetOfficialMerchs($page: Int, $limit: Int, $status: [String]) {
    officialMerchs(page: $page, limit: $limit, status: $status) {
      id
      user {
        id
        userId
        userName
      }
      nftCollection {
        id
        identifier
      }
      status
      image
      createdAt
    }
  }
`

export const GET_OFFICIAL_MERCHS_ONLY_IMAGES = gql`
  query GetOfficialMerchsOnlyImages($package: String) {
    officialMerchs(package: $package) {
      id
      nftCollection {
        id
        identifier
      }
      image
    }
  }
`

export const GET_OFFICIAL_MERCHS_TOTAL = gql`
  query GetOfficialMerchsTotal($status: [String]) {
    officialMerchsTotal(status: $status)
  }
`

export const GET_OFFICIAL_MERCH = gql`
  query GetOfficialMerch($id: String) {
    officialMerch(id: $id) {
      id
      user {
        userId
        userName
      }
      nftCollection {
        id
        identifier
      }
      package {
        id
        name
        products {
          id
          name
          image
        }
      }
      image
      imageName
      openseaLink
      websiteLink
      contract
      status
      shopId
      description
      productSpecs {
        id
        productDef {
          id
          name
        }
        adjustments {
          imageAdjustment {
            scale
            x
            y
            angle
          }
        }
      }
      skipProducts
      addProducts
    }
  }
`

export const OFFICIAL_MERCH_ADD_NEW_PRODUCT_SPEC = gql`
  mutation OfficialMerchAddNewProductSpec(
    $productDef: String!
    $officialMerchId: String!
    $imageAdjustment: ImageAdjustmentInput
  ) {
    officialMerchAddNewProductSpec(
      productDef: $productDef
      officialMerchId: $officialMerchId
      imageAdjustment: $imageAdjustment
    ) {
      id
      productSpecs {
        id
        productDef {
          id
          name
        }
        adjustments {
          imageAdjustment {
            x
            y
            scale
            angle
          }
        }
      }
    }
  }
`

export const OFFICIAL_MERCH_UPDATE_PRODUCT_SPEC = gql`
  mutation OfficialMerchUpdateProductSpec(
    $productDef: String!
    $officialMerchId: String!
    $imageAdjustment: ImageAdjustmentInput
  ) {
    officialMerchUpdateProductSpec(
      productDef: $productDef
      officialMerchId: $officialMerchId
      imageAdjustment: $imageAdjustment
    ) {
      id
      productSpecs {
        id
        productDef {
          id
          name
        }
        adjustments {
          imageAdjustment {
            x
            y
            scale
            angle
          }
        }
      }
    }
  }
`

export const OFFICIAL_MERCH_DELETE_PRODUCT_SPEC = gql`
  mutation OfficialMerchDeleteProductSpec(
    $id: String!
    $officialMerchId: String!
  ) {
    officialMerchDeleteProductSpec(id: $id, officialMerchId: $officialMerchId)
  }
`

export const EDIT_OFFICIAL_MERCH = gql`
  mutation EditOfficialMerch(
    $id: String!
    $status: String
    $skipProducts: [String]
    $addProducts: [String]
  ) {
    editOfficialMerch(
      id: $id
      status: $status
      skipProducts: $skipProducts
      addProducts: $addProducts
    ) {
      id
      status
      skipProducts
      addProducts
    }
  }
`

export const COPY_OFFICIAL_MERCH_ADJUSTMENTS = gql`
  mutation CopyOfficialMerchAdjustments(
    $id: String!
    $copyFrom: String
  ) {
    copyOfficialMerchAdjustments(
      id: $id
      copyFrom: $copyFrom
    ) {
      id
      productSpecs {
        id
        productDef {
          id
          name
        }
        adjustments {
          imageAdjustment {
            scale
            x
            y
            angle
          }
        }
      }
    }
  }
`
