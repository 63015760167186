/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'
import { useMutation, useLazyQuery, useQuery } from '@apollo/client'
import DropdownTreeSelect from 'react-dropdown-tree-select'
import {
  Button,
  FormGroup,
  Label,
  Input,
  InputGroup,
  Col,
  Row,
  Spinner,
  Badge,
  Container,
  InputGroupText,
  NavItem,
  Nav,
  NavLink,
  TabPane,
  TabContent,
} from 'reactstrap'
import { Redirect, useHistory } from 'react-router-dom'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { omit } from 'lodash'
import { toast } from 'react-toastify'
import ReactTooltip from 'react-tooltip'
import Confirm from '../../components/Confirm'
import ProductImage from './ProductImage'
import PrintifyInfo from '../../components/PrintifyInfo'
import {
  ADD_NEW_PRODUCT,
  GET_PRINTIFY_PRODUCT_NAME,
  EDIT_PRODUCT,
  GET_WOO_CATEGORIES,
  DELETE_PRODUCT,
  GET_PRODUCTS,
  CLONE_PRODUCT_DEF,
} from './actions'
import { Madswag, Printify } from './constants'
import Image from '../../components/Image'
import { labelMap as typesLabelMap, typeList } from './ProductType'
import { GET_COLLECTIONS } from '../Collection/actions'
import AppSplash from '../../components/AppSplash'
import ShippingPricesEditor from '../../components/ShippingEditor'
import MadswagInfo from '../../components/MadswagInfo'
import { omitDeep } from '../../utils'
import axios from 'axios'

const ProductEditor = ({ editingProduct, additionalFeatureOnFooter }) => {
  const { id } = editingProduct || {}

  const history = useHistory()

  const defaultName = editingProduct?.name || ''
  const defaultType = editingProduct?.type || 'merch'
  const defaultIncludeCollections = editingProduct?.includeCollections || []
  const defaultExcludeCollections = editingProduct?.excludeCollections || []

  const defaultPrintifyInfo = editingProduct
    ? omit(editingProduct.printifyInfo, ['__typename'])
    : {}
  const defaultMadswagInfo = editingProduct?.madswagInfo ?? {}

  const defaultPlatformType = editingProduct?.platformType ?? Madswag.value
  const defaultIsActive = editingProduct?.isActive ?? false
  const defaultIsArchived = editingProduct?.isArchived ?? false
  const defaultArtworkEnabled = editingProduct?.artworkEnabled
  const defaultOfficialMerchEnabled = editingProduct?.officialMerchEnabled
  const defaultWooCategories = editingProduct?.wooCategories ?? []
  const defaultTags = editingProduct?.tags ?? []
  const defaultImage = editingProduct?.image ?? ''
  const defaultShowcaseImageIndex = editingProduct?.showcaseImageIndex ?? 0
  const defaultStoreCreatorEnabled =
    editingProduct?.storeCreatorEnabled ?? false

  const parsedShippingPrices = {
    us: omit(editingProduct?.shippingPrices?.us, ['__typename']),
    canada: omit(editingProduct?.shippingPrices?.canada, ['__typename']),
    others: omit(editingProduct?.shippingPrices?.others, ['__typename']),
  }
  const defaultShippingPrices = parsedShippingPrices
  const defaultSupplier = editingProduct?.supplier ?? Madswag.value

  const [shippingPrices, setShippingPrices] = useState(defaultShippingPrices)
  const [name, setName] = useState(defaultName)
  const [supplier, setSupplier] = useState(defaultSupplier)

  const [originalName, setOriginalName] = useState()
  const [brand, setBrand] = useState()
  const [model, setModel] = useState()

  const [image, setImage] = useState(defaultImage)
  const [type, setType] = useState(defaultType)
  const [includeCollections, setIncludeCollections] = useState(
    defaultIncludeCollections
  )
  const [excludeCollections, setExcludeCollections] = useState(
    defaultExcludeCollections
  )

  const parseVariantsObjectToList = (variantsProps) => {
    const newVariantsProps = {}
    Object.entries(variantsProps ?? {}).forEach(([key, value]) => {
      if (value) {
        newVariantsProps[key] = value.map((vProp) => {
          return {
            ...vProp,
            views: Object.values(vProp.views ?? {}),
          }
        })
      }
    })
    return newVariantsProps
  }

  const parseVariantsListToObj = (variantsProps) => {
    const newVariantsProps = {}
    Object.entries(variantsProps ?? {}).forEach(([key, value]) => {
      if (value) {
        newVariantsProps[key] = value.map((vProp) => {
          return {
            ...vProp,
            views: Object.fromEntries(
              vProp?.views?.map((view) => [view.id, view]) ?? []
            ),
          }
        })
      }
    })
    return newVariantsProps
  }

  const [printifyInfo, setPrintifyInfo] = useState(defaultPrintifyInfo)
  const [madswagInfo, setMadswagInfo] = useState({
    ...defaultMadswagInfo,
    variantsProps: parseVariantsListToObj(
      omitDeep(defaultMadswagInfo.variantsProps, '__typename')
    ),
    views: Object.fromEntries(
      defaultMadswagInfo.views?.map((view) => [view.id, view]) ?? []
    ),
  })

  const [isActive, setIsActive] = useState(defaultIsActive)
  const [isArchived, setIsArchived] = useState(defaultIsArchived)
  const [artworkEnabled, setArtworkEnabled] = useState(defaultArtworkEnabled)
  const [officialMerchEnabled, setOfficialMerchEnabled] = useState(
    defaultOfficialMerchEnabled
  )
  const [storeCreatorEnabled, setStoreCreatorEnabled] = useState(
    defaultStoreCreatorEnabled
  )

  const [deleteProductConfirmIsOpen, setDeleteProductConfirmIsOpen] =
    useState(false)

  const [cloneProductConfirmIsOpen, setCloneProductConfirmIsOpen] =
    useState(false)

  const [getPrintifyProductName] = useLazyQuery(GET_PRINTIFY_PRODUCT_NAME, {
    onCompleted: ({ printifyProductInfo: { title, brand, model } }) => {
      if (!editingProduct) {
        setName(title)
      }
      setOriginalName(title)
      setBrand(brand)
      setModel(model)
    },
  })

  const [tab, setTab] = useState(1)

  const [wooCategories, setWooCategories] = useState(defaultWooCategories)
  const [tags, setTags] = useState(defaultTags)
  const [platformType, setPlatformType] = useState(defaultPlatformType)
  const [showcaseImageIndex, setShowcaseImageIndex] = useState(
    defaultShowcaseImageIndex
  )
  const [selectedFile, setSelectedFile] = useState(null)
  const [imagePreview, setImagePreview] = useState('')
  const [savingImage, setSavingImage] = useState(false)

  const uploadImage = (callback) => {
    const formData = new FormData()
    const myRenamedFile = new File([selectedFile], id ?? new Date().getTime(), {
      type: selectedFile.type,
    })
    formData.append('image', myRenamedFile)
    setSavingImage(true)
    axios
      .post(
        // eslint-disable-next-line no-undef
        `${process.env.REACT_APP_API_URL}/image/upload/product-def`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )
      .then((response) => {
        setSavingImage(false)
        let imageUploaded
        if (response.data.urls?.length > 0) {
          imageUploaded = response.data.urls[0].url
          setImage(imageUploaded)
        }

        if (callback) {
          callback(imageUploaded)
        }
      })
      .catch((e) => {
        setSavingImage(false)
        console.error(e)
        toast.error(`Error uploading image to server. ${e.message}`)
      })
  }

  const {
    data: { getWooCategories: wooCategoriesData } = {},
    loading: loadingWooCategories,
  } = useQuery(GET_WOO_CATEGORIES)

  const [
    deleteProduct,
    { loading: loadingDeleteProduct, data: dataProductRemoved },
  ] = useMutation(DELETE_PRODUCT, {
    onCompleted: (dataDeleteProduct) => {
      const { collectionsUpdated } = dataDeleteProduct.deleteProductDef
      toast.success(
        `Product ${name} removed sucessfully! Adjustments were removed from ${collectionsUpdated} collections.`
      )
    },
    update(cache) {
      const normalizedId = cache.identify({ id, __typename: 'ProductDef' })
      cache.evict({ id: normalizedId })
      cache.gc()
    },
  })

  const [cloneProduct, { loading: loadingCloneProduct }] = useMutation(
    CLONE_PRODUCT_DEF,
    {
      onCompleted: (data) => {
        const { id } = data.cloneProductDef
        toast.success(`Product ${name} copied to it will redirect soon.`)
        setTimeout(() => {
          history.push(`${id}`)
        }, 3000)
      },
      onError: (e) => {
        toast.error(`Error cloning product. ${e.message}`)
      },
    }
  )

  useEffect(() => {
    if (platformType === Printify.value) {
      const blueprintTimeOutId = setTimeout(() => {
        getPrintifyProductName({
          variables: {
            blueprintId: printifyInfo.blueprint_id,
          },
        })
      }, 1000)
      return () => clearTimeout(blueprintTimeOutId)
    }
  }, [printifyInfo.blueprint_id, platformType, getPrintifyProductName])

  const [addNewProduct, { loading }] = useMutation(ADD_NEW_PRODUCT, {
    onCompleted: (data) => {
      toast.success('Product Created')
      history.push(`/products/${data.addNewProductDef.id}`)
    },
    onError: (err) => {
      toast.error(`Could not create product. ${err.message}`)
    },
    refetchQueries: [GET_PRODUCTS],
  })
  const [editProduct, { loading: loadingEditing }] = useMutation(EDIT_PRODUCT, {
    onCompleted: () => {
      toast.success('Product edited successfully')
    },
    onError: (err) => {
      toast.error(`Could not edit product. ${err.message}`)
    },
  })

  const { loading: loadingCollections, data: { collections } = {} } =
    useQuery(GET_COLLECTIONS)

  useEffect(() => {
    if (isArchived) {
      setIsActive(false)
    }
  }, [isArchived, setIsActive])

  const onSelectFile = (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(null)
      return
    }

    // I've kept this example simple by using the first image instead of multiple
    setSelectedFile(e.target.files[0])
  }

  useEffect(() => {
    if (!selectedFile) {
      setImagePreview(undefined)
      return
    }

    const objectUrl = URL.createObjectURL(selectedFile)
    setImagePreview(objectUrl)

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile])

  if (loadingCollections) {
    return <AppSplash />
  }

  const collectionOptions = collections.map((col) => ({
    value: col.id,
    label: col.identifier,
  }))

  const productTypeOptions = typeList.map((type) => ({
    value: type,
    label: typesLabelMap[type],
  }))

  let providerInfoOk = false

  if (platformType === Printify.value) {
    providerInfoOk =
      printifyInfo?.blueprint_id &&
      printifyInfo?.price &&
      printifyInfo?.provider
  } else if (platformType === Madswag.value) {
    providerInfoOk =
      madswagInfo?.maddiesCost != null &&
      madswagInfo?.maddiesCost > 0 &&
      name != null
  }

  const isFormOk = name && type && providerInfoOk

  const isValid =
    editingProduct && editingProduct.validation
      ? editingProduct.validation.isValid
      : true

  if (dataProductRemoved) {
    return <Redirect to="/products" />
  }

  const loadCategoriesTree = ({ categories }) => {
    const resultData = {}

    categories.forEach((cat) => {
      if (!resultData[cat.id]) {
        resultData[cat.id] = {
          label: cat.name,
          value: cat.id,
          checked: (wooCategories || []).find(
            (catFromState) => catFromState.id === cat.id
          ),
          expanded: true,
        }
      }
      const children = (wooCategoriesData || []).filter(
        (cat2) => cat2.parent === cat.id
      )
      if (children && children.length > 0) {
        resultData[cat.id].children = loadCategoriesTree({
          categories: children,
        })
      }
    })

    return Object.values(resultData)
  }
  const mainCategories = (wooCategoriesData || []).filter(
    (cat) => cat.parent === 0
  )
  const treeWooCategories = loadCategoriesTree({ categories: mainCategories })

  const platformOptions = [Printify, Madswag]
  const supplierOptions = [Printify, Madswag]

  const toggleDeleteProductConfirm = () =>
    setDeleteProductConfirmIsOpen((v) => !v)

  const toggleCloneProductConfirm = () =>
    setCloneProductConfirmIsOpen((v) => !v)

  const handleSave = (newImage) => {
    const defaultData = {
      name,
      image: newImage ?? image,
      type,
      isActive,
      isArchived,
      supplier,
      shippingPrices,
      artworkEnabled,
      officialMerchEnabled,
      includeCollections,
      excludeCollections,
      platformType,
      printifyInfo,
      madswagInfo: {
        ...madswagInfo,
        views: Object.values(madswagInfo.views),
        variantsProps: parseVariantsObjectToList(madswagInfo.variantsProps),
      },
      storeCreatorEnabled,
      showcaseImageIndex: isNaN(showcaseImageIndex) ? 0 : showcaseImageIndex,
      wooCategories,
      tags,
    }

    if (editingProduct) {
      editProduct({
        variables: omitDeep(
          {
            ...defaultData,
            id: editingProduct.id,
          },
          '__typename'
        ),
      })
    } else {
      addNewProduct({
        variables: omitDeep(defaultData, '__typename'),
      })
    }
  }

  return (
    <div>
      <Confirm
        isOpen={cloneProductConfirmIsOpen}
        toggle={toggleCloneProductConfirm}
        msgHeader="Clone Product"
        message={
          <div>
            Do you want to clone the product?
            <div className="text-danger">
              Please save any changes before clone.
            </div>
          </div>
        }
        onYesClicked={() => cloneProduct({ variables: { id } })}
      />
      <Confirm
        isOpen={deleteProductConfirmIsOpen}
        toggle={toggleDeleteProductConfirm}
        msgHeader="Removing Product"
        message={`Removing product will also remove the adjustments set for any collection related to this product. Do you really want to remove the product '${name}' completely?`}
        onYesClicked={() => deleteProduct({ variables: { id } })}
      />
      <Container className="pt-3 pb-3">
        <Row className="bg-white p-2">
          <ReactTooltip effect="solid" className="w-25" />
          {platformType === Printify.value && printifyInfo.blueprint_id && (
            <Col xs={12} md={1}>
              <ProductImage
                blueprintId={printifyInfo.blueprint_id}
                onImageLoaded={(imageLink) => {
                  if (imageLink) {
                    setImage(imageLink)
                  }
                }}
              />
            </Col>
          )}
          {platformType === Madswag.value && (
            <Col xs={12} md={2}>
              <Image src={imagePreview ?? image} />
            </Col>
          )}
          <Col xs={12} md={id ? 5 : 9}>
            <h5>
              {name}
              {!isValid && (
                <span className="float-end">
                  <Badge color="warning">Validation Errors</Badge>
                </span>
              )}
            </h5>
            {platformType === 'printify' && (
              <div className="d-flex flex-column">
                {originalName && (
                  <span>
                    Printify Name: <b>{originalName}</b>
                  </span>
                )}
                {brand && (
                  <span>
                    Brand: <b>{brand}</b>
                  </span>
                )}
                {model && (
                  <span>
                    Model: <b>{model}</b>
                  </span>
                )}
              </div>
            )}
          </Col>
          <Col>
            <div className="d-flex gap-2 justify-content-end">
              <Button
                block
                className="mr-2"
                disabled={!isFormOk}
                color="primary"
                onClick={() => {
                  if (selectedFile != null) {
                    uploadImage(handleSave)
                  } else {
                    handleSave()
                  }
                }}
              >
                {(loading || loadingEditing || savingImage) && (
                  <Spinner size="sm" />
                )}
                Save
              </Button>
              {editingProduct && (
                <>
                  <Button
                    block
                    onClick={toggleDeleteProductConfirm}
                    color="danger"
                  >
                    {loadingDeleteProduct ? <Spinner size="sm" /> : ''} Delete
                  </Button>

                  <Button
                    block
                    onClick={toggleCloneProductConfirm}
                    color="secondary"
                  >
                    {loadingCloneProduct && <Spinner size="sm" />} Clone
                  </Button>
                </>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          {platformType === Madswag.value && (
            <Col xs={12} md={3}>
              <FormGroup>
                <Label for="imageField">Image</Label>
                <Input
                  type="file"
                  onChange={onSelectFile}
                  id="imageField"
                  placeholder="Product Image"
                />
              </FormGroup>
            </Col>
          )}
        </Row>
        <hr />
        <Nav tabs>
          <NavItem>
            <NavLink
              className="cursor-pointer"
              active={tab === 1}
              onClick={() => {
                setTab(1)
              }}
            >
              Details
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className="cursor-pointer"
              active={tab === 2}
              onClick={() => setTab(2)}
            >
              Shipping
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={tab} className="pt-3 pb-3">
          <TabPane tabId={1}>
            <Row>
              <Col xs={12} md={4}>
                <FormGroup>
                  <Label for="nameField">Name</Label>
                  <Input
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    id="nameField"
                    placeholder="Product Name"
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={3}>
                <FormGroup>
                  <Label for="typeField">Type</Label>
                  <Select
                    value={{
                      value: type,
                      label: typesLabelMap[type],
                    }}
                    options={productTypeOptions}
                    onChange={(selected) => setType(selected.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
            {platformType !== Madswag.value && (
              <Row>
                <Col md={6} xs={12}>
                  <FormGroup>
                    <Label for="includeCollectionsField">
                      Include Collections
                    </Label>
                    <Select
                      value={includeCollections
                        .filter((colId) => !excludeCollections.includes(colId))
                        .map((colId) => {
                          const col = collections.find((c) => {
                            return c.id === colId
                          })
                          return {
                            value: col.id,
                            label: col.identifier,
                          }
                        })}
                      isMulti
                      isClearable
                      options={collectionOptions.filter(
                        (col) => !excludeCollections.includes(col.value)
                      )}
                      onChange={(selected) => {
                        setIncludeCollections(
                          selected.map((sCol) => sCol.value)
                        )
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md={6} xs={12}>
                  <FormGroup>
                    <Label for="excludeCollectionsField">
                      Exclude Collections
                    </Label>
                    <Select
                      value={excludeCollections.map((colId) => {
                        const col = collections.find((c) => c.id === colId)
                        return {
                          value: col.id,
                          label: col.identifier,
                        }
                      })}
                      isMulti
                      isClearable
                      options={collectionOptions.filter(
                        (col) => !includeCollections.includes(col.value)
                      )}
                      onChange={(selected) => {
                        setExcludeCollections(
                          selected.map((sCol) => sCol.value)
                        )
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
            )}
            <Row>
              <Col xs={6} md={3}>
                <FormGroup>
                  <InputGroup>
                    <InputGroupText>Is Active</InputGroupText>
                    <Button
                      disabled={isArchived}
                      color={isActive ? 'success' : 'secondary'}
                      onClick={() => setIsActive(!isActive)}
                    >
                      {isActive ? 'Yes' : 'No'}
                    </Button>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col xs={6} md={3}>
                <ReactTooltip id="is-archived-tooltip" effect="solid">
                  When product is archived it will not be created when testing
                  products on stating mode.
                </ReactTooltip>
                <FormGroup>
                  <InputGroup data-tip="" data-for="is-archived-tooltip">
                    <InputGroupText>Archived</InputGroupText>
                    <Button
                      color={isArchived ? 'success' : 'secondary'}
                      onClick={() => setIsArchived(!isArchived)}
                    >
                      {isArchived ? 'Yes' : 'No'}
                    </Button>
                  </InputGroup>
                </FormGroup>
              </Col>
              {platformType !== Madswag.value && (
                <>
                  <Col xs={6} md={3}>
                    <FormGroup>
                      <ReactTooltip id="artworkEnabled" effect="solid">
                        <div className="text-center">
                          Enable the product for artworks as well.
                          <div className="text-warning">
                            when active the NFT requests will continue to
                            include that product
                          </div>
                        </div>
                      </ReactTooltip>
                      <InputGroup data-tip="" data-for="artworkEnabled">
                        <InputGroupText>Artwork</InputGroupText>
                        <Button
                          color={artworkEnabled ? 'success' : 'secondary'}
                          onClick={() => setArtworkEnabled(!artworkEnabled)}
                        >
                          {artworkEnabled ? 'Yes' : 'No'}
                        </Button>
                      </InputGroup>
                    </FormGroup>
                  </Col>

                  <Col xs={6} md={3}>
                    <FormGroup>
                      <ReactTooltip id="officialMerchEnabled" effect="solid">
                        <div className="text-center">
                          Enable only for official merch requests.
                          <div className="text-warning">
                            when active the NFT requests will skip that product
                          </div>
                          <div className="text-warning">
                            as it is considered an official merch only product
                          </div>
                        </div>
                      </ReactTooltip>
                      <InputGroup data-tip="" data-for="officialMerchEnabled">
                        <InputGroupText>Official Merch</InputGroupText>
                        <Button
                          color={officialMerchEnabled ? 'success' : 'secondary'}
                          onClick={() =>
                            setOfficialMerchEnabled(!officialMerchEnabled)
                          }
                        >
                          {officialMerchEnabled ? 'Yes' : 'No'}
                        </Button>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </>
              )}
              <Col xs={6} md={3}>
                <FormGroup>
                  <ReactTooltip id="storeCreatorEnabled" effect="solid">
                    <div className="text-center">
                      Enable product for store creator.
                      <div className="text-warning">
                        Store creator is a new service that creates a store and
                        adjust the mockups using a canvas.
                      </div>
                    </div>
                  </ReactTooltip>
                  <InputGroup data-tip="" data-for="storeCreatorEnabled">
                    <InputGroupText>Store Creator</InputGroupText>
                    <Button
                      color={storeCreatorEnabled ? 'success' : 'secondary'}
                      onClick={() =>
                        setStoreCreatorEnabled(!storeCreatorEnabled)
                      }
                    >
                      {storeCreatorEnabled ? 'Yes' : 'No'}
                    </Button>
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6} xs={12}>
                <FormGroup>
                  <Label>Tags</Label>
                  <CreatableSelect
                    value={tags.map((v) => ({ value: v, label: v }))}
                    isMulti
                    isClearable
                    isCreatable
                    onChange={(selected) => {
                      setTags(selected.map((sCol) => sCol.value))
                    }}
                  />
                </FormGroup>
              </Col>
              <Col md={6} xs={12}>
                <FormGroup>
                  <Label>Woo Categories</Label>
                  {loadingWooCategories && (
                    <div>
                      <Spinner size="sm" />
                    </div>
                  )}
                  {!loadingWooCategories && (
                    <DropdownTreeSelect
                      data={treeWooCategories}
                      keepOpenOnSelect={true}
                      mode="hierarchical"
                      onChange={(currentNode, selectedNodes) => {
                        setWooCategories(
                          wooCategoriesData.filter((wooCat) =>
                            selectedNodes.find(
                              (selectedCat) => selectedCat.value === wooCat.id
                            )
                          )
                        )
                      }}
                    />
                  )}
                </FormGroup>
              </Col>
            </Row>
            <hr />
            <Row>
              <Col>
                <FormGroup>
                  <Label>Platform Type</Label>
                  <Select
                    type="select"
                    options={platformOptions}
                    value={platformOptions.find(
                      (po) => po.value === platformType
                    )}
                    onChange={(selected) => {
                      setPlatformType(selected.value)
                      setSupplier(selected.value)
                    }}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>Supplier</Label>
                  <Select
                    type="select"
                    options={supplierOptions}
                    value={supplierOptions.find((po) => po.value === supplier)}
                    onChange={(selected) => setSupplier(selected.value)}
                  />
                </FormGroup>
              </Col>
              {platformType !== Madswag.value && (
                <Col md={3}>
                  <FormGroup data-tip="This is used to prioritize the showcase image in case that the first image is not ideal to be the showcase one. Default is 0(the first element)">
                    <Label>Showcase Index</Label>
                    <Input
                      value={showcaseImageIndex}
                      type="number"
                      onChange={(e) => {
                        setShowcaseImageIndex(e.target.valueAsNumber)
                      }}
                    />
                  </FormGroup>
                </Col>
              )}
            </Row>
            <Row>
              <Col>
                {platformType === Madswag.value && (
                  <MadswagInfo
                    madswagInfo={omitDeep(
                      {
                        ...defaultMadswagInfo,
                        variantsProps: parseVariantsListToObj(
                          omitDeep(
                            defaultMadswagInfo.variantsProps,
                            '__typename'
                          )
                        ),
                        views: Object.fromEntries(
                          defaultMadswagInfo.views?.map((view) => [
                            view.id,
                            view,
                          ]) ?? []
                        ),
                      },
                      '__typename'
                    )}
                    onChange={(newMadswagInfo) => {
                      setMadswagInfo(newMadswagInfo)
                    }}
                  />
                )}
                {platformType === Printify.value && (
                  <PrintifyInfo
                    onInfoChange={(newPrintifyInfo) =>
                      setPrintifyInfo(newPrintifyInfo)
                    }
                    blueprintId={editingProduct?.printifyInfo?.blueprint_id}
                    price={editingProduct?.printifyInfo?.price}
                    maddiesCost={editingProduct?.printifyInfo?.maddiesCost}
                    provider={editingProduct?.printifyInfo?.provider}
                    imageAdjustment={
                      editingProduct?.printifyInfo?.imageAdjustment
                    }
                    variants={editingProduct?.printifyInfo?.variants}
                    variantsProps={editingProduct?.printifyInfo?.variantsProps}
                    variantsPropsKey={
                      editingProduct?.printifyInfo?.variantsPropsKey
                    }
                    editingProduct={editingProduct ? true : false}
                  />
                )}
              </Col>
            </Row>

            {additionalFeatureOnFooter && (
              <>
                <hr />
                <Row style={{ zIndex: 0 }}>{additionalFeatureOnFooter}</Row>
              </>
            )}
          </TabPane>
          <TabPane tabId={2}>
            <Row>
              <Col>
                <ShippingPricesEditor
                  defaultShippingPrices={defaultShippingPrices}
                  onChange={(prices) => setShippingPrices(prices)}
                />
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </Container>
    </div>
  )
}

export default ProductEditor
