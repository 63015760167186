import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import { GET_PACKAGES } from './actions'
import AppSplash from '../../components/AppSplash'
import { ListGroup, ListGroupItem, Row, Col, Button, Modal } from 'reactstrap'
import Editor from './editor'
import Image from '../../components/Image'
import ReactTooltip from 'react-tooltip'
import { Link } from 'react-router-dom'

export default function Packages() {
  const { loading, data: { packages } = {} } = useQuery(GET_PACKAGES)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen)
  }

  if (loading) {
    return <AppSplash />
  }
  return (
    <div className="p-3">
      <ReactTooltip effect="solid" />
      <Modal isOpen={isModalOpen} toggle={toggleModal}>
        <Editor isCreating={true} onPackageAdded={() => toggleModal()} />
      </Modal>
      <Row>
        <Col>
          <Button color="primary" onClick={() => toggleModal()}>
            New
          </Button>
        </Col>
      </Row>
      <hr />
      <ListGroup>
        {packages.map((pack) => (
          <ListGroupItem
            key={pack.id}
            action
            className="cursor-pointer"
            tag={Link}
            to={`/package/${pack.id}`}
          >
            <Row>
              <Col md={2}>{pack.name}</Col>
              <Col>
                <Row>
                  {pack?.products?.map((p) => (
                    <Col key={p.id} xs={1} style={{minWidth: '5em'}} data-tip={p.name}>
                      <Image src={p.image} />
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          </ListGroupItem>
        ))}
      </ListGroup>
    </div>
  )
}
