import styled from 'styled-components'

export default styled.span`
  color: #575757;
  padding: 0 0.2em;
  border: 1px solid transparent;
  border-radius: 3px;
  &:hover {
    border: 1px solid #575757;
  }
  font-size: 0.75em;
`