import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
const ArtworkImg = ({ src, height, width }) => (
  <LazyLoadImage
    className="rounded"
    alt="artwork"
    height={height}
    src={src} // use normal <img> attributes as props
    width={width}
  />
)

export default ArtworkImg
