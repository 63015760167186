import { useLazyQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import {
  Badge,
  Button,
  Col,
  Input,
  Modal,
  Row,
  Spinner,
  Table,
} from 'reactstrap'
import Select from 'react-select'
import { GET_ORDER_LIST, GET_TOTAL_ORDERS } from './actions'
import { GET_NFT_USERS } from '../NFTUser/actions'
import moment from 'moment'
import { OrderStatusColor } from '../../utils/status'
import ReactTooltip from 'react-tooltip'
import OrderEditor from './editor'
import { Pagination } from '../../components/Pagination'
import { DatePickerRange } from '../../components/DatePickerRange'

export default function OrderList() {
  const [selectedOrderToView, setSelectedOrderToView] = useState(false)
  const [isOrderEditorModalOpen, setIsOrderEditorModalOpen] = useState(false)
  const [page, setPage] = useState(1)
  const defaultLimit = 50
  const [limit, setLimit] = useState(defaultLimit)
  const [user, setUser] = useState()

  const [userFilter, setUserFilter] = useState('')
  const [userFilterTextToDebounce, setUserFilterTextToDebounce] = useState('')
  const [filter, setFilter] = useState('')
  const [filterTextToDebounce, setFilterTextToDebounce] = useState('')

  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const onChangeDateRange = ({ start, end }) => {
    setStartDate(start)
    setEndDate(end)
  }

  const [getOrders, { loading, data: { orders = [] } = {} }] =
    useLazyQuery(GET_ORDER_LIST)

  const [
    getTotalOrders,
    { loading: loadingTotalOrders, data: { totalOrders } = {} },
  ] = useLazyQuery(GET_TOTAL_ORDERS)

  const [getNftUsers, { loading: loadingUsers, data: { nftUsers } = {} }] =
    useLazyQuery(GET_NFT_USERS, { fetchPolicy: 'network-only' })

  useEffect(() => {
    const query = {
      variables: {
        limit,
        page,
        search: filter,
        vendor: user?.id,
        startDate: startDate
          ? moment.utc(startDate).startOf('day').valueOf()
          : null,
        endDate: endDate ? moment.utc(endDate).endOf('day').valueOf() : null,
      },
    }
    getOrders(query)
    getTotalOrders(query)
  }, [filter, limit, page, user, getOrders, startDate, endDate, getTotalOrders])

  useEffect(() => {
    const filterTimeoutId = setTimeout(() => {
      setFilter(filterTextToDebounce)
    }, 700)
    return () => clearTimeout(filterTimeoutId)
  }, [filterTextToDebounce, setFilter])

  useEffect(() => {
    if (userFilter) {
      getNftUsers({
        variables: {
          search: userFilter,
        },
      })
    }
  }, [userFilter, getNftUsers])

  useEffect(() => {
    const userFilterTimeoutId = setTimeout(() => {
      setUserFilter(userFilterTextToDebounce)
    }, 700)
    return () => clearTimeout(userFilterTimeoutId)
  }, [userFilterTextToDebounce, setFilter])

  useEffect(() => {
    getOrders({
      variables: {
        limit: 50,
        page: 1,
        search: '',
      },
    })
    getTotalOrders({
      variables: {
        limit: 50,
        page: 1,
        search: '',
      },
    })
  }, [getOrders, getTotalOrders])

  const getDateCreatedText = (timestamp) => {
    const date = moment(timestamp).utcOffset(0)
    return date.format('YYYY-MM-DD HH:mm')
  }

  const nftUsersOptions =
    nftUsers?.map((u) => ({
      value: u?.id,
      label: `${u?.userId} - ${u?.userName}`,
    })) || []

  const toggleOrderEditorModal = () =>
    setIsOrderEditorModalOpen(!isOrderEditorModalOpen)

  const onOrderClicked = (id) => {
    setSelectedOrderToView(id)
    toggleOrderEditorModal()
  }

  return (
    <div className="p-3">
      <Modal
        size="lg"
        style={{ minWidth: '90%' }}
        isOpen={isOrderEditorModalOpen}
        toggle={toggleOrderEditorModal}
      >
        <OrderEditor orderId={selectedOrderToView} />
      </Modal>
      <ReactTooltip effect="solid" />
      <Row className="mt-2">
        <Col>
          <Button
            onClick={() => {
              const query = {
                variables: {
                  limit,
                  page,
                  search: filter,
                  vendor: user?.id,
                  startDate: startDate
                    ? moment.utc(startDate).startOf('day').valueOf()
                    : null,
                  endDate: endDate
                    ? moment.utc(endDate).endOf('day').valueOf()
                    : null,
                },
              }
              getOrders(query)
              getTotalOrders(query)
            }}
          >
            Refresh {loading && <Spinner size="sm" />}
          </Button>
        </Col>
        <Col>
          <Pagination
            total={totalOrders}
            defaultLimit={defaultLimit}
            loadingTotal={loadingTotalOrders}
            onLimitChange={({ limit }) => setLimit(limit)}
            onPageChange={({ page }) => setPage(page)}
          />
        </Col>
      </Row>
      <Row className="text-center">
        <Col>
          <Table hover>
            <thead>
              <tr>
                <td></td>
                <td style={{ maxWidth: '7em' }}>
                  <Input
                    placeholder="search order"
                    value={filterTextToDebounce}
                    onChange={(e) => {
                      setFilterTextToDebounce(e.target.value)
                    }}
                  />
                </td>
                <td></td>
                <td style={{ minWidth: '10em' }}>
                  <Select
                    placeholder="Vendor Filter"
                    value={
                      user
                        ? {
                            value: user.id,
                            label: `${user.userId} - ${user.userName}`,
                          }
                        : null
                    }
                    isClearable
                    options={nftUsersOptions}
                    onInputChange={(text) => {
                      setUserFilterTextToDebounce(text)
                    }}
                    onChange={(selected) => {
                      setUser(
                        selected
                          ? nftUsers.find((u) => u.id === selected.value)
                          : null
                      )
                    }}
                    isLoading={loadingUsers}
                  />
                </td>
                <td></td>
                <td>
                  <DatePickerRange onChange={onChangeDateRange} />
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <th></th>
                <th>Order ID</th>
                <th>Customer ID</th>
                <th>Vendor ID</th>
                <th>Vendor Name</th>
                <th>Date Created(GMT)</th>
                <th data-tip="Yes when all products from order matches with admin products">
                  Products Matched
                </th>
                <th>Status</th>
                <th>Discount</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {orders?.map((order, i) => (
                <tr
                  key={order.id}
                  className="cursor-pointer"
                  onClick={() => onOrderClicked(order?.id)}
                >
                  <th>{i + 1 + (page - 1) * limit}</th>
                  <td>{order?.data?.id}</td>
                  <td>{order?.data?.customer_id}</td>
                  <td>{order?.vendor?.userId}</td>
                  <td>{order?.vendor?.userName}</td>
                  <td>{getDateCreatedText(order?.data?.date_created_gmt)}</td>
                  <td>
                    <Badge
                      color={order?.allProductsMatched ? 'success' : 'danger'}
                    >
                      {order?.allProductsMatched ? 'yes' : 'no'}
                    </Badge>
                  </td>
                  <td>
                    <Badge
                      className="opacity-50"
                      color={OrderStatusColor[order?.status] || 'secondary'}
                    >
                      {order?.status}
                    </Badge>
                  </td>
                  <td>$ {order?.data?.discount_total}</td>
                  <td>$ {order?.data?.total}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  )
}
