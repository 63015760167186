import React, { useEffect, useState } from 'react'
import {
  ListGroupItem,
  Row,
  Col,
  ListGroupItemHeading,
  Badge,
  InputGroup,
  InputGroupText,
  Input,
  Alert,
  ListGroup,
  Spinner,
} from 'reactstrap'
import { FaSearch } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import CollectionCreator from './editor'
import moment from 'moment'
import Image from '../../components/Image'
import { useLazyQuery, useQuery } from '@apollo/client'
import { GET_COLLECTIONS, GET_TOTAL_COLLECTIONS } from './actions'
import AppSplash from '../../components/AppSplash'
import { Pagination } from '../../components/Pagination'

const CollectionList = () => {
  const [page, setPage] = useState(1)
  const defaultLimit = 10
  const [limit, setLimit] = useState(defaultLimit)
  const [filter, setFilter] = useState('')
  const [filterTextToDebouce, setFilterTextToDebouce] = useState('')
  const [getCollections, { loading, error, data: { collections = [] } = {} }] =
    useLazyQuery(GET_COLLECTIONS, { fetchPolicy: 'network-only' })

  const {
    loading: loadingTotalCollections,
    error: errorTotalCollections,
    data: { totalCollections } = {},
  } = useQuery(GET_TOTAL_COLLECTIONS, { variables: { search: filter } })

  useEffect(() => {
    getCollections({
      variables: {
        limit,
        page,
        search: filter,
      },
    })
  }, [filter, limit, page, getCollections])

  useEffect(() => {
    const filterTimeoutId = setTimeout(() => {
      setFilter(filterTextToDebouce)
    }, 700)
    return () => clearTimeout(filterTimeoutId)
  }, [filterTextToDebouce, setFilter])

  useEffect(() => {
    getCollections({
      variables: {
        limit: 10,
        page: 1,
        search: '',
      },
    })
  }, [getCollections])

  if (error || errorTotalCollections) {
    return (
      <Alert color="danger">
        Something went wrong :(. {error?.message}{' '}
        {errorTotalCollections?.message}
      </Alert>
    )
  }

  return (
    <div>
      <ReactTooltip effect="solid" />
      <Row>
        <Col md={2}>
          <CollectionCreator
            collections={collections}
            buttonLabel="New Collection"
            block
          />
        </Col>
        <Col>
          <InputGroup>
            <InputGroupText>
              <FaSearch />
            </InputGroupText>
            <Input
              placeholder="search collection"
              value={filterTextToDebouce}
              onChange={(e) => {
                setFilterTextToDebouce(e.target.value)
              }}
            />
          </InputGroup>
        </Col>
        <Col md={1}>{loading && <Spinner type="grow" size="sm" />}</Col>
        <Col>
          <Pagination
            defaultLimit={defaultLimit}
            loading={loadingTotalCollections}
            total={totalCollections}
            onLimitChange={({ limit }) => setLimit(limit)}
            onPageChange={({ page }) => setPage(page)}
          />
        </Col>
      </Row>
      <hr />
      {loading && !collections && <AppSplash />}
      <ListGroup>
        {collections?.map((collection) => (
          <ListGroupItem
            key={collection.id}
            tag={Link}
            to={`/collections/${collection.id}`}
            action
            style={{ cursor: 'auto' }}
          >
            <Row>
              <Col md={1}>
                <Image src={collection.linkSample} lowRes />
              </Col>
              <Col>
                <ListGroupItemHeading>
                  {collection.identifier} - {collection.initials}
                </ListGroupItemHeading>
                {collection?.owner && 'Owner:'}{' '}
                {collection?.owner && (
                  <Badge color="primary">
                    {collection?.owner?.userName} - {collection?.owner?.userId}
                  </Badge>
                )}{' '}
                {collection?.owner && (
                  <>
                    Commission:{' '}
                    <Badge color="primary">
                      {collection?.ownerCommission}%
                    </Badge>
                  </>
                )}
              </Col>
              <Col className="text-end" md={4}>
                {!collection?.sentToWordpress && (
                  <Badge color="danger">not sent to wp</Badge>
                )}{' '}
                <Badge style={{ opacity: 0.5 }} data-tip="Created At">
                  {moment(parseInt(collection.createdAt)).format('MMMM Do')}
                </Badge>{' '}
                <Badge color={collection.isActive ? 'success' : 'secondary'}>
                  {collection.isActive ? 'active' : 'inactive'}
                </Badge>
              </Col>
            </Row>
          </ListGroupItem>
        ))}
      </ListGroup>
    </div>
  )
}

export default CollectionList
