/* eslint-disable react/prop-types */
import { useMutation } from '@apollo/client'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { Button } from 'reactstrap'
import { DELETE_STAGING_PRODUCTS } from '../../containers/Product/actions'
import { isSupportGroup } from '../../utils/permissions'
import Confirm from '../Confirm'

const CleanStoreButton = ({ userType }) => {
  const [rmProductsConfirmIsOpen, setRmProductsConfirmIsOpen] = useState(false)
  const toggleRmProductsConfirm = () =>
    setRmProductsConfirmIsOpen(!rmProductsConfirmIsOpen)

  const [deleteStagingProducts] = useMutation(DELETE_STAGING_PRODUCTS)

  if (!isSupportGroup(userType)) {
    return null
  }

  return (
    <span>
      <Confirm
        isOpen={rmProductsConfirmIsOpen}
        toggle={toggleRmProductsConfirm}
        message={`Do you really want to remove the products inside staging store?`}
        onYesClicked={() => {
          deleteStagingProducts()
          toast.success('Products should be removed shortly.')
        }}
      />
      <Button
        color="secondary"
        outline
        size="sm"
        onClick={toggleRmProductsConfirm}
        style={{ verticalAlign: 'text-top' }}
      >
        Clean Store
      </Button>
    </span>
  )
}
const mapStateToProps = (state) => ({
  userType: state?.user?.user?.type,
})

export default connect(mapStateToProps)(CleanStoreButton)
