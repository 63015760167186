import { useLazyQuery, useMutation } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import {
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
  Table,
} from 'reactstrap'
import AppSplash from '../../components/AppSplash'
import {
  CALCULATE_COMMISSION_BY_VENDOR,
  GET_COMMISSION_REPORTS,
  GET_COMMISSION_REPORTS_TOTAL,
  EXPORT_COMMISSIONS,
} from './actions'
import { CommReportViewer } from './viewer'
import CommissionStatus from './CommissionStatus'
import SelectUser from '../NFTUser/SelectUser'
import { Pagination } from '../../components/Pagination'
import moment from 'moment'
import { toast } from 'react-toastify'
import ReactTooltip from 'react-tooltip'
import { UPDATE_USERS_INFO_FROM_WP } from './actions'
import { MdSaveAlt } from 'react-icons/md'
import { ReportItem } from './ReportItem'
import { DatePickerRange } from '../../components/DatePickerRange'
import ExportedList from './ExportedList'
import { GoTasklist } from 'react-icons/go'

const CommissionList = () => {
  const defaultLimit = 20

  const [forceRecalculation, setForceRecalculation] = useState(false)
  const [autoRefresh, setAutoRefresh] = useState(false)
  const [vendor, setVendor] = useState()
  const [limit, setLimit] = useState(defaultLimit)
  const [page, setPage] = useState(1)
  const [startDate, setStartDate] = useState(
    moment.utc().startOf('month').valueOf()
  )
  const [endDate, setEndDate] = useState(moment.utc().endOf('month').valueOf())
  const [editorIsOpen, setEditorIsOpen] = useState(false)
  const [exportedListIsOpen, setExportedListIsOpen] = useState(false)
  const [vendorSelected, setVendorSelected] = useState()
  const [officialCollectionSelected, setOfficialCollectionSelected] = useState()

  const [getCommissionReports, { data, loading }] = useLazyQuery(
    GET_COMMISSION_REPORTS,
    {
      fetchPolicy: 'network-only',
      pollInterval: autoRefresh ? 10 * 1000 : 0,
      variables: {
        vendor: vendor?.id,
        limit,
        page,
        period: {
          startDate: startDate,
          endDate: endDate,
        },
      },
    }
  )

  const [updateUsersInfo, { loading: loadingUpdateUsersInfo }] = useMutation(
    UPDATE_USERS_INFO_FROM_WP
  )

  const [
    getCommissionReportsTotal,
    {
      data: {
        commissionReportsTotal: {
          count: total,
          commissionsTotal,
          officialCommission,
          nftoCommission,
          paidTotal,
          owedTotal,
        } = {},
      } = {},
      loading: loadingTotal,
    },
  ] = useLazyQuery(GET_COMMISSION_REPORTS_TOTAL, {
    fetchPolicy: 'network-only',
    pollInterval: autoRefresh ? 10 * 1000 : 0,
    variables: {
      vendor: vendor?.id,
      limit,
      page,
      period: {
        startDate: startDate ?? null,
        endDate: endDate ?? null,
      },
    },
  })

  const [exportCommissions] = useLazyQuery(EXPORT_COMMISSIONS, {
    onCompleted: () => {
      toast.info('Export task started.')
    },
    onError: (error) => {
      toast.error(`Error exporting commissions. "${error?.message}"`)
    },
    fetchPolicy: 'network-only',
  })

  const [
    calculateCommissionByVendor,
    { loading: isCalculatingCommissionByVendor },
  ] = useMutation(CALCULATE_COMMISSION_BY_VENDOR, {
    onCompleted: () => {
      toast.success('Commissions will be calculating in background!')
    },
    onError: (error) => {
      toast.error(error.message)
    },
  })

  const toggleEditor = () => setEditorIsOpen(!editorIsOpen)
  const toggleExportedList = () => setExportedListIsOpen(!exportedListIsOpen)

  const onChangeDateRange = ({ start, end }) => {
    setStartDate(moment.utc(start).startOf('day').valueOf())
    setEndDate(moment.utc(end).endOf('day').valueOf())
  }

  const handleSelectCommissionReport = ({ vendor, officialCollection }) => {
    setEditorIsOpen(true)
    setVendorSelected(vendor)
    setOfficialCollectionSelected(officialCollection)
  }

  useEffect(() => {
    const query = {
      vendor: vendor?.id,
      limit,
      page,
      period: {
        startDate,
        endDate,
      },
    }
    getCommissionReports(query)
    getCommissionReportsTotal(query)
  }, [
    getCommissionReports,
    getCommissionReportsTotal,
    startDate,
    endDate,
    page,
    limit,
    vendor,
  ])

  return (
    <Container fluid>
      <Modal
        isOpen={exportedListIsOpen}
        toggle={toggleExportedList}
        unmountOnClose
        size="lg"
      >
        <ModalHeader>Exported List</ModalHeader>
        <ModalBody>
          <ExportedList />
        </ModalBody>
      </Modal>
      <Modal
        style={{ minWidth: '90%' }}
        isOpen={editorIsOpen}
        toggle={toggleEditor}
        size="lg"
        unmountOnClose
      >
        <CommReportViewer
          vendor={vendorSelected}
          period={{
            startDate,
            endDate,
          }}
          officialCollection={officialCollectionSelected?.id}
        />
      </Modal>
      <div className="m-2" />
      <CommissionStatus hideWhenStopped />
      <Row>
        <Col md={5}>
          <ReactTooltip
            id="calc-commissions-tooltip"
            effect="solid"
            place="bottom"
            className="text-center"
          >
            Calculate the commissions by vendor
            <div>
              using the period defined in{' '}
              <span className="text-warning">from-to</span> filter.
            </div>
          </ReactTooltip>
          <Button
            data-tip=""
            data-for="calc-commissions-tooltip"
            color="primary"
            onClick={() => {
              if (!startDate) {
                toast.info('Define a start to calculate the commissions.')
                return
              }
              calculateCommissionByVendor({
                variables: {
                  startDate,
                  endDate,
                  forceRecalculation,
                },
              })
            }}
          >
            Calculate {isCalculatingCommissionByVendor && <Spinner size="sm" />}
          </Button>{' '}
          <Button
            outline
            onClick={() => {
              getCommissionReports()
              getCommissionReportsTotal()
            }}
          >
            Refresh {loading && <Spinner size="sm" />}
          </Button>{' '}
          <Button outline onClick={() => updateUsersInfo()}>
            Update User info {loadingUpdateUsersInfo && <Spinner size="sm" />}
          </Button>{' '}
          <Button
            outline
            disabled={!total}
            onClick={() =>
              exportCommissions({
                variables: {
                  vendor: vendor?.id,
                  period: {
                    startDate: startDate ?? null,
                    endDate: endDate ?? null,
                  },
                },
              })
            }
          >
            Export <MdSaveAlt />{' '}
          </Button>{' '}
          <ReactTooltip id="export-list-tooltip" place="bottom" effect="solid">
            See export list
          </ReactTooltip>
          <Button
            outline
            onClick={toggleExportedList}
            data-tip=""
            data-for="export-list-tooltip"
          >
            <GoTasklist />
          </Button>
          <div className="mt-1">
            <span className="d-inline-block">
              <FormGroup check>
                <Input
                  id="autoRefreshField"
                  type="checkbox"
                  checked={autoRefresh}
                  onChange={() => setAutoRefresh(!autoRefresh)}
                />{' '}
                <Label check for="autoRefreshField">
                  Auto refresh
                </Label>
              </FormGroup>
            </span>{' '}
            <ReactTooltip
              id="force-calc-commissions-tooltip"
              effect="solid"
              place="bottom"
              className="text-center"
            >
              It will recalculate the commissions and replace
              <div>the ones on each product inside the orders in period.</div>
              <div className="text-warning">
                The normal calc gets the calculated value
                <div>from order products to avoid calculate again.</div>
              </div>
            </ReactTooltip>
            <span className="d-inline-block">
              <FormGroup
                check
                data-tip=""
                data-for="force-calc-commissions-tooltip"
              >
                <Input
                  id="forceRecalcField"
                  type="checkbox"
                  checked={forceRecalculation}
                  onChange={() => setForceRecalculation(!forceRecalculation)}
                />{' '}
                <Label check for="forceRecalcField">
                  Force re-calc
                </Label>
              </FormGroup>
            </span>{' '}
          </div>
        </Col>
        <Col>
          <Table bordered>
            <tbody>
              <tr>
                <td className="table-secondary">
                  {nftoCommission?.toFixed(2)} (NFTO)
                </td>
                <td className="table-secondary">
                  {officialCommission?.toFixed(2)} (Official)
                </td>
                <td className="table-primary">
                  {commissionsTotal?.toFixed(2)} (Total)
                </td>
                <td className="table-warning">
                  {owedTotal?.toFixed(2)} (Owed)
                </td>
                <td className="table-success">
                  {paidTotal?.toFixed(2)} (Paid)
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row>
        <Col md={2}>
          <SelectUser
            onUserSelected={(user) => {
              setVendor(user)
            }}
          />
        </Col>
        <Col md={3}>
          <DatePickerRange onChange={onChangeDateRange} />
        </Col>
        <Col className="text-end">
          <Pagination
            defaultLimit={defaultLimit}
            total={total}
            loadingTotal={loadingTotal}
            onLimitChange={({ limit }) => setLimit(limit)}
            onPageChange={({ page }) => setPage(page)}
          />
        </Col>
      </Row>
      {data?.commissionReports?.length > 0 && loading && <AppSplash />}
      <Table hover>
        <thead>
          <tr>
            <th style={{ width: '1em' }}>#</th>
            <th className="col-md-2">Vendor</th>
            <th>Email</th>
            <th>Official</th>
            <th className="col-md-1">Errors</th>
            <th className="col-md-1">Owed</th>
            <th className="col-md-1">Paid</th>
          </tr>
        </thead>
        <tbody>
          {data?.commissionReports?.map((cr, i) => {
            let status = { color: 'success', text: 'valid' }

            if (cr.hasWarns) {
              status = { color: 'warning', text: 'warn' }
            }

            if (cr.hasErrors) {
              status = { color: 'danger', text: 'error' }
            }

            return (
              <ReportItem
                key={cr?.id}
                status={status}
                {...cr}
                onClick={() =>
                  handleSelectCommissionReport({
                    vendor: cr.vendor,
                    officialCollection: cr.officialCollection,
                  })
                }
                index={i}
                limit={limit}
                page={page}
              />
            )
          })}
        </tbody>
      </Table>
    </Container>
  )
}

export default CommissionList
