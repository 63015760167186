/* eslint-disable react/prop-types */
import { useLazyQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { GET_NFT_USERS } from './actions'

export default function SelectUser({ onUserSelected, defaultUser }) {
  const [userFilterTextToDebouce, setUserFilterTextToDebouce] = useState('')
  const [userFilter, setUserFilter] = useState('')
  const [user, setUser] = useState(defaultUser)

  const [getNftUsers, { loading: loadingUsers, data: { nftUsers } = {} }] =
    useLazyQuery(GET_NFT_USERS, { fetchPolicy: 'network-only' })
    
  useEffect(() => {
    const userFilterTimeoutId = setTimeout(() => {
      setUserFilter(userFilterTextToDebouce)
    }, 700)
    return () => clearTimeout(userFilterTimeoutId)
  }, [userFilterTextToDebouce])

  useEffect(() => {
    if (userFilter) {
      getNftUsers({
        variables: {
          search: userFilter,
        },
      })
    }
  }, [userFilter, getNftUsers])

  const nftUsersOptions =
    nftUsers?.map((u) => ({
      value: u?.id,
      label: `${u?.userId} - ${u?.userName}`,
    })) || []

  return (
    <div style={{zIndex: 20}}>
    
    <Select
      placeholder="User Filter"
      value={user
        ? {
            value: user.id,
            label: `${user.userId} - ${user.userName}`,
          }
        : null}
        isClearable
        options={nftUsersOptions}
      onInputChange={(text) => {
        setUserFilterTextToDebouce(text)
      }}
      onChange={(selected) => {
        const userSelected = selected ? nftUsers.find((u) => u.id === selected.value) : null
        setUser(userSelected)
        if (onUserSelected) {
          onUserSelected(userSelected)
        }
      }}
      isLoading={loadingUsers}
      
    />
    </div>
  )
}
