import React, { useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import Select from 'react-select'
import { toast } from 'react-toastify'
import {
  Alert,
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from 'reactstrap'
import AppSplash from '../../components/AppSplash'
import {
  ARTWORK_ADD_NEW_PRODUCT_SPEC,
  ARTWORK_DELETE_PRODUCT_SPEC,
  ARTWORK_UPDATE_PRODUCT_SPEC,
  EDIT_ARTWORK,
} from './actions'
import ProductSpecEditor from '../Product/ProductSpecEditor'
import { GET_ARTWORK } from './actions'
import ArtworkImg from './ArtworkImg'
import { labelMap as typesLabelMap, typeList } from '../Product/ProductType'
import Confirm from '../../components/Confirm'
import { FaRocket } from 'react-icons/fa'
import { BiTestTube } from 'react-icons/bi'
import { GET_PRODUCTS } from '../Product/actions'
import { SpecSpinner } from '../Collection/viewer'
import { Status } from '../../utils/status'

const ArtworkEditor = () => {
  const { id } = useParams()
  const [name, setName] = useState('')
  const [imageLink, setImageLink] = useState('')
  const [priceMargin, setPriceMargin] = useState(0)
  const [productTypes, setProductTypes] = useState([])
  const [status, setStatus] = useState(Status.ONHOLD)
  // const [productSpecs, setProductSpecs] = useState([])
  const [skipProducts, setSkipProducts] = useState([])
  const [updater, setUpdater] = useState(false)

  const [confirmCreateProductsIsOpen, setConfirmCreateProductsIsOpen] =
    useState(false)

  const { loading, error, data } = useQuery(GET_ARTWORK, {
    variables: { id },
    onCompleted: ({ artwork }) => {
      setName(artwork.name)
      setImageLink(artwork.imageLink)
      setPriceMargin(artwork.priceMargin)
      setProductTypes(artwork.productTypes)
      setStatus(artwork.status)
      setSkipProducts(artwork.skipProducts)
    },
  })

  const [addNewSpec, { loading: isSavingSpec }] = useMutation(
    ARTWORK_ADD_NEW_PRODUCT_SPEC,
    {
      onCompleted: () => {
        toast.success('Adjustment created!')
      },
    }
  )

  const [updateSpec, { loading: isUpdatingSpec }] = useMutation(
    ARTWORK_UPDATE_PRODUCT_SPEC,
    {
      onCompleted: () => {
        toast.success('Adjustment updated!')
      },
    }
  )

  const [updateArtwork, { loading: updatingArtwork }] = useMutation(
    EDIT_ARTWORK,
    {
      onCompleted: () => {
        toast.success('Artwork saved successfully!')
      },
      onError: (error) => {
        toast.error(`Error saving artwork. ${error.message}`)
      },
    }
  )

  const [deleteSpec, { loading: isDeletingSpec }] = useMutation(
    ARTWORK_DELETE_PRODUCT_SPEC,
    {
      onCompleted: () => {
        setUpdater(!updater)
        toast.success('Adjustment removed!')
      },
      update(cache, { data: { artworkDeleteProductSpec } }) {
        console.log({ artworkDeleteProductSpec })
        const normalizedId = cache.identify({
          id: artworkDeleteProductSpec,
          __typename: 'ProductSpec',
        })
        console.log({ normalizedId })
        cache.evict({ id: normalizedId })
        cache.gc()
      },
    }
  )

  const { loading: loadingProducts, data: { products } = {} } =
    useQuery(GET_PRODUCTS)

  if (loading || loadingProducts) {
    return <AppSplash />
  }

  if (error) {
    return (
      <Alert color="danger">
        Error loading data from server :( ${error.message}
      </Alert>
    )
  }

  const { productSpecs } = data.artwork

  const productTypeOptions = typeList.map((type) => ({
    value: type,
    label: typesLabelMap[type],
  }))

  const statusOptions = [
    { value: 'onhold', label: 'On Hold' },
    { value: 'inprogress', label: 'In Progress' },
    { value: 'creating', label: 'Creating' },
    { value: 'invalid', label: 'Invalid' },
    { value: 'duplicated', label: 'Duplicated' },
    { value: 'done', label: 'Done' },
  ]

  const productTypesSelected = productTypeOptions.filter((pto) =>
    productTypes.includes(pto.value)
  )

  const testProduct = ({ productDef, imageAdjustment }) => {
    const data = {
      artworkId: id,
      isStaging: true,
      userName: 'staging-api',
      userId: '1',
    }

    if (productDef) {
      data.productFilter = productDef
    }

    if (imageAdjustment) {
      data.adjustmentsFilter = { imageAdjustment }
    }

    axios
      // eslint-disable-next-line no-undef
      .post(process.env.REACT_APP_API_URL + '/artwork/products/create', data)
      .then(() => toast.success(`Request sent successfully!`))
      .catch((e) => toast.error(`Error creating products. ${e.message}`))
  }

  const createProductionProducts = ({ artworkId }) => {
    const data = { artworkId }

    axios
      // eslint-disable-next-line no-undef
      .post(process.env.REACT_APP_API_URL + '/artwork/products/create', data)
      .then(() => toast.success(`Request sent successfully!`))
      .catch((e) => toast.error(`Error creating products. ${e.message}`))
  }

  const productOptions = products?.map((p) => ({ value: p.id, label: p.name }))

  const toggleConfirmCreateProducts = () =>
    setConfirmCreateProductsIsOpen(!confirmCreateProductsIsOpen)

  return (
    <div>
      <Confirm
        isOpen={confirmCreateProductsIsOpen}
        message="Do you really want to create all the products on the PRODUCTION store?"
        toggle={toggleConfirmCreateProducts}
        onYesClicked={() => createProductionProducts({ artworkId: id })}
      />
      <Row>
        {(isUpdatingSpec || isSavingSpec || isDeletingSpec) && <SpecSpinner />}
        <Col xs={12} md={3} className="text-center">
          <ArtworkImg src={imageLink} width="200em" />
          <Row className="mt-3">
            <Col md={{ size: 8, offset: 2 }}>
              <Button block onClick={toggleConfirmCreateProducts}>
                <FaRocket /> Create Products
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md={{ size: 8, offset: 2 }}>
              <Button
                className="mt-2"
                block
                onClick={() => testProduct({})}
                color="primary"
              >
                <BiTestTube /> Test Products
              </Button>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col>
              <Form>
                <FormGroup row>
                  <Label for="nameInput" sm={2}>
                    Name
                  </Label>
                  <Col sm={10}>
                    <Input id="nameInput" value={name} disabled />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="priceMarginInput" sm={2}>
                    Price Margin %
                  </Label>
                  <Col sm={10}>
                    <Input
                      type="number"
                      id="priceMarginInput"
                      value={priceMargin}
                      disabled
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="exampleSelect" sm={2}>
                    Product Types
                  </Label>
                  <Col sm={10}>
                    <Select
                      isMulti
                      value={productTypesSelected}
                      options={productTypeOptions}
                      onChange={(selected) => {
                        const newProductTypes = selected?.map((sv) => sv.value)
                        setProductTypes(newProductTypes)
                      }}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="exampleSelect" sm={2}>
                    Status
                  </Label>
                  <Col sm={10}>
                    <Select
                      value={statusOptions.find((so) => so.value === status)}
                      options={statusOptions}
                      onChange={(selected) => {
                        setStatus(selected.value)
                      }}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="exampleSelect" sm={2}>
                    Skip Products
                  </Label>
                  <Col sm={10}>
                    <Select
                      value={skipProducts
                        ?.map((spId) => products.find((p) => p.id === spId))
                        .map((p) => ({ value: p.id, label: p.name }))}
                      isMulti
                      options={productOptions}
                      onChange={(selected) => {
                        const newSkipProducts = selected?.map((sv) => sv.value)
                        setSkipProducts(newSkipProducts)
                      }}
                    />
                  </Col>
                </FormGroup>
                <Button
                  color="primary"
                  className="float-end"
                  onClick={() => {
                    updateArtwork({
                      variables: {
                        id,
                        skipProducts,
                        status,
                        productTypes,
                      },
                    })
                  }}
                >
                  {updatingArtwork && <Spinner size="sm" />} Save
                </Button>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
      <hr />
      <ProductSpecEditor
        testProductWithAdjustment={testProduct}
        productSpecs={productSpecs}
        addNewSpec={({ productDef, imageAdjustment }) => {
          addNewSpec({
            variables: {
              productDef,
              imageAdjustment,
              artworkId: id,
            },
          })
        }}
        updateSpec={({ productDef, imageAdjustment }) => {
          updateSpec({
            variables: {
              productDef,
              imageAdjustment,
              artworkId: id,
            },
          })
        }}
        deleteSpec={({ id: specId }) => {
          deleteSpec({
            variables: {
              artworkId: id,
              id: specId,
            },
          })
        }}
      />
    </div>
  )
}

export default ArtworkEditor
