import { gql } from '@apollo/client'

export const VERIFY_SESSION = gql`
  query VerifySession {
    verify {
      id
      username
      type
    }
  }
`
