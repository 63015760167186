/* eslint-disable react/prop-types */
import { useQuery } from '@apollo/client'
import React, { useState } from 'react'
import { FaPlus } from 'react-icons/fa'
import { Alert, Button, Card, CardBody, CardTitle, Row } from 'reactstrap'
import AppSplash from '../../components/AppSplash'
import ProductSpecItem from './ProductSpecItem'
import { GET_PRODUCTS_FOR_SPEC } from './actions'

export default function ProductSpecEditor({
  productSpecs,
  testProductWithAdjustment,
  addNewSpec,
  updateSpec,
  deleteSpec,
  packageId,
  officialMerchId,
}) {
  const [isNewAdjustmentOpen, setIsNewAdjustmentOpen] = useState(false)
  const {
    loading: loadingProducts,
    error: errorProducts,
    data: productsData,
  } = useQuery(GET_PRODUCTS_FOR_SPEC, {
    variables: {
      packageId,
      officialMerchId,
    },
    fetchPolicy: 'network-only',
  })

  if (loadingProducts) {
    return <AppSplash />
  }

  if (errorProducts) {
    return (
      <Alert color="danger">
        Error loading products. {errorProducts.message}
      </Alert>
    )
  }

  const defaultAdjustments = {
    imageAdjustment: {
      scale: null,
      y: null,
      x: null,
      angle: null,
    },
  }

  const { productsForSpec } = productsData || {}

  const productsToAddNew = productsForSpec?.filter((p) => {
    return !productSpecs?.find((ps) => {
      return ps.productDef.id === p.id
    })
  })

  return (
    <Card className="p-2">
      <CardBody className="p-0">
        <CardTitle>
          Product Adjustments{' '}
          {productsToAddNew?.length > 0 && (
            <Button
              className="float-end mr-4"
              onClick={() => setIsNewAdjustmentOpen(!isNewAdjustmentOpen)}
            >
              <FaPlus className="pb-1" /> New Adjustment
            </Button>
          )}
        </CardTitle>

        <CardBody>
          <Row>
            {isNewAdjustmentOpen && !loadingProducts && productsForSpec && (
              <ProductSpecItem
                testProductWithAdjustment={testProductWithAdjustment}
                productsToAddNew={productsToAddNew}
                isCreating
                addNewSpec={addNewSpec}
                adjustments={defaultAdjustments}
              />
            )}

            {productSpecs?.map((pSpec) => (
              <ProductSpecItem
                key={`${pSpec.productDef.id}-${pSpec.id}`}
                specId={pSpec.id}
                addNewSpec={addNewSpec}
                testProductWithAdjustment={testProductWithAdjustment}
                updateSpec={updateSpec}
                deleteSpec={deleteSpec}
                productDef={pSpec.productDef.id}
                adjustments={pSpec.adjustments}
              />
            ))}
          </Row>
        </CardBody>
      </CardBody>
    </Card>
  )
}
