import { gql } from '@apollo/client'

export const GET_UPDATER_ACTIONS = gql`
  query GetUpdaterActions {
    updaterActions {
      id
      userName
      collectionInitials
      collectionToken
      productName
      createdBy {
        id
        username
      }
      status
      recreateStatus
      createdAt
      printifyProductsRemoved
      wooProductsRemoved
      type
    }
  }
`

export const RECREATE_UPDATER_ACTION = gql`
  mutation RecreateUpdaterAction($id: String, $filters: UpdaterFiltersInput) {
    recreateUpdaterAction(id: $id, filters: $filters)
  }
`

export const DELETE_UPDATER_ACTION = gql`
  mutation DeleteUpdaterAction($filters: UpdaterFiltersInput) {
    deleteUpdaterAction(filters: $filters)
  }
`
