import React, { useRef, useState } from 'react'
import { MdColorLens } from 'react-icons/md'
import { Button, Input, InputGroup, InputGroupText } from 'reactstrap'
import { SketchPicker } from 'react-color'
import styled from 'styled-components'
import { useOutsideListener } from '../../utils/hooks'

const ColorFeedback = styled(InputGroupText)`
  background: ${(props) => props.color ?? 'transparent'};
`

const Wrapper = styled.span`
  z-index: 1;
  position: absolute;
`

const ColorPicker = ({ onChange, defaultColor }) => {
  const [color, setColor] = useState(defaultColor ?? null)
  const [showing, setShowing] = useState(false)

  const handleChange = (newColor) => {
    setColor(newColor)
    if (onChange) {
      onChange(newColor)
    }
  }

  const pickerRef = useRef()

  useOutsideListener(pickerRef, () => {
    setShowing(false)
  })

  return (
    <span>
      <div className="d-flex">
        <InputGroup>
          <ColorFeedback color={color} />
          <Input
            value={color}
            onChange={(e) => {
              handleChange(e.target.value)
            }}
          />
          <Button
            outline
            primary
            onClick={() => {
              setShowing(true)
            }}
          >
            <MdColorLens />
            {showing && (
              <Wrapper
                ref={pickerRef}
                autoFocus={true}
                className="position-absolute"
                tabIndex={1}
              >
                <SketchPicker
                  color={color ?? ''}
                  onChange={(selected) => handleChange(selected.hex)}
                />
              </Wrapper>
            )}
          </Button>
        </InputGroup>
      </div>
    </span>
  )
}

export default ColorPicker
