/* eslint-disable react/prop-types */
import React from "react";
import { Spinner } from "reactstrap";
import { useQuery } from "@apollo/client";
import { GET_PRODUCT_IMAGE } from "./actions";
import { Redirect } from 'react-router-dom'
import Image from '../../components/Image'

const ProductImage = ({ blueprintId, imgSize = "60em", onImageLoaded }) => {
  const { loading, error, data } = useQuery(GET_PRODUCT_IMAGE, {
    variables: { blueprintId },
    onCompleted: ({ printifyProductInfo }) => {
      const { images } = printifyProductInfo
      const selectedImage = images ? images[0] : ''
      if (onImageLoaded) {
        onImageLoaded(selectedImage)
      }
    },
  });

  if (loading) {
    return <Spinner color="secondary" type="grow" />;
  }

  if (error) {
    if (error.message ===  "Unauthorized") {
      return <Redirect to="/login" />;
    }
    return null;
  }

  const { images } = data.printifyProductInfo;

  return (
    <Image
      src={images ? images[0] : ''}
      imgSize={imgSize}
    />
  );
};

export default ProductImage;
