/* eslint-disable react/prop-types */
import { useMutation, useQuery } from '@apollo/client'
import React, { useState } from 'react'
import AppSplash from '../../components/AppSplash'
import { useParams } from 'react-router-dom'
import { GET_ORDER_BY_ID, SAVE_ORDER } from './actions'
import { Row, Col, Badge, Container, Table, Button, Spinner } from 'reactstrap'
import { OrderStatusColor } from '../../utils/status'
import OrderProductEditor from './OrderProductEditor'
import moment from 'moment'
import { toast } from 'react-toastify'
import { omitDeep } from '../../utils'

export default function OrderEditor(props) {
  const { id } = useParams()
  const orderId = props.orderId ?? id

  const [products, setProducts] = useState()
  
  const { data: orderData, loading } = useQuery(GET_ORDER_BY_ID, {
    variables: { id: orderId },
    onCompleted: ({ orderById }) => {
      setProducts(orderById?.products?.map((p) => omitDeep(p, '__typename')))
    },
  })

  const [saveOrder, { loading: isSavingOrder }] = useMutation(SAVE_ORDER, {
    onCompleted: () => {
      toast.success('Order saved!')
    },
  })


  const {
    wooOrderId,
    allProductsMatched,
    vendor,
    status,
    data: originalData,
  } = orderData?.orderById ?? {}

  if (loading) {
    return <AppSplash />
  }

  const updateProductOnList = ({ index, productMatched }) => {
    if (products) {
      const newProducts = [...products]
      if (newProducts && productMatched) {
        newProducts[index] = {
          ...newProducts[index],
          productMatched: { ...productMatched },
        }
        setProducts(newProducts)
      }
    }
  }

  return (
    <Container fluid className="mt-3 mb-3">
      <Row>
        <Col>#{wooOrderId}</Col>
        <Col>
          Products Matched{' '}
          <Badge color={allProductsMatched ? 'success' : 'danger'}>
            {allProductsMatched ? 'yes' : 'no'}
          </Badge>
        </Col>
        <Col md={2}></Col>
      </Row>
      <hr />
      <h4>Details</h4>
      <Table size="sm">
        <thead>
          <th style={{ width: '20%' }}></th>
          <th></th>
        </thead>
        <tbody>
          <tr>
            <td>Order ID</td>
            <td>{originalData?.id}</td>
          </tr>
          <tr>
            <td>Date(GMT)</td>
            <td>{moment.utc(originalData?.date_created_gmt).format('YYYY-MM-DD HH:mm')}</td>
          </tr>
          <tr>
            <td>Customer ID</td>
            <td>{originalData?.customer_id}</td>
          </tr>
          <tr>
            <td>Vendor</td>
            <td>
              {vendor?.userId} - {vendor?.userName}
            </td>
          </tr>
          <tr>
            <td>Products Matched</td>
            <td>
              <Badge color={allProductsMatched ? 'success' : 'danger'}>
                {allProductsMatched ? 'yes' : 'no'}
              </Badge>
            </td>
          </tr>
          <tr>
            <td>Status</td>
            <td>
              <Badge color={OrderStatusColor[status]}>{status}</Badge>
            </td>
          </tr>
          <tr>
            <td>Total Discount</td>
            <td>$ {originalData?.discount_total}</td>
          </tr>
          <tr>
            <td>Total</td>
            <td>$ {originalData?.total}</td>
          </tr>
        </tbody>
      </Table>
      <h4>Products</h4>
      <Table hover className="text-center">
        <thead>
          <tr>
            <th>Name</th>
            <th>Prod.Matched</th>
            <th>VendorID</th>
            <th style={{ minWidth: '6em' }}>Total Tax</th>
            <th>Subtotal</th>
            <th>Total</th>
            <th>Qty</th>
            <th>Cost</th>
            <th>Vendor Comm%</th>
            <th>Vendor Comm$</th>
            <th>Official</th>
          </tr>
        </thead>
        <tbody>
          {products?.map((p, index) => (
            <OrderProductEditor
              key={`${p.id}-${orderId}`}
              orderId={orderId}
              onProductChange={({ productMatched }) => {
                updateProductOnList({ index, productMatched })
              }}
              {...p}
            />
          ))}
        </tbody>
      </Table>
      <div className="text-end">
        <Button
          onClick={() => {
            saveOrder({
              variables: {
                id: orderId,
                products: products.map((p) => ({
                  id: p?.id,
                  productMatched: {
                    id: p?.productMatched?.id,
                    name: p?.productMatched?.name,
                  },
                })),
              },
            })
          }}
          color="primary"
        >
          Save {isSavingOrder && <Spinner size="sm" />}
        </Button>
      </div>
    </Container>
  )
}
