/* eslint-disable react/prop-types */
import React from 'react'
import {
  Badge,
  Row,
  Col,
  UncontrolledTooltip,
  Card,
  CardBody,
  Button,
} from 'reactstrap'
import { Link } from 'react-router-dom'
import Image from '../../components/Image'
import { GiSevenPointedStar } from 'react-icons/gi'
import { AiFillEdit } from 'react-icons/ai'
import { numberToComma } from '../../utils'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'
import { CardHeader } from 'reactstrap'

const ProductWrapper = styled.div`
  padding: 0.4em;
  display: flex;
  flex-direction: column;
  border-width: 1px;
`

const ProductCard = styled(Card)`
  ${({ active }) => (!active ? 'background: rgba(234, 220, 188, 0.4);' : '')}
  ${({ archived }) => (archived ? 'background: rgba(234, 188, 188, 0.4);' : '')}
  &:hover {
    ${({ active, archived }) =>
      active && !archived ? 'background: rgba(216, 216, 216, 0.4);' : ''}

    ${({ active }) => (!active ? 'background: rgba(234, 220, 188, 0.5);' : '')}

    ${({ archived }) =>
      archived ? 'background: rgba(234, 188, 188, 0.5);' : ''}
  }
`

const Product = ({
  name,
  image,
  id,
  printifyInfo = {},
  validation,
  isActive,
  isArchived,
  artworkEnabled,
  officialMerchEnabled,
  platformType,
}) => {
  const price = printifyInfo?.price ?? false

  return (
    <ProductWrapper className="col-md-6 col-xs-12">
      <ProductCard
        active={isActive ? 'true' : ''}
        archived={isArchived ? 'true' : ''}
      >
        {officialMerchEnabled && (
            <CardHeader>
              <span className="text-primary">
                <div>
                  <GiSevenPointedStar
                    size="1em"
                    style={{ verticalAlign: 'text-top' }}
                  />{' '}
                  Official
                </div>
              </span>
            </CardHeader>
          )}
        <CardBody>
          <Row>
            <Col xs={4} md={2}>
              <Image src={image} />
            </Col>
            <Col>
              <Row>
                <Col xs={9} md={10}>
                  {name}{' '}
                  {validation && !validation.isValid && (
                    <span>
                      <UncontrolledTooltip target={`validation-error-${id}`}>
                        {validation.message}
                        {validation.invalidVariants &&
                          validation.invalidVariants.length > 0 && (
                            <div>
                              Invalid Variants: [{validation.invalidVariants}]
                            </div>
                          )}
                      </UncontrolledTooltip>
                      <Badge id={`validation-error-${id}`} color="warning">
                        validation errors
                      </Badge>
                    </span>
                  )}
                  {price && (
                    <div>
                      <div>
                        Price: <strong>${numberToComma(price)}</strong>
                      </div>{' '}
                      <div>
                        Cost:{' '}
                        {printifyInfo?.maddiesCost ? (
                          <strong>
                            ${printifyInfo?.maddiesCost || '0.00'}
                          </strong>
                        ) : (
                          <>
                            <ReactTooltip
                              id={`${id}-cost-alert`}
                              effect="solid"
                              className="text-center text-warning"
                            >
                              Commission system need this value to be setup.
                              <div>
                                Please do that before calculate vendor&apos;s
                                commission.
                              </div>
                            </ReactTooltip>
                            <Badge
                              data-tip=""
                              data-for={`${id}-cost-alert`}
                              color="danger"
                            >
                              $0.00
                            </Badge>
                          </>
                        )}
                      </div>{' '}
                    </div>
                  )}
                </Col>
                <Col className="text-end">
                  <Link to={`/products/${id}`}>
                    <Button outline>
                      <AiFillEdit size="1.1em" />
                    </Button>
                  </Link>
                </Col>
              </Row>
              <Row style={{ marginTop: '1em' }}>
                <Col xs={6} md={3} className="opacity-75">
                  <Badge
                    className="w-100"
                    color={platformType === 'madswag' ? 'dark' : 'success'}
                  >
                    {platformType}
                  </Badge>
                </Col>
                {!isActive && (
                  <Col xs={6} md={3}>
                    <Badge className="w-100 opacity-75" color="danger">
                      not active
                    </Badge>
                  </Col>
                )}
                {isArchived && (
                  <Col xs={6} md={3}>
                    <Badge className="w-100 opacity-75" color="danger">
                      archived
                    </Badge>
                  </Col>
                )}
                {artworkEnabled && (
                  <Col xs={6} md={3}>
                    <Badge className="w-100 opacity-75" color="secondary">
                      artwork
                    </Badge>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </CardBody>
      </ProductCard>
    </ProductWrapper>
  )
}

export default Product
