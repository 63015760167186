import { gql } from '@apollo/client'

export const GET_NFT_USERS = gql`
  query GetNFTUsers($search: String) {
    nftUsers(search: $search) {
      id
      userName
      userId
    }
  }
`