/* eslint-disable react/prop-types */
import React from 'react'
import { Button, ModalHeader, ModalBody, Modal, ModalFooter } from 'reactstrap'

const Confirm = ({
  isOpen,
  msgHeader,
  message,
  toggle,
  onYesClicked,
  onNoClicked,
  size,
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} size={size}>
      {msgHeader && <ModalHeader>{msgHeader}</ModalHeader>}
      <ModalBody className="text-dark">
        <span style={{fontSize: '1.1em'}}>{message}</span>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => {
            if (onYesClicked) {
              onYesClicked()
            }
            if (toggle) {
              toggle()
            }
          }}
        >
          Yes
        </Button>{' '}
        <Button
          color="secondary"
          onClick={() => {
            if (onNoClicked) {
              onNoClicked()
            }
            if (toggle) {
              toggle()
            }
          }}
        >
          No
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default Confirm
