import React from 'react'
import { Spinner, Container, Col, Row } from 'reactstrap'
import logo from '../../assets/logo.svg'
const AppSplash = () => {
  return (
    <Container className="mt-5">
      <Row>
        <Col md={{ size: 5, offset: 4 }} className="text-center">
          <img width="200em" src={logo} alt=""/>
          <hr/>
          <Spinner style={{ width: '3rem', height: '3rem' }}/>
        </Col>
      </Row>
    </Container>
  )
}

export default AppSplash
