import styled from 'styled-components'

const ColorCircle = styled.div`
  height: 1em;
  width: 1em;
  background: ${(props) => props.color ?? 'transparent'};
  display: inline-block;

  -webkit-box-shadow: 0px 0px 3px 0px #1f1f1f;
  box-shadow: 0px 0px 3px 0px #1f1f1f;
  border-radius: 50%;
`

export default ColorCircle
