import React from 'react'
import { Badge, Card, CardHeader } from 'reactstrap'
import styled from 'styled-components'
import ReactTooltip from 'react-tooltip'

import Image from '../../components/Image'
import { statusColors } from '../../utils/status'

const NFTCard = styled(Card)`
  transition: transform 0.5s;

  ${({opacity}) => opacity ? 'opacity: 0.4;' : ''}

  ${({ highlight }) =>
    highlight
      ? `
    box-shadow: 0px 0px 15px 7px rgba(125, 226, 68, 1);
    z-index: 1;
  `
      : ''}

  &:hover {
    cursor: pointer;
    box-shadow: 0px 0px 15px ${({ highlight }) => (highlight ? '7px' : '-5px')}
      ${({ highlight }) =>
        highlight ? 'rgba(125, 226, 68, 1)' : 'rgba(0, 0, 0, 1)'};
    transform: scale(1.02);
    z-index: 2;
  }
`

const StatusBadge = styled(Badge)`
  border-radius: 0 0 3px 3px;
`

const TokenBadge = styled(Badge)`
  border-radius: 0 0 4px 0;
  position: absolute;
`

const CollectionBadge = styled(Badge)`
  border-radius: 0;
  opacity: 0.7;
  white-space: pre-wrap;
`

export default function NFT({
  onClick,
  id,
  link,
  nftCollection,
  status,
  statusMsg,
  token,
  highlight,
  isNotInTokenFilter,
}) {
  return (
    <NFTCard
      onClick={onClick}
      style={{ maxWidth: '9em', minWidth: '9em' }}
      highlight={highlight ? 'true' : ''}
      opacity={isNotInTokenFilter ? 'true' : ''}
    >
      <ReactTooltip place="top" effect="solid" id={`tooltip-nft-token-${id}`}>
        Token: {token}
      </ReactTooltip>
      <ReactTooltip
        place="top"
        effect="solid"
        id={`tooltip-nft-status-msg-${id}`}
      >
        {statusMsg}
      </ReactTooltip>
      <CardHeader className="p-0">
        <Image border={false} src={link} lowRes />
      </CardHeader>
      <TokenBadge data-for={`tooltip-nft-token-${id}`} data-tip="">
        {token}
      </TokenBadge>
      <CollectionBadge color="primary">
        {nftCollection?.identifier}
      </CollectionBadge>
      <StatusBadge
        data-for={`tooltip-nft-status-msg-${id}`}
        data-tip=""
        color={statusColors[status]}
      >
        {status}
      </StatusBadge>
    </NFTCard>
  )
}
