import { gql } from '@apollo/client'

export const UPDATE_USERS_INFO_FROM_WP = gql`
  mutation UpdateUsersInfoFromWP {
    updateUsersInfoFromWP
  }
`
export const GET_COMMISSION_REPORTS_TOTAL = gql`
  query GetCommissionsTotal($vendor: String, $period: DatePeriodInput) {
    commissionReportsTotal(vendor: $vendor, period: $period) {
      count
      commissionsTotal
      paidTotal
      nftoCommission
      officialCommission
      owedTotal
    }
  }
`

export const EXPORT_COMMISSIONS = gql`
  query ExportCommissions($vendor: String, $period: DatePeriodInput) {
    exportCommissions(vendor: $vendor, period: $period)
  }
`

export const GET_EXPORTED_COMMISSION_LIST = gql`
  query GetExportedCommissionList {
    exportedCommissionList {
      id
      status
      progress
      fileUrl
      query {
        vendor
        period {
          startDate
          endDate
        }
      }
      createdAt
    }
  }
`

export const GET_COMMISSION_REPORTS = gql`
  query GetCommissionReports(
    $vendor: String
    $limit: Int
    $page: Int
    $period: DatePeriodInput
  ) {
    commissionReports(
      vendor: $vendor
      limit: $limit
      page: $page
      period: $period
    ) {
      id
      vendor {
        id
        userId
        userName
        email
        wallets {
          id
          chain
        }
        paypal
      }
      officialCollection {
        id
        identifier
        initials
      }
      hasErrors
      hasWarns
      commission
      paidCommission
      count
    }
  }
`

export const GET_COMMISSION_STATUS = gql`
  query GetProcessStatus($name: String) {
    processStatus(name: $name) {
      id
      name
      progress
      prepared
      status
      data {
        startDate
        endDate
      }
      createdAt
      updatedAt
    }
  }
`

export const GET_COMMISSIONS_TOTAL = gql`
  query GetCommissionsTotal(
    $vendor: String
    $period: DatePeriodInput
    $showPaid: Boolean
    $showZeroes: Boolean
  ) {
    commissionsTotal(
      vendor: $vendor
      period: $period
      showPaid: $showPaid
      showZeroes: $showZeroes
    )
  }
`

export const GET_COMMISSIONS = gql`
  query GetCommissions(
    $vendor: String
    $limit: Int
    $page: Int
    $period: DatePeriodInput
    $showPaid: Boolean
    $showZeroes: Boolean
  ) {
    commissions(
      vendor: $vendor
      limit: $limit
      page: $page
      period: $period
      showPaid: $showPaid
      showZeroes: $showZeroes
    ) {
      id
      error
      warn
      info
      isRoyalties
      commission
      cost
      shouldIgnore
      vendor {
        id
        userId
        userName
      }
      order {
        id
        wooOrderId
        status
      }
      date_created_gmt
      payment {
        id
        code
      }
      product {
        id
        data {
          name
          parent_name
          price
          subtotal
          total
          quantity
          official_merch
        }
      }
    }
  }
`

export const CALCULATE_COMMISSION_BY_VENDOR = gql`
  mutation CalculateCommissionByVendor(
    $startDate: Float
    $endDate: Float
    $forceRecalculation: Boolean
  ) {
    calculateCommissions(
      startDate: $startDate
      endDate: $endDate
      forceRecalculation: $forceRecalculation
    )
  }
`

export const PAY_COMMISSIONS = gql`
  mutation PayCommissions(
    $vendor: String
    $period: DatePeriodInput
    $selectedCommissions: [String]
    $paymentInfo: CommissionPaymentInput
  ) {
    payCommissions(
      vendor: $vendor
      period: $period
      selectedCommissions: $selectedCommissions
      paymentInfo: $paymentInfo
    )
  }
`
