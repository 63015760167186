import React, { useState } from 'react'
import {
  Row,
  Col,
  Alert,
  Input,
  InputGroup,
  InputGroupText,
  Button,
  Modal,
  Container,
  CardGroup,
  Spinner,
  ButtonGroup,
} from 'reactstrap'
import { Redirect } from 'react-router-dom'
import { FaSearch } from 'react-icons/fa'
import { useQuery } from '@apollo/client'
import ProductItem from './ProductItem'
import ProductCreator from './editor'
import AppSplash from '../../components/AppSplash'
import { GET_PRODUCTS } from './actions'

const ProductList = () => {
  const [productNameFilter, setProductNameFilter] = useState('')
  const [showInactive, setShowInactive] = useState(false)
  const [filterMadswag, setFilterMadswag] = useState(false)
  const [filterPrintify, setFilterPrintify] = useState(false)
  const [isCreateProductModalOpen, setIsCreateModalOpen] = useState(false)
  const { loading, error, data } = useQuery(GET_PRODUCTS, {
    variables: { showInactive },
    pollInterval: 10 * 1000, //10s,
  })

  if (error) {
    if (error.message === 'Unauthorized') {
      return <Redirect to="/login" />
    }
    return (
      <div className="text-center m-4">
        <Alert color="danger">An error has ocurred :(</Alert>
      </div>
    )
  }

  let productsList = data ? data.products : []

  productsList = productNameFilter
    ? productsList.filter((p) =>
        p.name.toLowerCase().includes(productNameFilter.toLowerCase())
      )
    : productsList

  productsList = filterMadswag
    ? productsList.filter((p) => p.platformType === 'madswag')
    : productsList

  productsList = filterPrintify
    ? productsList.filter((p) => p.platformType === 'printify')
    : productsList

  const toggleAddProductModal = () =>
    setIsCreateModalOpen(!isCreateProductModalOpen)

  return (
    <Container fluid>
      <Row className="pt-3 pb-3 bg-white border-bottom mb-3">
        <Col xs={12} md={2}>
          <Button block onClick={toggleAddProductModal}>
            Add Product
          </Button>
          <Modal
            style={{ minWidth: '90%' }}
            size="lg"
            isOpen={isCreateProductModalOpen}
            toggle={toggleAddProductModal}
          >
            <ProductCreator />
          </Modal>
        </Col>
        <Col xs={12} md={3}>
          <InputGroup>
            <InputGroupText>
              <FaSearch />
            </InputGroupText>
            <Input
              placeholder="search product"
              value={productNameFilter}
              onChange={(e) => {
                setProductNameFilter(e.target.value)
              }}
            />
          </InputGroup>
        </Col>
        <Col xs={12} md={4}>
          <Button
            value={showInactive}
            onClick={() => {
              setShowInactive(!showInactive)
            }}
            color={showInactive ? 'success' : 'secondary'}
          >
            {showInactive ? 'Hide inactive' : 'Show inactive'}
          </Button>{' '}
          <ButtonGroup>
            <Button
              value={filterMadswag}
              onClick={() => {
                setFilterPrintify(!filterMadswag ? false : filterPrintify)
                setFilterMadswag(!filterMadswag)
              }}
              color={filterMadswag ? 'success' : 'secondary'}
            >
              Madswag
            </Button>
            <Button
              value={filterPrintify}
              onClick={() => {
                setFilterMadswag(!filterPrintify ? false : filterMadswag)
                setFilterPrintify(!filterPrintify)
              }}
              color={filterPrintify ? 'success' : 'secondary'}
            >
              Printify
            </Button>
          </ButtonGroup>
        </Col>
        <Col md={1}>{loading && <Spinner size="sm" />}</Col>
        <Col className="text-end p-2">Total: {productsList.length}</Col>
      </Row>
      <Row>
        <Col>
          {loading && !productsList?.length && <AppSplash />}
          {!loading && (
            <CardGroup>
              {productsList?.map((p) => (
                <ProductItem
                  key={p.id}
                  id={p.id}
                  name={p.name}
                  image={p.image}
                  adjustments={p.adjustments}
                  excludeCollections={p.excludeCollections}
                  includeCollections={p.includeCollections}
                  printifyInfo={p.printifyInfo}
                  platformType={p.platformType}
                  validation={p.validation}
                  isActive={p.isActive}
                  isArchived={p.isArchived}
                  artworkEnabled={p.artworkEnabled}
                  officialMerchEnabled={p.officialMerchEnabled}
                  type={p.type}
                />
              ))}
            </CardGroup>
          )}
        </Col>
      </Row>
    </Container>
  )
}

export default ProductList
