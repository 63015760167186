import { useEffect, useRef } from 'react'

export const useFocus = () => {
  const ref = useRef(null)
  const setFocus = () => {
    ref.current && ref.current.focus()
  }

  return [ref, setFocus]
}

/**
 * Hook that alerts clicks outside of the passed ref
 */
export function useOutsideListener(ref, onOutsideClick) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        if (onOutsideClick) {
          onOutsideClick()
        }
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])
}
