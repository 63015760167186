import { gql } from "@apollo/client";

export const GET_COMMISSION_PAYMENTS = gql`
  query GetCommissionPayments(
    $vendor: String
    $limit: Int
    $page: Int
    $period: DatePeriodInput
  ) {
    commissionPayments(
      vendor: $vendor
      limit: $limit
      page: $page
      period: $period
    ) {
      id
      summary
      totalPaid
      user {
        id
        username
      }
      vendor {
        id
        userName
        userId
      }
      transactionDetails
      fromAccount
      toAccount
      platform
      period {
        startDate
        endDate
      }
      createdAt
      code
    }
  }
`

export const GET_COMMISSION_PAYMENTS_TOTAL = gql`
  query GetCommissionPaymentsTotal(
    $vendor: String
    $period: DatePeriodInput
  ) {
    commissionPaymentsTotal(
      vendor: $vendor
      period: $period
    )
  }
`