import { gql } from '@apollo/client'

export const GET_ARTISTS_REQUESTS = gql`
  query GetArtistsRequests($page: Int, $limit: Int, $status: [String]) {
    artworks(page: $page, limit: $limit, status: $status) {
      requests {
        id
        user {
          userId
          userName
        }
        imageLink
        createdAt
        status
        productTypes
      }
      total
    }
  }
`

export const GET_ARTWORK = gql`
  query GetArtworkById($id: String) {
    artwork(id: $id) {
      id
      name
      user {
        userId
        userName
      }
      productSpecs {
        id
        productDef
        adjustments {
          imageAdjustment {
            scale
            x
            y
            angle
          }
        }
      }
      price
      priceMargin
      imageLink
      createdAt
      status
      productTypes
      skipProducts
    }
  }
`

export const ARTWORK_ADD_NEW_PRODUCT_SPEC = gql`
  mutation ArtwokAddNewProductSpec(
    $productDef: String!
    $artworkId: String!
    $imageAdjustment: ImageAdjustmentInput
  ) {
    artworkAddNewProductSpec(
      productDef: $productDef
      artworkId: $artworkId
      imageAdjustment: $imageAdjustment
    ) {
      id
      productSpecs {
        id
        productDef
        adjustments {
          imageAdjustment {
            x
            y
            scale
            angle
          }
        }
      }
    }
  }
`


export const ARTWORK_UPDATE_PRODUCT_SPEC = gql`
  mutation ArtworkUpdateProductSpec(
    $productDef: String!
    $artworkId: String!
    $imageAdjustment: ImageAdjustmentInput
  ) {
    artworkUpdateProductSpec(
      productDef: $productDef
      artworkId: $artworkId
      imageAdjustment: $imageAdjustment
    ) {
      id
      productSpecs {
        id
        productDef
        adjustments {
          imageAdjustment {
            scale
            x
            y
            angle
          }
        }
      }
    }
  }
`

export const ARTWORK_DELETE_PRODUCT_SPEC = gql`
  mutation ArtworkDeleteProductSpec($id: String!, $artworkId: String!) {
    artworkDeleteProductSpec(id: $id, artworkId: $artworkId)
  }
`

export const EDIT_ARTWORK = gql`
  mutation EditArtwork($id: String!, $status: String, $skipProducts: [String], $productTypes: [String]) {
    editArtwork(id: $id, status: $status, skipProducts: $skipProducts, productTypes: $productTypes) {
      id
      status
      skipProducts
      productTypes
    }
  }
`