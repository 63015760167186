import React from 'react'
import 'react-toastify/dist/ReactToastify.css'
import LoginPage from './containers/User/login'
import HomePage from './containers/Home'
import { Switch, Route } from 'react-router-dom'
import 'react-dropdown-tree-select/dist/styles.css'
import "react-datepicker/dist/react-datepicker.css";
import './app.css'

const App = () => {
  return (
    <Switch>
      <Route path="/login" exact>
        <LoginPage />
      </Route>
      <Route path="/">
        <HomePage />
      </Route>
    </Switch>
  )
}

export default App
