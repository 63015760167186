import _ from 'lodash'

export const numberToComma = (num) => {
  try {
    if (!num) {
      return '0.00'
    }
    //function to add commas to textboxes
    const numToConvert = (num + '').replace(/\b0+/g, '')

    if (numToConvert.length === 2) {
      return `0.${numToConvert}`
    } else if (numToConvert.length === 1) {
      return `0.0${numToConvert}`
    } else if (numToConvert.length > 2) {
      const dot = '.'
      var position = numToConvert.length - 2
      var output = [
        numToConvert.slice(0, position),
        dot,
        numToConvert.slice(position),
      ].join('')
      return output
    }
  } catch (e) {
    // do nothing
  }

  return '0.00'
}

export const capitalize = (s) => {
  if (s && s.length > 0) {
    return `${s[0].toUpperCase()}${s.length > 1 ? s.slice(1) : ''}`
  }
  return s
}

const cloudFrontByBucket = {
  'rowie-static': 'https://d3jo872tli6thm.cloudfront.net',
  'maddies-static': 'https://d3ezgod8cyqjne.cloudfront.net',
}

export const getLowResolutionImage = (link, sizeOptions, fit = 'cover') => {
  const { width, height } = sizeOptions ?? { width: 200, height: 200 }
  const matched = /https?:\/\/([^.]*).*amazonaws.com\/(.*)/.exec(link)

  if (!matched) {
    return link
  }

  const bucket = matched[1]
  const path = matched[2]

  let data = {
    bucket,
    key: path,
    edits: {
      resize: {
        width,
        height,
        fit,
      },
    },
  }

  const selectedCloudFrontURL = cloudFrontByBucket[bucket]
  if (!selectedCloudFrontURL) {
    return link
  }
  data = btoa(JSON.stringify(data))
  return `${selectedCloudFrontURL}/${data}`
}

export const generateSlug = (string = '') => {
  const parsedString = string.trim().replace(/ +/i, ' ')

  const words = parsedString.split(' ')

  return words.join('-').toLowerCase()
}

export const generateInitials = (string = '') => {
  if (!string.trim().includes(' ')) {
    return string.trim().slice(0, 4).toUpperCase()
  }

  const parsedString = string.trim().replace(/ +/i, ' ')

  const words = parsedString.split(' ')

  return words
    .map((w) => w[0])
    .join('')
    .toUpperCase()
}

export const generateRowieLink = (slug) => {
  return `https://rowie-static.s3.us-east-2.amazonaws.com/nfts/${slug}/1.png`
}

export const omitDeep = (objArg, targetKey, first = true) => {
  if (objArg == null || !_.isObject(objArg)) {
    return objArg
  }
  const obj = first ? _.cloneDeep(objArg) : objArg

  _.forIn(obj, function (value, key) {
    if (key === targetKey) {
      delete obj[key]
    } else if (_.isObject(value)) {
      omitDeep(value, targetKey, false)
    } else if (_.isArray(value)) {
      value.forEach((el) => omitDeep(el, targetKey, false))
    }
  })
  return obj
}
