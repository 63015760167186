export const MERCH = 'merch'
export const APPAREL = 'apparel'
export const WALL_ART = 'wall_art'

export const labelMap = {
  [MERCH]: 'Merch',
  [WALL_ART]: 'Wall Art',
  [APPAREL]: 'Apparel',
}

export const typeList = Object.keys(labelMap)