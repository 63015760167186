import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { DELETE_COLLECTION_BY_ID, GET_COLLECTIONS } from './actions'
import { Button } from 'reactstrap'
import Confirm from '../../components/Confirm'

import { RiDeleteBinLine } from 'react-icons/ri'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import { Spinner } from 'reactstrap'

const DeleteCollection = ({ collectionId }) => {
  const history = useHistory()

  const [confirmDeletionIsOpen, setConfirmDeletionIsOpen] = useState(false)

  const toggle = () => setConfirmDeletionIsOpen((v) => !v)

  const [deleteCollection, { loading }] = useMutation(DELETE_COLLECTION_BY_ID, {
    onCompleted: () => {
      toast.success(
        `Collection removed successfully! Adjustments were removed from collections.`
      )
      history.push('/collections')
    },
    onError: (e) => {
      toast.error(`Error removing collection. ${e.message}`)
    },
    refetchQueries: [{ query: GET_COLLECTIONS }],
  })

  return (
    <div>
      <Button
        color="danger"
        onClick={() => {
          setConfirmDeletionIsOpen(true)
        }}
      >
        <RiDeleteBinLine /> {loading && <Spinner size="sm" />}
      </Button>
      <Confirm
        message={`Do you really want to delete the collection?`}
        onYesClicked={() => {
          deleteCollection({ variables: { id: collectionId } })
        }}
        isOpen={confirmDeletionIsOpen}
        toggle={toggle}
      />
    </div>
  )
}

export default DeleteCollection
