import React, { useEffect, useState } from 'react'
import {
  Alert,
  Col,
  Input,
  ListGroup,
  ListGroupItem,
  Row,
  Spinner,
} from 'reactstrap'
import { useLazyQuery, useQuery } from '@apollo/client'
import { Redirect } from 'react-router-dom'
import { GET_NFT_REQUESTS, GET_TOTAL_NFT_REQUESTS } from './actions'
import NFTRequestItem from './NFTRequestItem'
import { GET_NFT_USERS } from '../NFTUser/actions'
import Select from 'react-select'
import { GET_COLLECTIONS } from '../Collection/actions'
import styled from 'styled-components'
import { Pagination } from '../../components/Pagination'

const RequestListGroupItem = styled(ListGroupItem)`
  &:hover {
    background: #f6f6f6;
  }
`

const NFTRequests = () => {
  const [page, setPage] = useState(1)
  const defaultLimit = 10
  const [limit, setLimit] = useState(defaultLimit)
  const [user, setUser] = useState()
  const [collection, setCollection] = useState()
  const [token, setToken] = useState('')
  const [tokenToDebounce, setTokenToDebounce] = useState('')

  const { loading: loadingUsers, data: { nftUsers } = {} } =
    useQuery(GET_NFT_USERS)

  const [getNFTRequests, { loading, error, data: { nftRequests = [] } = {} }] =
    useLazyQuery(GET_NFT_REQUESTS, {
      pollInterval: 30 * 1000,
      fetchPolicy: 'network-only',
    })

  const [
    getTotalNFTRequests,
    { loading: loadingTotalElements, data: { totalNFTRequests } = {} },
  ] = useLazyQuery(GET_TOTAL_NFT_REQUESTS, {
    pollInterval: 30 * 1000,
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    const filterTimeoutId = setTimeout(() => {
      setToken(tokenToDebounce)
    }, 700)
    return () => clearTimeout(filterTimeoutId)
  }, [tokenToDebounce, setToken])

  useEffect(() => {
    getNFTRequests({
      variables: {
        limit,
        page,
        filters: {
          user: user?.id || '',
          collection: collection?.id,
          token,
        },
      },
    })
  }, [user, collection, getNFTRequests, token, limit, page])

  useEffect(() => {
    getTotalNFTRequests({
      variables: {
        filters: {
          user: user?.id,
          collection: collection?.id,
          token,
        },
      },
    })
  }, [user, collection, getTotalNFTRequests, token])

  const { loading: loadingCollections, data: { collections } = {} } =
    useQuery(GET_COLLECTIONS)

  if (error) {
    if (error.message === 'Unauthorized') {
      return <Redirect to="/login" />
    }
    return (
      <div className="text-center m-4">
        <Alert color="danger">An error has ocurred :( {error?.message}</Alert>
      </div>
    )
  }

  const nftUsersOptions = !loadingUsers
    ? nftUsers.map((u) => ({
        value: u.id,
        label: `${u.userId} - ${u.userName}`,
      }))
    : []

  const collectionOptions = !loadingCollections
    ? collections.map((col) => ({
        value: col.id,
        label: col.identifier,
      }))
    : []

  return (
    <div>
      <Row>
        <Col md={3}>
          <Select
            placeholder="User Filter"
            value={
              user
                ? {
                    value: user.id,
                    label: `${user.userId} - ${user.userName}`,
                  }
                : null
            }
            isClearable
            options={nftUsersOptions}
            onChange={(selected) => {
              setUser(
                selected ? nftUsers.find((u) => u.id === selected.value) : null
              )
            }}
            isLoading={loadingUsers}
          />
        </Col>
        <Col md={3}>
          <Select
            placeholder="Collection Filter"
            value={
              collection
                ? {
                    value: collection.id,
                    label: collection.identifier,
                  }
                : null
            }
            isClearable
            options={collectionOptions}
            onChange={(selected) => {
              setCollection(
                selected
                  ? collections.find((col) => col.id === selected.value)
                  : null
              )
            }}
            isLoading={loadingCollections}
          />
        </Col>
        <Col md={1}>
          <Input
            placeholder="Token"
            value={tokenToDebounce}
            onChange={(e) => setTokenToDebounce(e.target.value)}
          />
        </Col>
        <Col md={1}>{loading && <Spinner size="sm" />}</Col>
        <Col>
          <Pagination
            defaultLimit={defaultLimit}
            loading={loadingTotalElements}
            total={totalNFTRequests}
            onLimitChange={({ limit }) => setLimit(limit)}
            onPageChange={({ page }) => setPage(page)}
          />
        </Col>
      </Row>
      <hr/>
      <ListGroup>
        {nftRequests.map((nftReq) => (
          <RequestListGroupItem key={nftReq.id} style={{ zIndex: 0 }}>
            <NFTRequestItem
              user={nftReq.user}
              nfts={nftReq.nfts}
              createdAt={nftReq.createdAt}
              tokenFilter={token}
            />
          </RequestListGroupItem>
        ))}
      </ListGroup>
    </div>
  )
}

export default NFTRequests
