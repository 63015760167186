/* eslint-disable react/prop-types */
import { useMutation } from '@apollo/client'
import moment from 'moment'
import React, { useState } from 'react'
import { BsCalendar } from 'react-icons/bs'
import { toast } from 'react-toastify'
import { Badge, Button, Col, Row, Spinner } from 'reactstrap'
import Confirm from '../../components/Confirm'
import { statusColors } from '../../utils/status'
import { RECREATE_UPDATER_ACTION } from './actions'

const typeColors = {
  remove: 'danger',
  recreate: 'primary',
}

export default function UpdaterActionItem({
  id,
  status,
  collectionToken,
  productName,
  collectionInitials,
  userName,
  createdAt,
  createdBy,
  wooProductsRemoved,
  printifyProductsRemoved,
  recreateStatus,
  type,
}) {
  const [recreateDialogIsOpen, setRecreateDialogIsOpen] = useState(false)

  const [recreateUpdaterAction, { loading }] = useMutation(
    RECREATE_UPDATER_ACTION,
    {
      onError: (e) => {
        toast.error(e.message)
      },
    }
  )

  const generalMessage = (
    <strong>
      <hr />
      <div>
        <div className="mb-2">
          Filters{' '}
          <span className="text-primary">(Please review carefully)</span>
        </div>
        <div>
          Product:{' '}
          <span className={productName ? 'text-primary' : 'text-danger'}>
            {productName}
            {!productName && <span>All (no filter applied)</span>}
          </span>
        </div>
        <div>
          Collection:{' '}
          <span className={collectionInitials ? 'text-primary' : 'text-danger'}>
            {collectionInitials}
            {!collectionInitials && <span>All (No filter applied)</span>}
          </span>
        </div>
        <div>
          Token:{' '}
          <span className={collectionToken ? 'text-primary' : 'text-danger'}>
            {collectionToken}
            {!collectionToken && <span>All tokens (No filter applied)</span>}
          </span>
        </div>
        <div>
          User:{' '}
          <span className={userName ? 'text-primary' : 'text-danger'}>
            {userName}
            {!userName && <span>All users (No filter applied)</span>}
          </span>
        </div>
      </div>
      <hr />
      <div className="text-danger">
        <h4 className="text-warning">WARNING</h4>
        This might re-create non desired products or might duplicate existing
        ones if they were not removed before.
        <div>
          <i>Old NFTs might be considered on the filter as well.</i>
        </div>
        <div>
          Please be careful and review the filters and ask for approval.
        </div>
      </div>
    </strong>
  )

  const toggleRecreateDialog = () =>
    setRecreateDialogIsOpen(!recreateDialogIsOpen)

  return (
    <Row>
      <Confirm
        isOpen={recreateDialogIsOpen}
        toggle={toggleRecreateDialog}
        msgHeader="Product Recreator"
        message={
          <div>
            <div>
              Do you really want to re-create the NFTs matched for the filter?
            </div>
            <div>{generalMessage}</div>
          </div>
        }
        onYesClicked={() => recreateUpdaterAction({ variables: { id } })}
      />
      <Col md={2}>
        <BsCalendar className="text-primary" />{' '}
        <Badge color="secondary">{createdBy?.username}</Badge>
        <div>{moment(createdAt).fromNow()}</div>
      </Col>
      <Col>
        <div>{userName ? `User: ${userName}` : ''}</div>
        <div>
          {collectionInitials ? `Collection: ${collectionInitials}` : ''}
        </div>
        <div>{productName ? `Product: ${productName}` : ''}</div>
        <div>{collectionToken ? `Token: ${collectionToken}` : ''}</div>
      </Col>
      {type === 'remove' && (
        <Col>
          <div>Woo Products Removed: {wooProductsRemoved}</div>
          <div>Printify Products Removed: {printifyProductsRemoved}</div>
        </Col>
      )}
      <Col md={2} className="text-center">
        <div>
          <Badge color={typeColors[type]}>{type}</Badge>:
          <Badge color={statusColors[status] || 'secondary'}>
            {status === 'inprogress' && <Spinner size="sm" />} {status}
          </Badge>
        </div>
        {recreateStatus && (
          <div>
            <Badge color="info">recreate</Badge>:
            <Badge color={statusColors[recreateStatus]}>{recreateStatus}</Badge>
          </div>
        )}
      </Col>
      <Col className="text-end" md={2}>
        <Button
          color="info"
          outline
          disable={status !== 'done'}
          onClick={toggleRecreateDialog}
        >
          {(loading || recreateStatus === 'inprogress') && (
            <Spinner size="sm" />
          )}{' '}
          re-create
        </Button>
      </Col>
    </Row>
  )
}
