/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { useMutation, gql, useLazyQuery, useQuery } from '@apollo/client'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupText,
  Col,
  Row,
  Spinner,
  Badge,
} from 'reactstrap'
import Select from 'react-select'
import { toast } from 'react-toastify'
import {
  ADD_NEW_COLLECTION,
  EDIT_COLLECTION,
  GET_MORALIS_METADATA,
  GET_COLLECTIONS_FOR_SPEC_COPY,
  SEND_COLLECTION_TO_WP,
} from './actions'
import ReactTooltip from 'react-tooltip'
import SelectUser from '../NFTUser/SelectUser'
import Image from '../../components/Image'
import { connect } from 'react-redux'
import { isCommissionGroup } from '../../utils/permissions'
import Confirm from '../../components/Confirm'
import { generateInitials, generateRowieLink, generateSlug } from '../../utils'

const CollectionEditor = ({
  buttonLabel,
  className,
  editingCollection,
  block,
  userType,
}) => {
  const defaultIdentifier = editingCollection?.identifier || ''
  const defaultInitials = editingCollection?.initials || ''
  const defaultContract = editingCollection?.contract || ''
  const defaultLinkSample = editingCollection?.linkSample || ''

  const defaultIsActive = !!editingCollection?.isActive
  const defaultRemoveBackground = !!editingCollection?.options?.removeBackground

  const defaultAddClearSuffix = !!editingCollection?.options?.addClearSuffix

  const defaultToken = editingCollection?.token || ''
  const defaultSlug = editingCollection?.slug || ''
  const defaultChain = editingCollection?.chain || 'ETH'
  const defaultOwner = editingCollection?.owner
  const defaultOwnerCommission = editingCollection?.ownerCommission || 5

  const [isOpen, setIsOpen] = useState(false)

  const [identifier, setIdentifier] = useState(defaultIdentifier)
  const [initials, setInitials] = useState(defaultInitials)
  const [contract, setContract] = useState(defaultContract)
  const [linkSample, setLinkSample] = useState(defaultLinkSample)
  const [removeBackground, setRemoveBackground] = useState(
    defaultRemoveBackground
  )
  const [isActive, setIsActive] = useState(defaultIsActive)
  const [addClearSuffix, setAddClearSuffix] = useState(defaultAddClearSuffix)
  const [token, setToken] = useState(defaultToken)
  const [slug, setSlug] = useState(defaultSlug)
  const [chain, setChain] = useState(defaultChain)
  const [owner, setOwner] = useState(defaultOwner)
  const [ownerCommission, setOwnerCommission] = useState(defaultOwnerCommission)
  const [isSendToWPConfirmOpen, setIsSendToWPConfirmOpen] = useState(false)

  const [copyFromCollection, setCopyFromCollection] = useState()

  const { data: { collections = [] } = {} } = useQuery(
    GET_COLLECTIONS_FOR_SPEC_COPY
  )

  const [editCollection, { loading: isLoadingEditCollection }] = useMutation(
    EDIT_COLLECTION,
    {
      onCompleted: () => {
        toast.success('Collection edited successfully!')
        setIsOpen(false)
      },
      onError: (e) => {
        toast.error(e.message)
      },
    }
  )

  const [getMoralisMetadata, { loading: loadingMoralisMetadata }] =
    useLazyQuery(GET_MORALIS_METADATA, {
      onCompleted: ({ moralisContractMetadata }) => {
        setIdentifier(moralisContractMetadata.name)
        setInitials(moralisContractMetadata.symbol)
      },
      onError: (e) => {
        toast.error(e.message)
      },
      fetchPolicy: 'network-only',
    })

  const [sendCollectionToWP, { loading: loadingSendCollectionToWP }] =
    useMutation(SEND_COLLECTION_TO_WP, {
      onCompleted: () => {
        toast.success('Collection sent to WP successfully!')
      },
      onError: (e) => {
        toast.error(e.message)
      },
    })

  const [addNewCollection, { loading }] = useMutation(ADD_NEW_COLLECTION, {
    onCompleted: () => {
      toast.success('Collection created successfully!')
      setIsOpen(false)
    },
    onError: (e) => {
      toast.error(e.message)
    },
    update(cache, { data: { addNewCollection: newCol } }) {
      cache.modify({
        fields: {
          collections(existingCollections = []) {
            const newColRef = cache.writeFragment({
              data: newCol,
              fragment: gql`
                fragment NewCollection on Collection {
                  id
                  identifier
                  initials
                  contract
                  linkSample
                  token
                  isActive
                }
              `,
            })
            return [...existingCollections, newColRef]
          },
        },
      })
    },
  })

  const toggleSendToWPConfirm = () =>
    setIsSendToWPConfirmOpen(!isSendToWPConfirmOpen)
  const toggle = () => setIsOpen(!isOpen)

  const collectionOptions = collections?.map((col) => ({
    value: col.id,
    label: col.identifier,
  }))

  const isFormOk = identifier && initials
  const moralisChainList = [
    { value: 'ETH', label: 'Ethereum' },
    { value: 'POLY', label: 'Polygon' },
    { value: 'BSC', label: 'Binance Smart Chain' },
    { value: 'XTZ', label: 'Tezos' },
    { value: 'SOL', label: 'Solana' },
  ]

  const chainOptions = moralisChainList.map((c) => ({
    value: c.value,
    label: `${c.label} (${c.value})`,
  }))

  return (
    <div>
      <Button
        block={block}
        className={className}
        color="primary"
        onClick={toggle}
      >
        {buttonLabel}
      </Button>
      <Modal isOpen={isOpen} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle} className="bg-white sticky-top">
          <div className="d-flex gap-2">
            {linkSample && (
              <span style={{ width: 60 }}>
                <Image src={linkSample} />
              </span>
            )}
            {identifier && <span>{identifier}</span>}
            {!identifier && <span>Collection Editor</span>}
            <div>
              <Badge
                color={
                  editingCollection?.sentToWordpress ? 'success' : 'danger'
                }
              >
                {editingCollection?.sentToWordpress
                  ? 'sent to WP'
                  : 'not sent to WP'}
              </Badge>
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <Confirm
            isOpen={isSendToWPConfirmOpen}
            msgHeader="Do you really want to send the collection to Wordpress?"
            message="This action will create the collection with the contract inside the store make sure that contract and other info are ok."
            toggle={toggleSendToWPConfirm}
            onYesClicked={() =>
              sendCollectionToWP({ variables: { id: editingCollection?.id } })
            }
          />
          <Row>
            <Col sm={12} md={3}>
              <InputGroup style={{ zIndex: 0 }}>
                <InputGroupText style={{ flex: 1 }}>Remove BG</InputGroupText>
                <Button
                  color={removeBackground ? 'success' : 'secondary'}
                  onClick={() => setRemoveBackground(!removeBackground)}
                >
                  {removeBackground ? 'Yes' : 'No'}
                </Button>
              </InputGroup>
            </Col>
            <Col sm={12} md={3}>
              <InputGroup style={{ zIndex: 0 }}>
                <InputGroupText style={{ flex: 1 }}>Is Active</InputGroupText>
                <Button
                  color={isActive ? 'success' : 'secondary'}
                  onClick={() => setIsActive(!isActive)}
                >
                  {isActive ? 'Yes' : 'No'}
                </Button>
              </InputGroup>
            </Col>
            <Col sm={12} md={4}>
              <InputGroup style={{ zIndex: 0 }}>
                <InputGroupText style={{ flex: 1 }}>
                  Add -clear suffix
                </InputGroupText>
                <Button
                  color={addClearSuffix ? 'success' : 'secondary'}
                  onClick={() => setAddClearSuffix(!addClearSuffix)}
                >
                  {addClearSuffix ? 'Yes' : 'No'}
                </Button>
              </InputGroup>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col sm={12} md={6}>
              <FormGroup>
                <Label for="identifierField">Identifier</Label>
                <Input
                  value={identifier}
                  onChange={(e) => setIdentifier(e.target.value)}
                  id="identifierField"
                  placeholder="Collection Name"
                />
              </FormGroup>
            </Col>
            <Col sm={12} md={6}>
              <ReactTooltip id="gen-initials-tooltip" effect="solid">
                Generate initials from identifier.
              </ReactTooltip>
              <FormGroup>
                <Label for="initialsField">Initials</Label>
                <InputGroup>
                  <Input
                    value={initials}
                    onChange={(e) => setInitials(e.target.value)}
                    id="initialsField"
                    placeholder="CN"
                  />
                  <Button
                    data-tip=""
                    data-for="gen-initials-tooltip"
                    outline
                    onClick={() => setInitials(generateInitials(identifier))}
                  >
                    Gen
                  </Button>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col sm={12} md={6}>
              <ReactTooltip id="gen-slug-tooltip" effect="solid">
                Generate slug from identifier.
              </ReactTooltip>
              <FormGroup>
                <Label for="slugField">Slug</Label>
                <InputGroup>
                  <Input
                    value={slug}
                    onChange={(e) => setSlug(e.target.value.trim())}
                    id="slugField"
                    placeholder="my-collection..."
                  />
                  <Button
                    data-tip=""
                    data-for="gen-slug-tooltip"
                    outline
                    onClick={() => setSlug(generateSlug(identifier))}
                  >
                    Gen
                  </Button>
                </InputGroup>
              </FormGroup>
            </Col>
            <Col sm={12} md={4} style={{ zIndex: 3 }}>
              <FormGroup>
                <Label for="chainField">Chain</Label>
                <Select
                  value={{ value: chain, label: chain }}
                  options={chainOptions}
                  defaultValue={{ value: 'ETH', label: 'ETH' }}
                  onChange={(selected) => {
                    setChain(selected.value)
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <ReactTooltip id="gen-slug-tooltip" effect="solid">
                Generate Identifier and Initials from contract.
                <div className="text-warning">
                  <i>loads using moralis API</i>
                </div>
              </ReactTooltip>
              <FormGroup>
                <Label for="contractField">Contract</Label>
                <InputGroup>
                  <Input
                    value={contract}
                    onChange={(e) => setContract(e.target.value.trim())}
                    id="contractField"
                    placeholder="0xc2c747....."
                  />
                  <Button
                    color="secondary"
                    outline
                    onClick={() => {
                      getMoralisMetadata({
                        variables: {
                          moralisContractMetadataInput: {
                            contract,
                            chain,
                          },
                        },
                      })
                    }}
                  >
                    {loadingMoralisMetadata && <Spinner size="sm" />} Load Meta
                  </Button>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>

          {isCommissionGroup(userType) && (
            <Row>
              <Col sm={12} md={9}>
                <FormGroup>
                  <ReactTooltip
                    id="collection-editor-owner-tooltip"
                    effect="solid"
                  >
                    This user will be used to get commissions from other NFT
                    Owners.
                  </ReactTooltip>
                  <Label
                    id="ownerFieldId"
                    data-tip=""
                    data-for="collection-editor-owner-tooltip"
                  >
                    Owner
                  </Label>
                  <SelectUser
                    defaultUser={owner}
                    onUserSelected={(userSelected) => setOwner(userSelected)}
                  />
                </FormGroup>
              </Col>
              <Col sm={12} md={3}>
                <FormGroup>
                  <ReactTooltip
                    id="collection-editor-owner-comm-tooltip"
                    effect="solid"
                  >
                    This user will be used to get commissions from other NFT
                    Owners.
                  </ReactTooltip>
                  <Label
                    id="ownerCommFieldId"
                    data-tip=""
                    data-for="collection-editor-owner-comm-tooltip"
                  >
                    Owner Commission
                  </Label>
                  <Input
                    value={ownerCommission}
                    type="number"
                    onChange={(e) => {
                      setOwnerCommission(e.target.valueAsNumber)
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
          )}
          <hr />
          <Row>
            <Col sm={12} md={10}>
              <ReactTooltip id="gen-rowie-link-tooltip" effect="solid">
                Generate AWS rowie link to load the real image.
              </ReactTooltip>
              <FormGroup>
                <Label for="linkSampleField">Image Link</Label>
                <InputGroup>
                  <Input
                    value={linkSample}
                    onChange={(e) => setLinkSample(e.target.value.trim())}
                    id="linkSampleField"
                    placeholder="https://my-image-link.com"
                  />
                  <Button
                    data-tip=""
                    data-for="gen-rowie-link-tooltip"
                    outline
                    onClick={() => setLinkSample(generateRowieLink(slug))}
                  >
                    Gen
                  </Button>
                </InputGroup>
              </FormGroup>
            </Col>
            <Col sm={12} md={2}>
              <FormGroup>
                <ReactTooltip
                  id="collection-editor-token-tooltip"
                  effect="solid"
                >
                  This token is used for staging purposes to test products
                </ReactTooltip>
                <Label
                  id="tokenFieldId"
                  data-tip=""
                  data-for="collection-editor-token-tooltip"
                >
                  Token
                </Label>
                <Input
                  value={token}
                  defaultValue={'1'}
                  onChange={(e) => setToken(e.target.value.trim())}
                  id="tokenField"
                />
              </FormGroup>
            </Col>
          </Row>

          <span>
            <hr />
            <FormGroup>
              <Label for="copyFromCollectionField">Copy from Collection</Label>
              <Select
                isClearable
                value={copyFromCollection}
                onChange={(selected) => setCopyFromCollection(selected)}
                id="copyFromCollectionField"
                options={collectionOptions}
              />
            </FormGroup>
          </span>
        </ModalBody>
        <ModalFooter>
          {editingCollection && !editingCollection?.sentToWordpress && (
            <Button className="me-auto" onClick={() => toggleSendToWPConfirm()}>
              Send to WP {loadingSendCollectionToWP && <Spinner size="sm" />}
            </Button>
          )}
          <Button
            disabled={!isFormOk}
            color="primary"
            onClick={() => {
              const payload = {
                identifier: identifier?.trim(),
                initials: initials?.trim(),
                contract: contract?.trim(),
                token,
                slug,
                linkSample,
                owner: owner?.id,
                ownerCommission,
                options: {
                  removeBackground,
                  addClearSuffix,
                },
                isActive,
                copyFromCollection: copyFromCollection
                  ? copyFromCollection.value
                  : null,
                chain,
              }

              if (editingCollection) {
                payload.id = editingCollection.id
              }

              if (editingCollection) {
                editCollection({ variables: payload })
              } else {
                addNewCollection({ variables: payload })
              }
            }}
          >
            {(loading || isLoadingEditCollection) && <Spinner size="sm" />}
            Save
          </Button>{' '}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}
const mapStateToProps = (state) => ({
  userType: state?.user?.user?.type,
})

export default connect(mapStateToProps)(CollectionEditor)
