export const statusColors = {
  creating: 'secondary',
  in_progress: 'primary',
  inprogress: 'primary',
  done: 'success',
  error: 'danger',
  invalid: 'danger',
  duplicated: 'secondary',
  onhold: 'warning',
  running: 'primary',
}

export const Status = {
  ERROR: {
    value: 'error',
    color: 'danger',
  },
  ONHOLD: {
    value: 'onhold',
    color: 'warning',
  },
  INPROGRESS: {
    value: 'inprogress',
    color: 'info',
  },
  CREATING: {
    value: 'creating',
    color: 'primary',
  },
  INVALID: {
    value: 'invalid',
    color: 'danger',
  },
  DUPLICATED: {
    value: 'duplicated',
    color: 'secondary',
  },
  DONE: {
    value: 'done',
    color: 'success',
  },
}

export const OrderStatusColor = {
  'pending' : 'info',
  'processing': 'primary',
  'in-production': 'primary',
  'shipped': 'success',
  'completed': 'success',
  'on-hold': 'info',
  'cancelled': 'danger',
  'refunded': 'danger'
}