import React, { useEffect, useState, useRef } from 'react'
import axios from 'axios'
import socketIOClient from 'socket.io-client'
import moment from 'moment'
import {
  Row,
  Col,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap'

const levelToName = (level) => {
  return {
    60: { name: 'FATAL', color: 'rgb(255, 0, 0)' },
    50: { name: 'ERROR', color: 'rgb(196, 81, 81)' },
    40: { name: 'WARN', color: 'rgb(213, 129, 43)' },
    30: { name: 'INFO', color: 'rgb(103, 135, 207)' },
    20: { name: 'DEBUG', color: 'rgb(169, 200, 14)' },
    10: { name: 'TRACE', color: 'rgb(68, 134, 124)' },
  }[level]
}

let socket

const LogsViewer = () => {
  const [combinedLogs, setCombinedLogs] = useState([])
  const [errorLogs, setErrorLogs] = useState([])
  const [debugLogs, setDebugLogs] = useState([])

  const [autoScroll, setAutoScroll] = useState(true)
  const [activeTab, setActiveTab] = useState(1)

  const [isLoadingCombinedChunk, setIsLoadingCombinedChunk] = useState(true)
  const [isLoadingDebugChunk, setIsLoadingDebugChunk] = useState(false)
  const [isLoadingErrorChunk, setIsLoadingErrorChunk] = useState(false)

  const searchParams = new URLSearchParams(window.location.search)

  const nLines = searchParams.get('nLines') ? searchParams.get('nLines') : 200

  useEffect(() => {
    // eslint-disable-next-line no-undef
    socket = socketIOClient(process.env.REACT_APP_API_URL, {
      query: 'type=LOGS',
      extraHeaders: {
        Authorization: `Bearer ${localStorage.getItem('AUTH_TOKEN')}`
      },
    })
  }, [])

  useEffect(() => {
    if (isLoadingCombinedChunk) {
      axios
        .post(
          // eslint-disable-next-line no-undef
          process.env.REACT_APP_API_URL + '/logs-chunk',
          {
            logType: 'combined',
            nLines,
          },
          {
            headers: {
              // eslint-disable-next-line no-undef
              Authorization: process.env.REACT_APP_API_AUTH_HEADER,
            },
          }
        )
        .then((result) => {
          setCombinedLogs((result.data || []).map((l) => JSON.parse(l)))
          if (socket) {
            socket.on('COMBINED_LOG_EVENT', (data) => {
              setCombinedLogs((oldArray) => [...oldArray, JSON.parse(data)])
            })
          }
          setIsLoadingCombinedChunk(false)
        })
    }
  }, [isLoadingCombinedChunk, activeTab, nLines])

  useEffect(() => {
    if (isLoadingDebugChunk) {
      axios
        .post(
          // eslint-disable-next-line no-undef
          process.env.REACT_APP_API_URL + '/logs-chunk',
          {
            logType: 'debug',
            nLines,
          },
          {
            headers: {
              // eslint-disable-next-line no-undef
              Authorization: process.env.REACT_APP_API_AUTH_HEADER,
            },
          }
        )
        .then((result) => {
          setDebugLogs((result.data || []).map((l) => JSON.parse(l)))
          if (socket) {
            socket.on('DEBUG_LOG_EVENT', (data) => {
              setDebugLogs((oldArray) => [...oldArray, JSON.parse(data)])
            })
          }
          setIsLoadingDebugChunk(false)
        })
    }
  }, [isLoadingDebugChunk, activeTab, nLines])

  useEffect(() => {
    if (isLoadingErrorChunk) {
      axios
        .post(
          // eslint-disable-next-line no-undef
          process.env.REACT_APP_API_URL + '/logs-chunk',
          {
            logType: 'error',
            nLines,
          },
          {
            headers: {
              // eslint-disable-next-line no-undef
              Authorization: process.env.REACT_APP_API_AUTH_HEADER,
            },
          }
        )
        .then((result) => {
          setErrorLogs((result.data || []).map((l) => JSON.parse(l)))
          if (socket) {
            socket.on('ERROR_LOG_EVENT', (data) => {
              setErrorLogs((oldArray) => [...oldArray, JSON.parse(data)])
            })
          }
          setIsLoadingErrorChunk(false)
        })
    }
  }, [isLoadingErrorChunk, activeTab, nLines])

  useEffect(() => {
    if (autoScroll) {
      scrollToBottom(messagesEndRefCombined)
    }
  }, [combinedLogs.length, autoScroll])

  useEffect(() => {
    if (autoScroll) {
      scrollToBottom(messagesEndRefError)
    }
  }, [errorLogs.length, autoScroll])

  useEffect(() => {
    if (autoScroll) {
      scrollToBottom(messagesEndRefDebug)
    }
  }, [debugLogs.length, autoScroll])

  const messagesEndRefCombined = useRef(null)
  const messagesEndRefError = useRef(null)
  const messagesEndRefDebug = useRef(null)

  const refsMap = {
    combined: messagesEndRefCombined,
    error: messagesEndRefError,
    debug: messagesEndRefDebug,
  }

  const scrollToBottom = (logsRef) => {
    logsRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  const allLogs = [
    {
      title: 'debug',
      data: debugLogs,
      isLoading: isLoadingDebugChunk,
      setActiveTab: () => {
        if (!isLoadingDebugChunk && debugLogs.length === 0) {
          setIsLoadingDebugChunk(true)
        }
        setActiveTab(0)
      },
    },
    {
      title: 'combined',
      data: combinedLogs,
      isLoading: isLoadingDebugChunk,
      setActiveTab: () => {
        if (!isLoadingCombinedChunk && combinedLogs.length === 0) {
          setIsLoadingCombinedChunk(true)
        }
        setActiveTab(1)
      },
    },
    {
      title: 'error',
      data: errorLogs,
      isLoading: isLoadingErrorChunk,
      setActiveTab: () => {
        if (!isLoadingErrorChunk && errorLogs.length === 0) {
          setIsLoadingErrorChunk(true)
        }
        setActiveTab(2)
      },
    },
  ]

  return (
    <div className="p-2">
      <Row className="m-2">
        <Col md={10}>
          <h2>Logs</h2>
        </Col>
        <Col>
          <Button
            className="float-end"
            color={autoScroll ? 'success' : 'secondary'}
            onClick={() => setAutoScroll(!autoScroll)}
          >
            Auto Scroll
          </Button>
        </Col>
      </Row>

      <Nav tabs>
        {allLogs.map((logEntry, i) => (
          <NavItem key={logEntry.title}>
            <NavLink
              className={activeTab === i ? 'bg-secondary text-white' : ''}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                logEntry.setActiveTab()
              }}
            >
              {logEntry.title}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <TabContent activeTab={activeTab}>
        {allLogs.map((logEntry, i) => (
          <TabPane tabId={i} key={i}>
            <div
              style={{
                maxHeight: '70vh',
                overflowY: 'auto',
                fontFamily: 'monospace',
              }}
              className="border"
            >
              {logEntry.data.map((logRow, j) => {
                const logLevel = levelToName(logRow.level)
                return (
                  <Row key={j}>
                    <Col className="text-justify">
                      [
                      {moment(new Date(logRow?.time)).format(
                        'YYYY-MM-DD HH:mm:SS.SSS'
                      )}
                      ]:{' '}
                      <span
                        className="font-weight-bold"
                        style={{ color: logLevel.color }}
                      >
                        {logLevel.name}
                      </span>{' '}
                      - {logRow.msg}
                    </Col>
                  </Row>
                )
              })}
              <div ref={refsMap[logEntry.title]} />
            </div>
          </TabPane>
        ))}
      </TabContent>
    </div>
  )
}

export default LogsViewer
