import React, { useState } from 'react'
import { useLazyQuery, useQuery } from '@apollo/client'
import {
  GET_COMMISSIONS,
  GET_COMMISSIONS_TOTAL,
  GET_COMMISSION_REPORTS,
} from './actions'
import {
  Badge,
  Button,
  Container,
  Table,
  Row,
  Col,
  Spinner,
  Input,
  Modal,
} from 'reactstrap'
import AppSplash from '../../components/AppSplash'
import { Link } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import { MdVerified } from 'react-icons/md'
import { FaExternalLinkAlt, FaRegEye, FaRegEyeSlash } from 'react-icons/fa'
import { GiMoneyStack } from 'react-icons/gi'
import { AiFillCrown } from 'react-icons/ai'
import { BsCheck2Square } from 'react-icons/bs'
import { Pagination } from '../../components/Pagination'
import moment from 'moment'
import { DatePickerRange } from '../../components/DatePickerRange'
import CommissionPayment from './payments/editor'
import styled from 'styled-components'

const OrderStatus = styled(Badge)`
  position: absolute;
  top: 0;
  left: 0;
  font-size: 0.45em;
  opacity: 0.7;
  border-radius: 0 0 3px;
`

export const CommReportViewer = ({
  vendor,
  period: periodArg,
  officialCollection,
}) => {
  const defaultLimit = 50

  const [selectedCommissions, setSelectedCommissions] = useState({})
  const [period, setPeriod] = useState(periodArg)
  const [limit, setLimit] = useState(defaultLimit)
  const [page, setPage] = useState(1)
  const [showPaid, setShowPaid] = useState(false)
  const [showZeroes, setShowZeroes] = useState(true)

  const [paymentEditorIsOpen, setPaymentEditorIsOpen] = useState(false)

  const toggleSelectCommission = ({ id, commission }) => {
    setSelectedCommissions({
      ...selectedCommissions,
      [id]: {
        selected: !selectedCommissions[id]?.selected,
        commission,
        id,
      },
    })
  }

  const togglePaymentEditor = () => setPaymentEditorIsOpen(!paymentEditorIsOpen)

  const { data, loading, refetch } = useQuery(GET_COMMISSIONS, {
    variables: {
      vendor: vendor?.id,
      period,
      page,
      limit,
      showPaid,
      showZeroes,
    },
    fetchPolicy: 'network-only',
  })

  const [getCommissions] = useLazyQuery(GET_COMMISSIONS, {
    variables: {
      vendor: vendor?.id,
      period,
      page,
      limit,
      showPaid,
      showZeroes,
    },
    fetchPolicy: 'network-only',
  })

  const {
    data: { commissionReports } = {},
    refetch: refetchCommissionReports,
  } = useQuery(GET_COMMISSION_REPORTS, {
    variables: { vendor: vendor?.id, period, page, limit },
    fetchPolicy: 'network-only',
  })

  const totalPeriodCommission =
    commissionReports?.length > 0 ? commissionReports[0].commission : 0

  const { data: { commissionsTotal } = {}, loading: loadingTotal } = useQuery(
    GET_COMMISSIONS_TOTAL,
    {
      variables: { vendor: vendor?.id, period, showPaid, showZeroes },
    }
  )

  const onChangeDateRange = ({ start, end }) => {
    setPeriod({
      startDate: moment.utc(start).startOf('day').valueOf(),
      endDate: moment.utc(end).endOf('day').valueOf(),
    })
    setSelectedCommissions({})
  }

  const onlySelectedCommissions = Object.values(selectedCommissions).filter(
    (value) => value?.selected === true
  )

  const totalSelected = onlySelectedCommissions?.length ?? 0

  const totalCommissionSelected = onlySelectedCommissions?.reduce(
    (prev = 0, current) => prev + current?.commission,
    0
  )

  const allCommissionsSelected = data?.commissions
    ?.filter(
      (comm) =>
        !comm?.payment?.code && comm?.commission > 0 && !comm.shouldIgnore
    )
    .every((comm) => selectedCommissions[comm.id]?.selected)

  const toggleSelectAllCommissions = () => {
    if (!allCommissionsSelected) {
      const newObject = { ...selectedCommissions }
      data?.commissions?.forEach((comm) => {
        if (
          !comm?.payment?.code &&
          comm?.commission > 0 &&
          !comm.shouldIgnore
        ) {
          newObject[comm.id] = {
            selected: true,
            commission: comm.commission,
            id: comm.id,
          }
        }
      })
      setSelectedCommissions(newObject)
    } else {
      const newObject = { ...selectedCommissions }
      data?.commissions?.forEach((comm) => {
        newObject[comm.id] = {
          selected: false,
          id: comm.id,
        }
      })
      setSelectedCommissions(newObject)
    }
  }

  const selectedCommissionsToPay = Object.values(onlySelectedCommissions)?.map(
    (sc) => sc.id
  )

  return (
    <Container fluid>
      <Modal isOpen={paymentEditorIsOpen} toggle={togglePaymentEditor}>
        <CommissionPayment
          vendor={vendor}
          total={totalCommissionSelected}
          commissions={selectedCommissionsToPay}
          toggle={togglePaymentEditor}
          periodTotal={totalPeriodCommission}
          period={period}
          onCompleted={() => {
            refetchCommissionReports()
            setSelectedCommissions({})
            getCommissions()
          }}
        />
      </Modal>
      <Row className="pt-3">
        <Col>
          <h3 className="d-inline">
            {officialCollection && (
              <MdVerified className="text-primary" size="1.3em" />
            )}{' '}
            <b>{vendor?.userName}</b>{' '}
            {officialCollection?.identifier && (
              <Link
                to={`/collections/${officialCollection?.id}`}
                target="_blank"
              >
                <Button
                  outline
                  size="sm"
                  className="cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation()
                  }}
                >
                  {officialCollection?.identifier} <FaExternalLinkAlt />
                </Button>
              </Link>
            )}
          </h3>{' '}
          {loading && <Spinner size="sm" />}
        </Col>
      </Row>
      <Row className="pt-3">
        <Col>
          <Button onClick={refetch} color="primary" outline>
            Refresh {loading && <Spinner size="sm" />}
          </Button>{' '}
          <Button color="primary" outline onClick={togglePaymentEditor}>
            Pay {!totalSelected && 'period'} <GiMoneyStack size="1.4em" />{' '}
            {totalSelected ? (
              <span>
                <Badge color="primary">
                  <BsCheck2Square /> {totalSelected}
                </Badge>{' '}
                <Badge color="success">
                  ${totalCommissionSelected?.toFixed(2)}
                </Badge>
              </span>
            ) : (
              ''
            )}
            {!totalSelected && (
              <Badge color="success">
                ${totalPeriodCommission?.toFixed(2)}
              </Badge>
            )}
          </Button>
        </Col>
        <Col md={4}>
          <Pagination
            defaultLimit={defaultLimit}
            onPageChange={({ page }) => setPage(page)}
            onLimitChange={({ limit }) => setLimit(limit)}
            total={commissionsTotal}
            loadingTotal={loadingTotal}
          />
        </Col>
      </Row>
      <hr />
      <Row>
        <Col md={3}>
          <DatePickerRange
            allowEmpty
            period={period}
            onChange={onChangeDateRange}
          />
        </Col>
        <Col className="d-flex justify-content-end gap-2">
          <Button
            size="sm"
            color={showZeroes ? 'success' : 'secondary'}
            onClick={() => setShowZeroes(!showZeroes)}
          >
            {showZeroes ? <FaRegEye /> : <FaRegEyeSlash />} Zeroes
          </Button>
          <Button
            size="sm"
            color={showPaid ? 'success' : 'secondary'}
            onClick={() => setShowPaid(!showPaid)}
          >
            {showPaid ? <FaRegEye /> : <FaRegEyeSlash />} Paid
          </Button>
        </Col>
      </Row>
      {loading && !data?.commissions && <AppSplash />}
      {data?.commissions && (
        <Table hover>
          <thead>
            <tr>
              <th>
                <Input
                  type="checkbox"
                  checked={allCommissionsSelected}
                  onChange={() => {
                    toggleSelectAllCommissions()
                  }}
                />
              </th>
              <th>#</th>
              <th style={{ width: '40%' }}>P.Name</th>
              <th>Date(GMT)</th>
              <th>Total</th>
              <th>Qty</th>
              <th>Cost</th>
              <th>Commission</th>
              <th>Order</th>
              <th>Error</th>
              {showPaid && <th>Payment</th>}
            </tr>
          </thead>
          <tbody>
            {data?.commissions?.map((comm, i) => {
              const product = comm.product

              let commissionColumnClass =
                comm?.commission && !comm.shouldIgnore ? 'table-success' : ''

              let rowClass = comm?.error ? 'table-danger' : ''

              if (comm.shouldIgnore) {
                rowClass = 'table-secondary'
              }

              return (
                <tr className={rowClass} key={comm.id}>
                  <td>
                    <Input
                      type="checkbox"
                      disabled={
                        comm?.payment?.code ||
                        comm?.commission === 0 ||
                        comm.shouldIgnore
                      }
                      checked={selectedCommissions[comm.id]?.selected}
                      onChange={() => {
                        toggleSelectCommission({
                          id: comm.id,
                          commission: comm?.commission ?? 0,
                        })
                      }}
                    />
                  </td>
                  <td>{i + 1 + (page - 1) * limit}</td>
                  <td>
                    {product?.data?.official_merch && (
                      <span>
                        <ReactTooltip
                          effect="solid"
                          id={`official-tooltip-${comm?.id}`}
                          className="text-center"
                        >
                          Official Product
                        </ReactTooltip>
                        <MdVerified
                          data-tip=""
                          data-for={`official-tooltip-${comm?.id}`}
                          className="text-primary"
                          size="1.3em"
                        />
                      </span>
                    )}{' '}
                    {comm?.isRoyalties && (
                      <span>
                        <ReactTooltip
                          effect="solid"
                          id={`royalties-tooltip-${comm.id}`}
                          className="text-center"
                        >
                          Earned royalties from a NFT Owner
                          <div>
                            from a collection that user &apos;
                            {product?.vendor?.userName}&apos; owns.
                          </div>
                        </ReactTooltip>
                        <AiFillCrown
                          data-tip=""
                          data-for={`royalties-tooltip-${comm.id}`}
                          className="text-warning"
                          size="1.2em"
                        />
                      </span>
                    )}{' '}
                    {product?.data?.name}
                  </td>
                  <td>
                    {moment.utc(comm?.date_created_gmt).format('YYYY-MM-DD')}
                  </td>
                  <td>${product?.data?.total}</td>
                  <td>x{product?.data?.quantity}</td>
                  <td>{comm?.cost ? `$${comm?.cost?.toFixed(2)}` : 'none'}</td>
                  <td
                    className={commissionColumnClass}
                    style={{
                      textDecoration: comm.shouldIgnore ? 'line-through' : '',
                    }}
                  >
                    $ {comm?.commission?.toFixed(2)}
                  </td>
                  <td className="position-relative">
                    <Link to={`/order/${comm?.order?.id}`} target="_blank">
                      {comm?.order?.wooOrderId}{' '}
                    </Link>
                    {['cancelled', 'refunded'].includes(
                      comm?.order?.status
                    ) && (
                      <OrderStatus color="danger">
                        {comm?.order?.status}
                      </OrderStatus>
                    )}
                  </td>
                  <td>
                    {comm?.error && (
                      <span>
                        <ReactTooltip
                          id={`error-p-${comm?.id}`}
                          effect="solid"
                          className="w-25 text-center"
                        >
                          {comm?.error}
                        </ReactTooltip>
                        <Badge
                          data-tip=""
                          data-for={`error-p-${comm?.id}`}
                          color="danger"
                          className="cursor-pointer"
                        >
                          error
                        </Badge>
                      </span>
                    )}{' '}
                    {comm?.warn && (
                      <span>
                        <ReactTooltip
                          id={`warn-p-${comm?.id}`}
                          effect="solid"
                          className="w-25 text-center"
                        >
                          {comm?.warn}
                        </ReactTooltip>
                        <Badge
                          data-tip=""
                          data-for={`warn-p-${comm?.id}`}
                          color="warning"
                        >
                          warn
                        </Badge>
                      </span>
                    )}
                    {comm?.info && (
                      <span>
                        <ReactTooltip
                          id={`info-p-${comm?.id}`}
                          effect="solid"
                          className="w-25 text-center"
                        >
                          {comm?.info}
                        </ReactTooltip>
                        <Badge
                          data-tip=""
                          data-for={`info-p-${comm?.id}`}
                          color="info"
                        >
                          info
                        </Badge>
                      </span>
                    )}
                  </td>
                  {showPaid && (
                    <td>
                      {comm?.payment?.code && (
                        <Badge color="success">PMT-{comm?.payment?.code}</Badge>
                      )}
                    </td>
                  )}
                </tr>
              )
            })}
          </tbody>
        </Table>
      )}
    </Container>
  )
}
