import { useQuery } from '@apollo/client'
import moment from 'moment'
import React, { useState } from 'react'
import {
  Col,
  Row,
  Table,
  Button,
  Spinner,
  Modal,
  ModalBody,
  ModalHeader,
  Badge,
} from 'reactstrap'
import { Pagination } from '../../../components/Pagination'
import SelectUser from '../../NFTUser/SelectUser'
import {
  GET_COMMISSION_PAYMENTS,
  GET_COMMISSION_PAYMENTS_TOTAL,
} from './actions'
import PaymentViewer from './viewer'

const PaymentsList = () => {
  const defaultLimit = 20
  const [isPreviewOpen, setIsPreviewOpen] = useState(false)
  const [selectedPayment, setSelectedPayment] = useState()
  const [page, setPage] = useState(1)
  const [vendor, setVendor] = useState()
  const [limit, setLimit] = useState(defaultLimit)

  const { data: { commissionPaymentsTotal } = {}, loading: loadingTotal } =
    useQuery(GET_COMMISSION_PAYMENTS_TOTAL, {
      variables: {
        vendor: vendor?.id,
      },
    })

  const { data, loading, refetch } = useQuery(GET_COMMISSION_PAYMENTS, {
    variables: {
      limit,
      page,
      vendor: vendor?.id,
    },
  })

  const togglePreview = () => {
    setIsPreviewOpen(!isPreviewOpen)
  }

  return (
    <div>
      <Modal isOpen={isPreviewOpen} toggle={togglePreview}>
        <ModalHeader>
          Payment Preview -{' '}
          {selectedPayment && (
            <Badge color="success">PMT-{selectedPayment.code}</Badge>
          )}
        </ModalHeader>
        <ModalBody>
          {selectedPayment && <PaymentViewer {...selectedPayment} />}
          {!selectedPayment && 'Payment is not valid'}
        </ModalBody>
      </Modal>
      <Row>
        <Col>
          <Button
            color="secondary"
            onClick={() => {
              refetch()
            }}
          >
            Refresh {loading && <Spinner size="sm" />}
          </Button>
        </Col>
        <Col>
          <Pagination
            onPageChange={({ page }) => {
              setPage(page)
            }}
            total={commissionPaymentsTotal ?? 0}
            loadingTotal={loadingTotal}
            defaultLimit={defaultLimit}
            onLimitChange={({ limit }) => {
              setLimit(limit)
            }}
          />
        </Col>
      </Row>
      <hr />
      <Table hover>
        <thead>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>
              <SelectUser
                onUserSelected={(user) => {
                  setVendor(user)
                }}
              />
            </td>
            <td></td>
          </tr>
          <tr>
            <th>#</th>
            <th>Summary</th>
            <th>Created At (GMT)</th>
            <th>Created By</th>
            <th>Vendor</th>
            <th className="col-md-1">Total Paid</th>
          </tr>
        </thead>
        <tbody>
          {data?.commissionPayments?.map((payment, i) => {
            return (
              <tr
                key={payment.id}
                className="cursor-pointer"
                onClick={() => {
                  setSelectedPayment(payment)
                  togglePreview()
                }}
              >
                <td>{i + 1 + (page - 1) * limit}</td>
                <td>{payment.summary}</td>
                <td>
                  {moment.utc(payment.createdAt).format('YYYY-MM-DD HH:mm')}
                </td>
                <td>{payment.user?.username}</td>
                <td>{payment.vendor?.userName}</td>
                <td>{payment.totalPaid?.toFixed(2)}</td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </div>
  )
}

export default PaymentsList
