import React, { useState } from 'react'
import { Button, Alert, Col } from 'reactstrap'
import { toast } from 'react-toastify'
import { useQuery } from '@apollo/client'
import { useParams, Redirect } from 'react-router-dom'
import ProductEditor from './editor'
import axios from 'axios'
import AppSplash from '../../components/AppSplash'
import Select from 'react-select'
import { GET_COLLECTIONS } from '../Collection/actions'
import { GET_PRODUCT_BY_ID } from './actions'

const ProductViewer = () => {
  let { id } = useParams()

  const { loading, error, data } = useQuery(GET_PRODUCT_BY_ID, {
    variables: { id },
  })

  const {
    loading: loadingCollections,
    error: errorLoadingCollections,
    data: { collections } = {},
  } = useQuery(GET_COLLECTIONS)

  const [selectedCollections, setSelectedCollections] = useState({})

  if (loading || loadingCollections) {
    return <AppSplash />
  }

  if (error || errorLoadingCollections) {
    if (error.message === 'Unauthorized') {
      return <Redirect to="/login" />
    }
    return (
      <div className="text-center m-4">
        <Alert color="danger">
          An error has ocurred :(. {error.message}{' '}
          {errorLoadingCollections?.message}
        </Alert>
      </div>
    )
  }

  const createPrintifyProducts = async () => {
    const collectionsToCreate = collections.filter(
      (col) => selectedCollections[col.id]
    )

    if (id) {
      axios
        // eslint-disable-next-line no-undef
        .post(process.env.REACT_APP_API_URL || '/', {
          productFilter: id,
          nfts: collectionsToCreate.map((col) => ({
            data: {
              identifier: col.identifier,
              token: col.token || 1,
              link: col.linkSample,
            },
          })),
          userName: 'staging-api',
          userId: 1,
          isStaging: true,
        })
        .then(() => toast.success(`Request sent successfully!`))
        .catch((e) => toast.error(`Error creating products. ${e.message}`))
    }
  }

  const hasSelectedCollections = Object.values(selectedCollections).find(
    (v) => v
  )

  const selectedCollectionsParsed = []

  Object.keys(selectedCollections || {}).forEach((selectedId) => {
    if (selectedCollections[selectedId]) {
      const collectionSelected = collections.find((c) => c.id === selectedId)
      selectedCollectionsParsed.push({
        value: collectionSelected.id,
        label: collectionSelected.initials,
      })
    }
  })

  const additionalFeatureOnFooter = (
    <>
      <Col>
        <Select
          options={collections.map((c) => ({
            value: c.id,
            label: c.identifier,
          }))}
          isMulti
          menuPlacement="top"
          closeMenuOnSelect={false}
          value={selectedCollectionsParsed}
          onChange={(selected) => {
            if (selected) {
              const newSelectedCollections = {}
              collections.forEach((col) => {
                newSelectedCollections[col.id] = selected.find(
                  (s) => s.value === col.id
                )
              })
              setSelectedCollections(newSelectedCollections)
            }
          }}
        />
      </Col>
      <Col md={2}>
        <Button
          block
          disabled={!hasSelectedCollections}
          onClick={() => {
            if (hasSelectedCollections) {
              createPrintifyProducts()
            }
          }}
        >
          Create Products
        </Button>
      </Col>
    </>
  )

  return (
    <ProductEditor
      key={`product-editor-${id}`}
      editingProduct={data.productById}
      collections={collections}
      buttonLabel="Edit"
      additionalFeatureOnFooter={additionalFeatureOnFooter}
    />
  )
}

export default ProductViewer
