/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import ReactTooltip from 'react-tooltip'
import {
  Input,
  Spinner,
  Pagination as PaginationBS,
  PaginationItem,
  PaginationLink,
} from 'reactstrap'

export const Pagination = ({
  total,
  loadingTotal,
  onPageChange,
  onLimitChange,
  defaultLimit = 10,
}) => {
  const [limit, setLimit] = useState(defaultLimit)
  const [page, setPage] = useState(1)
  const pageCount = Math.ceil(total / limit || 1)
  const limitOptions = [100, 50, 20, 10]

  const handleSetPage = (newPage) => {
    setPage(newPage)
    if (onPageChange) {
      onPageChange({ page: newPage })
    }
  }

  return (
    <span>
      <div className="d-flex flex-row gap-2 align-items-center">
        <div className="d-flex flex-row align-items-center gap-2 ms-auto">
          <div>Limit</div>
          <Input
            type="select"
            value={limit}
            onChange={(e) => {
              const newLimit = parseInt(e.target.value)
              setLimit(newLimit)
              if (onLimitChange) {
                onLimitChange({ limit: newLimit })
              }
              handleSetPage(1)
            }}
          >
            {limitOptions.map((limitOption) => (
              <option key={limitOption} value={limitOption}>
                {limitOption}
              </option>
            ))}
          </Input>
        </div>
        <div>
          {loadingTotal && <Spinner size="sm" />} Total: {total}
        </div>
        <PaginationBS tag="div" listClassName="m-0">
          {page > 1 && (
            <PaginationItem>
              <PaginationLink first onClick={() => handleSetPage(1)} />
            </PaginationItem>
          )}
          {page - 1 > 0 && (
            <PaginationItem>
              <PaginationLink
                previous
                onClick={() => handleSetPage(page - 1)}
              />
            </PaginationItem>
          )}
          <PaginationItem active>
            <PaginationLink>{page}</PaginationLink>
          </PaginationItem>
          {page + 1 < pageCount && (
            <PaginationItem>
              <PaginationLink next onClick={() => handleSetPage(page + 1)} />
            </PaginationItem>
          )}
          {page < pageCount && (
            <>
              <ReactTooltip
                id="last-page-pagination"
                effect="solid"
                place="bottom"
              >
                {pageCount}
              </ReactTooltip>
              <PaginationItem data-tip="" data-for="last-page-pagination">
                <PaginationLink last onClick={() => handleSetPage(pageCount)} />
              </PaginationItem>
            </>
          )}
        </PaginationBS>
      </div>
    </span>
  )
}
