import { useQuery } from '@apollo/client'
import React, { useState } from 'react'
import {
  Alert,
  Button,
  Col,
  FormGroup,
  Label,
  ListGroup,
  ListGroupItem,
  Row,
  Spinner,
} from 'reactstrap'
import AppSplash from '../../components/AppSplash/index.js'
import { GET_ARTISTS_REQUESTS } from './actions.js'
import ArtworkItem from './ArtworkItem.js'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import { Status } from '../../utils/status'
import { Pagination } from '../../components/Pagination/index.js'

const ArtistsRequests = () => {
  const [page, setPage] = useState(1)
  const defaultLimit = 10
  const [limit, setLimit] = useState(defaultLimit)
  const [statusFilter, setStatusFilter] = useState([
    Status.ONHOLD.value,
    Status.INPROGRESS.value,
  ])

  const { loading, error, data, refetch } = useQuery(GET_ARTISTS_REQUESTS, {
    variables: { page, limit, status: statusFilter },
    pollInterval: 5 * 60 * 1000,
    fetchPolicy: 'network-only',
  })

  if (error) {
    return <Alert color="danger">An error occurred :( - {error.message}</Alert>
  }

  const { artworks: { requests = [], total } = {} } = data || {}

  const statusOptions = Object.values(Status).map((statusVal) => ({
    value: statusVal.value,
    label: statusVal.value,
  }))

  return (
    <div>
      <Row className="mb-1">
        <Col>
          <FormGroup row>
            <Label md={2} className="mr-2">
              Status
            </Label>
            <Col>
              <Select
                value={statusFilter.map((sf) => ({
                  value: sf,
                  label: sf,
                }))}
                isMulti
                options={statusOptions}
                onChange={(selected) => {
                  setStatusFilter(selected.map((so) => so.value))
                }}
                closeMenuOnSelect={false}
              />
            </Col>
          </FormGroup>
        </Col>
        <Col md={1}>
          <Button onClick={() => refetch()}>
            Refresh {loading && requests?.length > 0 && <Spinner size="sm" />}
          </Button>
        </Col>
        <Col>
          <Pagination
            defaultLimit={defaultLimit}
            loading={loading}
            total={total}
            onLimitChange={({ limit }) => setLimit(limit)}
            onPageChange={({ page }) => setPage(page)}
          />
        </Col>
      </Row>
      {loading && requests?.length === 0 && <AppSplash />}
      <ListGroup className="overflow-auto" style={{ zIndex: 0 }}>
        {requests.map((req) => (
          <ListGroupItem
            key={req.id}
            className="cursor-pointer"
            tag={Link}
            to={`/artwork/${req.id}`}
            action
          >
            <ArtworkItem
              id={req.id}
              user={req.user}
              createdAt={req.createdAt}
              imageLink={req.imageLink}
              status={req.status}
              statusColor={
                Object.values(Status).find((s) => s.value === req.status)
                  ?.color || ''
              }
            />
          </ListGroupItem>
        ))}
      </ListGroup>
    </div>
  )
}

export default ArtistsRequests
