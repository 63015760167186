/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { FaExternalLinkAlt } from 'react-icons/fa'
import { TiWarning } from 'react-icons/ti'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import { Badge, Button, Spinner } from 'reactstrap'
import { GET_PRODUCTS } from '../Product/actions'
import { BiSelectMultiple } from 'react-icons/bi'
import ReactTooltip from 'react-tooltip'
import Confirm from '../../components/Confirm'
import { APPLY_ALL_PRODUCT_NAME_TO_MATCHED } from './actions'
import { toast } from 'react-toastify'

export default function OrderProductEditor({
  orderId,
  data,
  commission,
  productMatched,
  onProductChange,
  cost,
}) {
  const buildOptionElement = (product) => ({
    value: product,
    label: product?.name,
  })
  const [confirmApplyProductsIsOpen, setConfirmApplyProductsIsOpen] =
    useState(false)

  const { data: productsData, loading: loadingProductsList } = useQuery(
    GET_PRODUCTS,
    { variables: { showInactive: true } }
  )

  const [
    applyProductNameToMatched,
    { loading: loadingApplyProductMatchedToAll },
  ] = useMutation(APPLY_ALL_PRODUCT_NAME_TO_MATCHED, {
    onError: (error) => {
      toast.error(`Error applying products. ${error.message}`)
    },
    onCompleted: () => {
      toast.success('All products applied.')
    },
  })

  const productsOptions =
    productsData?.products?.map((p) => buildOptionElement(p)) ?? []

  const toggleConfirmApplyProducts = () =>
    setConfirmApplyProductsIsOpen(!confirmApplyProductsIsOpen)
  return (
    <tr>
      <Confirm
        isOpen={confirmApplyProductsIsOpen}
        message={
          <div className="text-center">
            Do you really want to apply the matched product{' '}
            <span className="text-primary fw-bold">{productMatched?.name}</span>{' '}
            to all the products with name{' '}
            <span className="text-primary fw-bold">{data?.name}</span> on the
            database?
          </div>
        }
        toggle={toggleConfirmApplyProducts}
        onYesClicked={() => {
          applyProductNameToMatched({
            variables: {
              orderId,
              productName: data?.name,
              productMatched: productMatched?.id,
            },
          })
        }}
      />
      <td>{data?.name}</td>
      <td>
        <div className="d-flex" style={{ minWidth: '15em' }}>
          <div className="flex-fill">
            <Select
              isLoading={loadingProductsList}
              value={productMatched ? buildOptionElement(productMatched) : null}
              options={productsOptions}
              onChange={(selected) => {
                onProductChange({ productMatched: selected.value })
              }}
            />
          </div>
          {productMatched && (
            <span className="p-1">
              <Link to={`/products/${productMatched?.id}`}>
                <FaExternalLinkAlt />
              </Link>{' '}
              <ReactTooltip
                id={`tooltip-copy-matched-to-others-${data?.name}`}
                effect="solid"
                className="text-center"
              >
                Copy this selection for all
                <div>
                  <span className="text-warning">{data?.name}</span>
                </div>
                <div>inside database.</div>
              </ReactTooltip>
              <Button
                size="sm"
                outline
                data-tip=""
                data-for={`tooltip-copy-matched-to-others-${data?.name}`}
                onClick={() => toggleConfirmApplyProducts()}
              >
                <BiSelectMultiple />{' '}
                {loadingApplyProductMatchedToAll && <Spinner size="sm" />}
              </Button>
            </span>
          )}
          {!productMatched && (
            <span className="p-1 text-danger">
              <TiWarning />
            </span>
          )}
        </div>
      </td>
      <td>{data?.vendor_id}</td>
      <td>${data?.total_tax}</td>
      <td>${data?.subtotal}</td>
      <td>${data?.total}</td>
      <td>x{data?.quantity}</td>
      <td>{cost ? `${cost?.toFixed(2)}` : '-'}</td>
      <td>
        {data?.official_merch && (
          <ReactTooltip
            id={`official-comm-info-${orderId}-${data?.product_id}`}
            className="text-center"
            effect="solid"
          >
            Official Products commission calculates by the difference from
            <div className="text-info">${data?.total}(total) - ${cost}(cost) = {(data?.total - cost).toFixed(2)}</div>
            <div className="fst-italic text-warning">
              If difference is under cost commission will be limited by zero.
            </div>
            Check product configs for more info.
          </ReactTooltip>
        )}
        <span
          data-tip=""
          data-for={`official-comm-info-${orderId}-${data?.product_id}`}
          className={`${
            data?.official_merch ? 'text-decoration-line-through' : ''
          } d-flex justify-content-center`}
        >
          {data?.vendor_commision || 15}%
        </span>
      </td>
      <td>
        {(commission || 0).toFixed(2)}
      </td>
      <td>
        <Badge color={data?.official_merch ? 'primary' : 'secondary'}>
          {data?.official_merch ? 'Official' : 'NFTO'}
        </Badge>
      </td>
    </tr>
  )
}
