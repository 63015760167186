import React, { useState } from 'react'
import { useParams, Redirect } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useMutation, useQuery } from '@apollo/client'
import axios from 'axios'
import Image from '../../components/Image'
import {
  Card,
  Row,
  Col,
  Button,
  InputGroup,
  InputGroupText,
  Alert,
  Spinner,
} from 'reactstrap'
import styled from 'styled-components'

import {
  ADD_NEW_PRODUCT_SPEC,
  DELETE_PRODUCT_SPEC,
  GET_COLLECTION_BY_ID,
  UPDATE_PRODUCT_SPEC,
} from './actions'
import CollectionEditor from './editor'
import DeleteCollection from './delete'
import ProductSpecEditor from '../Product/ProductSpecEditor'
import copy from 'copy-to-clipboard'
import ActionBadge from '../../components/ActionBadge'

export const SpecSpinner = styled(Spinner)`
  position: absolute;
  bottom: -1em;
  right: -1em;
`

const CollectionViewer = () => {
  let { id } = useParams()

  const [showArchived, setShowArchived] = useState(false)

  const { loading, error, data } = useQuery(GET_COLLECTION_BY_ID, {
    variables: { id },
  })

  const [addNewSpec, { loading: isSavingSpec }] = useMutation(
    ADD_NEW_PRODUCT_SPEC,
    {
      onCompleted: () => {
        toast.success('Adjustment created!')
      },
      onError: (error) => {
        toast.error(error.message)
      },
    }
  )

  const [updateSpec, { loading: isUpdatingSpec }] = useMutation(
    UPDATE_PRODUCT_SPEC,
    {
      onCompleted: () => {
        toast.success('Adjustment updated!')
      },
      onError: (error) => {
        toast.error(error.message)
      },
    }
  )

  const [deleteSpec, { loading: isDeletingSpec }] = useMutation(
    DELETE_PRODUCT_SPEC,
    {
      onCompleted: () => {
        toast.success('Adjustment removed!')
      },
      onError: (error) => {
        toast.error(error.message)
      },
      update(cache, { data: { deleteProductSpec } }) {
        const normalizedId = cache.identify({
          id: deleteProductSpec,
          __typename: 'ProductSpec',
        })
        cache.evict({ id: normalizedId })
        cache.gc()
      },
    }
  )

  if (loading) return <div>Loading...</div>
  if (error) {
    if (error.message === 'Unauthorized') {
      return <Redirect to="/login" />
    }
    return (
      <div className="text-center m-4">
        <Alert color="danger">An error has ocurred :(</Alert>
      </div>
    )
  }

  const {
    identifier,
    initials,
    contract,
    linkSample,
    productSpecs,
    options,
    token,
    isActive,
  } = data.collectionById

  const productSpecsFiltered =
    productSpecs?.filter((ps) =>
      showArchived ? true : !ps.productDef.isArchived
    ) ?? []

  const removeBackground = options && options.removeBackground

  const createAllProducts = () => {
    const data = {
      nfts: [
        {
          data: {
            identifier: identifier,
            token: token || 1,
            link: linkSample,
          },
        },
      ],
      userName: 'staging-api',
      userId: 1,
      isStaging: true,
    }
    axios
      .post(process.env.REACT_APP_API_URL || '/', data)
      .then(() => toast.success(`Request sent successfully!`))
      .catch((e) => toast.error(`Error creating products. ${e.message}`))
  }

  const testProductWithAdjustment = ({ productDef, imageAdjustment }) => {
    const data = {
      productFilter: productDef,
      adjustmentsFilter: { imageAdjustment },
      nfts: [
        {
          data: {
            identifier: identifier,
            token: token || 1,
            link: linkSample,
          },
        },
      ],
      userName: 'staging-api',
      userId: 1,
      isStaging: true,
    }

    axios
      .post(process.env.REACT_APP_API_URL || '/', data)
      .then(() => toast.success(`Request sent successfully!`))
      .catch((e) => toast.error(`Error creating products. ${e.message}`))
  }

  return (
    <div className="m-4">
      <Card size="sm" className="p-4">
        {(isUpdatingSpec || isSavingSpec || isDeletingSpec) && <SpecSpinner />}
        <Row>
          <Col md={1}>
            <Image
              src={linkSample}
              width="80em"
              alt=""
              className="rounded border"
            />
          </Col>
          <Col>
            <h1>
              {identifier} - {initials}
            </h1>
            <p>
              contract:{' '}
              <ActionBadge
                color="light"
                className="cursor-pointer"
                onClick={(e) => {
                  copy(contract)
                  toast.info(`"${contract}" copied`)
                  e.preventDefault()
                }}
              >
                {contract}
              </ActionBadge>
              <br />
              UUID:{' '}
              <ActionBadge
                color="light"
                className="cursor-pointer"
                onClick={(e) => {
                  copy(id)
                  toast.info(`"${id}" copied`)
                  e.preventDefault()
                }}
              >
                {id}
              </ActionBadge>
            </p>
            <Row>
              <Col md={5}>
                <InputGroup className="">
                  <InputGroupText>Remove BG</InputGroupText>
                  <Button
                    disabled
                    color={removeBackground ? 'success' : 'danger'}
                  >
                    {removeBackground ? 'Yes' : 'No'}
                  </Button>
                </InputGroup>
              </Col>
              <Col className="pl-0">
                <InputGroup className="">
                  <InputGroupText>Is Active</InputGroupText>
                  <Button disabled color={isActive ? 'success' : 'danger'}>
                    {isActive ? 'Yes' : 'No'}
                  </Button>
                </InputGroup>
              </Col>
            </Row>
          </Col>

          <Col md={3}>
            <div className="d-flex gap-2 justify-content-end">
              <DeleteCollection collectionId={data.collectionById.id} />
              <CollectionEditor
                className="d-inline float-end"
                buttonLabel="Edit"
                editingCollection={data.collectionById}
              />
            </div>
          </Col>
        </Row>

        <hr />
        <Row>
          <Col md={3}>
            <Button color="primary" block onClick={createAllProducts}>
              Test All Products
            </Button>
          </Col>
          <Col md={2}>
            <Button
              color={showArchived ? 'success' : 'secondary'}
              block
              onClick={() => setShowArchived((v) => !v)}
            >
              {showArchived ? 'Hide' : 'Show'} Archived
            </Button>
          </Col>
        </Row>
        <hr />
        <ProductSpecEditor
          testProductWithAdjustment={testProductWithAdjustment}
          productSpecs={productSpecsFiltered}
          addNewSpec={({ productDef, imageAdjustment }) => {
            addNewSpec({
              variables: {
                productDef,
                imageAdjustment,
                collectionId: id,
              },
            })
          }}
          updateSpec={({ productDef, imageAdjustment }) => {
            updateSpec({
              variables: {
                productDef,
                imageAdjustment,
                collectionId: id,
              },
            })
          }}
          deleteSpec={({ id: specId }) => {
            deleteSpec({
              variables: {
                collectionId: id,
                id: specId,
              },
            })
          }}
        />
      </Card>
    </div>
  )
}

export default CollectionViewer
