import { gql } from '@apollo/client'
export const GET_NFT_REQUESTS = gql`
  query GetNFTRequests($limit: Int, $page: Int, $filters: NFTRequestFilter) {
    nftRequests(limit: $limit, page: $page, filters: $filters) {
      id
      user {
        userName
        userId
      }
      nfts {
        id
        link
        token
        status
        nftCollection {
          id
          identifier
        }
        statusMsg
      }
      createdAt
    }
  }
`

export const GET_TOTAL_NFT_REQUESTS = gql`
  query GetTotalNFTRequests($filters: NFTRequestFilter) {
    totalNFTRequests(filters: $filters)
  }
`

export const GET_NFT_BY_ID = gql`
  query GetNFTById($id: String) {
    nftById(id: $id) {
      token
      link
      finalLink
      chain
      status
      statusMsg
      originalData {
        link
      }
      user {
        id
        userId
        userName
      }
      nftCollection {
        id
        identifier
        initials
      }
      skipBackgroundRemoval
    }
  }
`

export const EDIT_NFT = gql`
  mutation EditNFT($id: String, $link: String, $skipBackgroundRemoval: Boolean) {
    editNFT(id: $id, link: $link, skipBackgroundRemoval: $skipBackgroundRemoval) {
      id
      link
      finalLink
      skipBackgroundRemoval
    }
  }
`

export const RESEND_NFT = gql`
  mutation ResendNFT($id: String) {
    resendNFT(id: $id) {
      id
      finalLink
      status
      statusMsg
    }
  }
`
