import React, { useState } from 'react'
import {
  Badge,
  Button,
  Container,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Spinner,
} from 'reactstrap'
import Confirm from '../../../components/Confirm'
import Select from 'react-select'
import moment from 'moment'
import SelectUser from '../../NFTUser/SelectUser'
import { GiMoneyStack } from 'react-icons/gi'
import { BsCheck2Square } from 'react-icons/bs'
import { PAY_COMMISSIONS } from '../actions'
import { useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import PaymentViewer from './viewer'

const CommissionPayment = ({
  commissions = [],
  total: totalArg,
  vendor: vendorArg,
  period,
  periodTotal,
  toggle,
  onCompleted,
}) => {
  const isSelectedCommissionsEmpty = commissions.length === 0

  const [total, setTotal] = useState(
    isSelectedCommissionsEmpty ? periodTotal : totalArg
  )
  const [platform, setPlatform] = useState()
  const [summary, setSummary] = useState('')
  const [fromAccount, setFromAccount] = useState('')
  const [toAccount, setToAccount] = useState('')
  const [vendor, setVendor] = useState(vendorArg)
  const [transactionDetails, setTransactionDetails] = useState('')

  const [confirmPaymentIsOpen, setConfirmPaymentIsOpen] = useState('')

  const toggleConfirmPayment = () =>
    setConfirmPaymentIsOpen(!confirmPaymentIsOpen)

  const platformOptions = ['ETH', 'PayPal', 'Other'].map((o) => ({
    label: o,
    value: o,
  }))

  const [payCommissions, { loading }] = useMutation(PAY_COMMISSIONS, {
    variables: {
      vendor: vendor?.id,
      period,
      selectedCommissions: commissions,
      paymentInfo: {
        summary,
        totalPaid: parseFloat(total?.toFixed(2) ?? 0),
        transactionDetails,
        fromAccount,
        toAccount,
        platform,
      },
    },
    onCompleted: () => {
      toast.success('Payment created successfully!')
      if (onCompleted) {
        onCompleted()
      }
    },
    onError: (error) => {
      toast.error(`Payment error. ${error.message}`)
    },
  })

  const totalCommissionsSelected = commissions?.length ?? 0

  return (
    <>
      <Confirm
        toggle={toggleConfirmPayment}
        msgHeader="Commission Payment"
        onYesClicked={() => {
          payCommissions()
        }}
        message={
          <div>
            Do you really want to create the payment for the commissions?
            <hr />
            <PaymentViewer
              summary={summary}
              transactionDetails={transactionDetails}
              period={period}
              fromAccount={fromAccount}
              toAccount={toAccount}
              platform={platform}
              vendor={vendor}
              totalPaid={total}
              totalCommissionsSelected={totalCommissionsSelected}
            />
          </div>
        }
        isOpen={confirmPaymentIsOpen}
      />
      <Container className="border-bottom p-3 sticky-top bg-light text-center">
        <h5 className="m-0 d-flex justify-content-between">
          <GiMoneyStack className="text-success" size="1.5em" /> Commission
          Payment <GiMoneyStack className="text-success" size="1.5em" />
        </h5>
        <h5 className="m-0 mt-2">
          <Badge>
            {period?.startDate &&
              moment.utc(period?.startDate).format('YYYY-MM-DD')}
          </Badge>{' '}
          -{' '}
          <Badge>
            {period?.endDate && (
              <span>{moment.utc(period?.endDate).format('YYYY-MM-DD')}</span>
            )}
          </Badge>
        </h5>
      </Container>
      <Container className="pt-2 pb-2">
        <FormGroup>
          <Label for="summaryField">Summary</Label>
          <Input
            id="summaryField"
            placeholder="2022 March Payment - svs"
            value={summary}
            onChange={(e) => setSummary(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label for="transactionDetailsField">Transaction Details</Label>
          <Input
            id="transactionDetailsField"
            placeholder="PayPal transaction ID"
            type="textarea"
            value={transactionDetails}
            onChange={(e) => setTransactionDetails(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label for="fromAccountField">From Account</Label>
          <Input
            id="fromAccountField"
            placeholder="Maddies PayPal"
            value={fromAccount}
            onChange={(e) => setFromAccount(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label for="toAccountField">To Account</Label>
          <Input
            id="toAccountField"
            placeholder="Customer PayPal"
            value={toAccount}
            onChange={(e) => setToAccount(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label for="paymentPlatformField">Platform</Label>
          <Select
            options={platformOptions}
            value={platform ? { value: platform, label: platform } : null}
            onChange={(selected) => {
              setPlatform(selected?.value)
            }}
          />
        </FormGroup>
        <FormGroup>
          <Label for="paymentCommissionCustomerField">Customer</Label>
          <SelectUser
            defaultUser={vendor}
            onUserSelected={(user) => {
              setVendor(user)
            }}
          />
        </FormGroup>
        <FormGroup>
          <Label for="totalPaidField">Total</Label>
          <InputGroup>
            <InputGroupText>$</InputGroupText>
            <Input
              id="totalPaidField"
              placeholder="50.00"
              type="number"
              value={total?.toFixed(2)}
              defaultValue={total?.toFixed(2)}
              onChange={(e) => setTotal(e.target.valueAsNumber)}
            />

            <InputGroupText className="gap-1">
              {totalCommissionsSelected > 0 && (
                <>
                  <BsCheck2Square /> {totalCommissionsSelected}
                </>
              )}
              {totalCommissionsSelected === 0 && 'All in period'}
            </InputGroupText>
          </InputGroup>
        </FormGroup>
      </Container>
      <Container className="d-flex justify-content-end gap-2 border-top p-3">
        <Button
          onClick={() => {
            if (toggle) {
              toggle()
            }
          }}
        >
          Cancel
        </Button>{' '}
        <Button
          color="primary"
          onClick={toggleConfirmPayment}
          disabled={loading}
        >
          {loading ? 'Creating' : 'Create'} {loading && <Spinner size="sm" />}
        </Button>
      </Container>
    </>
  )
}

export default CommissionPayment
