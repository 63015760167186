import { useQuery } from '@apollo/client'
import React from 'react'
import { useParams } from 'react-router-dom'
import { Container } from 'reactstrap'
import AppSplash from '../../components/AppSplash'
import { GET_PACKAGE_BY_ID } from './actions'
import Editor from './editor'

export default function PackageViewer() {
  const { id } = useParams()

  const { loading, data: { packageById } = {} } = useQuery(GET_PACKAGE_BY_ID, {
    variables: { id },
  })

  if (loading) {
    return <AppSplash/>
  }

  return (
    <Container>
      <Editor id={id} name={packageById?.name} products={packageById?.products} />
    </Container>
  )
}
