import { useQuery } from '@apollo/client'
import React, { useState } from 'react'
import {
  Alert,
  Button,
  Col,
  FormGroup,
  Label,
  ListGroup,
  ListGroupItem,
  Row,
  Spinner,
} from 'reactstrap'
import AppSplash from '../../components/AppSplash'
import { GET_OFFICIAL_MERCHS, GET_OFFICIAL_MERCHS_TOTAL } from './actions'
import Item from './item'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import styled from 'styled-components'
import { Status } from '../../utils/status'
import { Pagination } from '../../components/Pagination'

const ArtistsRequests = () => {
  const [page, setPage] = useState(1)
  const defaultLimit = 10
  const [limit, setLimit] = useState(defaultLimit)
  const [statusFilter, setStatusFilter] = useState([
    Status.ONHOLD.value,
    Status.INPROGRESS.value,
  ])

  const {
    loading,
    error,
    data: { officialMerchs } = {},
    refetch,
  } = useQuery(GET_OFFICIAL_MERCHS, {
    variables: { page, limit, status: statusFilter },
    pollInterval: 5 * 60 * 1000,
    fetchPolicy: 'network-only',
  })

  const { data: { officialMerchsTotal: total } = {} } = useQuery(
    GET_OFFICIAL_MERCHS_TOTAL,
    {
      variables: { page, limit, status: statusFilter },
      pollInterval: 5 * 60 * 1000,
      fetchPolicy: 'network-only',
    }
  )

  if (error) {
    return <Alert color="danger">An error occurred :( - {error.message}</Alert>
  }

  const statusOptions = Object.values(Status).map((statusVal) => ({
    value: statusVal.value,
    label: statusVal.value,
  }))

  const StatusSelect = styled(Select)`
    min-width: 20em !important;
    max-width: 32em !important;
  `

  return (
    <div>
      <Row className="mb-1">
        <Col>
          <FormGroup row>
            <Label md={2}>Status</Label>
            <Col>
              <StatusSelect
                value={statusFilter.map((sf) => ({
                  value: sf,
                  label: sf,
                }))}
                isMulti
                options={statusOptions}
                onChange={(selected) => {
                  setStatusFilter(selected.map((so) => so.value))
                }}
                closeMenuOnSelect={false}
              />
            </Col>
          </FormGroup>
        </Col>
        <Col md={1}>
          <Button onClick={() => refetch()}>
            Refresh{' '}
            {loading && officialMerchs?.length > 0 && <Spinner size="sm" />}
          </Button>
        </Col>
        <Col>
          <Pagination
            defaultLimit={defaultLimit}
            loading={loading}
            total={total}
            onLimitChange={({ limit }) => setLimit(limit)}
            onPageChange={({ page }) => setPage(page)}
          />
        </Col>
      </Row>
      {loading && officialMerchs?.length === 0 && <AppSplash />}
      <ListGroup className="overflow-auto" style={{ zIndex: 0 }}>
        {officialMerchs?.map((om) => (
          <ListGroupItem
            key={om.id}
            className="cursor-pointer"
            tag={Link}
            to={`/official-merch/${om.id}`}
            action
          >
            <Item
              id={om.id}
              user={om.user}
              createdAt={om.createdAt}
              image={om.image}
              status={om.status}
            />
          </ListGroupItem>
        ))}
      </ListGroup>
    </div>
  )
}

export default ArtistsRequests
