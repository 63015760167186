/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import {
  Badge,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  ListGroup,
  ListGroupItem,
  Row,
  Button,
  Card,
  CardHeader,
  CardBody,
} from 'reactstrap'
import Select from 'react-select'
import { numberToComma, omitDeep } from '../../utils'
import ColorPicker from '../ColorPicker'

const VariantEditor = ({
  onChange,
  variantKey,
  variantsSelected,
  variantsProps,
  views,
}) => {
  console.log({variantsProps});
  const [variantsWithOptions, setVariantsWithOptions] = useState(
    omitDeep(variantsProps, '__typename')
  )
  const [onlySelected, setOnlySelected] = useState(variantsSelected?.length > 0)
  const contrastOptions = ['white', 'black'].map((c) => ({
    value: c,
    label: c,
  }))

  let variantsWithOptionsToDisplay = variantsWithOptions.sort((a, b) => {
    if (
      variantsSelected?.includes(a.variant) &&
      !variantsSelected?.includes(b.variant)
    ) {
      return -1
    } else if (
      !variantsSelected?.includes(a.variant) &&
      variantsSelected?.includes(b.variant)
    ) {
      return 1
    }
    return 0
  })

  if (onlySelected && variantsSelected?.length > 0) {
    variantsWithOptionsToDisplay = variantsWithOptionsToDisplay.filter((v) =>
      variantsSelected?.includes(v.variant)
    )
  }

  const updateVariantProps = (newProps) => {
    setVariantsWithOptions(newProps)
    if (onChange) {
      onChange(newProps)
    }
  }

  return (
    <Container fluid className="pb-4 pt-2">
      <Row>
        <Col md={2} className="text-center">
          <h4>
            <Badge>{variantKey}</Badge>
          </h4>
        </Col>
        <Col md={{ size: 4, offset: 6 }} className="text-end">
          <Button
            size="sm"
            color={onlySelected ? 'primary' : 'secondary'}
            onClick={() => setOnlySelected(!onlySelected)}
          >
            {onlySelected ? 'Show all' : 'Only Selected'}
          </Button>
        </Col>
      </Row>
      <ListGroup>
        {variantsWithOptionsToDisplay?.map((vObj) => (
          <ListGroupItem
            key={vObj.variant}
            color={
              onlySelected
                ? ''
                : `${variantsSelected?.includes(vObj.variant) ? 'primary' : ''}`
            }
          >
            <Row>
              <Col md={2}>{vObj.variant}</Col>
              <Col>
                <Form>
                  <FormGroup row>
                    <Label for={`labelFieldVariant-${vObj.variant}`} sm={3}>
                      Label
                    </Label>
                    <Col>
                      <InputGroup>
                        <Input
                          id={`labelFieldVariant-${vObj.variant}`}
                          value={vObj?.label ?? ''}
                          onChange={(e) => {
                            const newVariantsWithOptions =
                              variantsWithOptions.map((v) => {
                                if (v.variant === vObj.variant) {
                                  return {
                                    ...v,
                                    label: e.target.value ?? '',
                                  }
                                }
                                return v
                              })

                            updateVariantProps(newVariantsWithOptions)
                          }}
                        />
                      </InputGroup>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for={`priceFieldVariant-${vObj.variant}`} sm={3}>
                      Price
                    </Label>
                    <Col>
                      <InputGroup>
                        <InputGroupText className="bg-secondary text-white">
                          {numberToComma(vObj.price)}
                        </InputGroupText>
                        <Input
                          id={`priceFieldVariant-${vObj.variant}`}
                          type="number"
                          value={vObj?.price ?? ''}
                          onChange={(e) => {
                            const newVariantsWithOptions =
                              variantsWithOptions.map((v) => {
                                if (v.variant === vObj.variant) {
                                  return {
                                    ...v,
                                    price: !isNaN(e.target.valueAsNumber)
                                      ? e.target.valueAsNumber
                                      : '',
                                  }
                                }
                                return v
                              })

                            updateVariantProps(newVariantsWithOptions)
                          }}
                        />
                      </InputGroup>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label sm={3}>Contrast</Label>
                    <Col>
                      <Select
                        isClearable
                        options={contrastOptions}
                        value={{
                          value: vObj?.contrast,
                          label: vObj?.contrast,
                        }}
                        onChange={(selected) => {
                          const newVariantsWithOptions =
                            variantsWithOptions.map((v) => {
                              if (v.variant === vObj.variant) {
                                return {
                                  ...v,
                                  contrast: selected ? selected.value : '',
                                }
                              }
                              return v
                            })

                          updateVariantProps(newVariantsWithOptions)
                        }}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for={`costFieldVariant-${vObj.variant}`} sm={3}>
                      Maddies Cost
                    </Label>
                    <Col>
                      <Input
                        id={`costFieldVariant-${vObj.variant}`}
                        type="number"
                        value={vObj?.maddiesCost ?? null}
                        onChange={(e) => {
                          const newVariantsWithOptions =
                            variantsWithOptions.map((v) => {
                              if (v.variant === vObj.variant) {
                                return {
                                  ...v,
                                  maddiesCost: !isNaN(e.target.valueAsNumber)
                                    ? e.target.valueAsNumber
                                    : null,
                                }
                              }
                              return v
                            })
                          updateVariantProps(newVariantsWithOptions)
                        }}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label
                      for={`colorValueFieldVariant-${vObj.variant}`}
                      sm={3}
                    >
                      Color Value
                    </Label>
                    <Col>
                      <ColorPicker
                        defaultColor={vObj?.colorValue}
                        onChange={(newColor) => {
                          const newVariantsWithOptions =
                            variantsWithOptions.map((v) => {
                              if (v.variant === vObj.variant) {
                                return {
                                  ...v,
                                  colorValue: newColor,
                                }
                              }
                              return v
                            })
                          updateVariantProps(newVariantsWithOptions)
                        }}
                      />
                    </Col>
                  </FormGroup>
                  {Object.keys(views ?? {})?.length > 0 && (
                    <Form>
                      <Row>
                        <Col md={3}>Views</Col>
                        <Col>
                          <Row>
                            {Object.keys(views ?? {})?.map((view) => {
                              return (
                                <Col key={view} xs={12} md={6}>
                                  <Card>
                                    <CardHeader>{view}</CardHeader>
                                    <CardBody>
                                      <FormGroup>
                                        <Label>Image</Label>
                                        <Input
                                          value={
                                            (vObj.views ?? {})[view]?.image ??
                                            ''
                                          }
                                          onChange={(e) => {
                                            const newVariantsWithOptions =
                                              variantsWithOptions.map((vOp) => {
                                                if (
                                                  vOp.variant === vObj.variant
                                                ) {
                                                  return {
                                                    ...vOp,
                                                    views: {
                                                      ...(vOp.views ?? {}),
                                                      [view]: {
                                                        ...(vOp.views ?? {})[
                                                          view
                                                        ],
                                                        id: view,
                                                        image: e.target.value,
                                                      },
                                                    },
                                                  }
                                                }
                                                return vOp
                                              })
                                            updateVariantProps(
                                              newVariantsWithOptions
                                            )
                                          }}
                                        />
                                      </FormGroup>
                                    </CardBody>
                                  </Card>
                                </Col>
                              )
                            })}
                          </Row>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Form>
              </Col>
            </Row>
          </ListGroupItem>
        ))}
      </ListGroup>
    </Container>
  )
}

export default VariantEditor
