import { AUTH_ERROR, AUTH_SUCCESS, VERIFY_SUCCESS } from './actions'

const INITIAL_STATE = {
  token: '',
  isLogged: false,
}

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AUTH_SUCCESS:
      return {
        ...state,
        isLogged: true,
        token: action.token,
        user: {
          username: action.username,
          type: action.userType,
        },
      }

    case VERIFY_SUCCESS:
      return {
        ...state,
        isLogged: true,
        user: {
          username: action.username,
          type: action.userType,
        }
      }

    case AUTH_ERROR:
      return INITIAL_STATE

    default:
      return state
  }
}

export default reducer
