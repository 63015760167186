import { gql } from '@apollo/client'

export const GET_COLLECTIONS = gql`
  query GetCollections($ids: [String], $page: Int, $limit: Int, $search: String) {
    collections(ids: $ids, page: $page, limit: $limit, search :$search) {
      id
      identifier
      initials
      contract
      sentToWordpress
      owner {
        id
        userName
        userId
      }
      ownerCommission
      linkSample
      token
      isActive
      createdAt
    }
  }
`

export const GET_TOTAL_COLLECTIONS = gql`
  query GetTotalCollections($search: String) {
    totalCollections(search: $search)
  }
`

export const GET_COLLECTION_BY_ID = gql`
  query GetCollectionById($id: String) {
    collectionById(id: $id) {
      id
      identifier
      initials
      chain
      owner {
        id
        userName
        userId
      }
      ownerCommission
      contract
      linkSample
      token
      slug
      sentToWordpress
      options {
        removeBackground
        addClearSuffix
      }
      isActive
      productSpecs {
        id
        productDef {
          id
          isArchived
        }
        adjustments {
          imageAdjustment {
            scale
            y
            x
            angle
          }
        }
      }
    }
  }
`

export const UPDATE_REMOVE_BACKGROUND = gql`
  mutation UpdateRemoveBackground(
    $removeBackground: Boolean!
    $collectionId: String!
  ) {
    updateRemoveBackground(
      removeBackground: $removeBackground
      collectionId: $collectionId
    ) {
      id
      options {
        removeBackground
      }
    }
  }
`

export const SEND_COLLECTION_TO_WP = gql`
  mutation SendCollectionToWP(
    $id: String
  ) {
    sendCollectionToWordpress(
      id: $id
    ) {
      id
      sentToWordpress
    }
  }
`

export const ADD_NEW_PRODUCT_SPEC = gql`
  mutation AddNewProductSpec(
    $productDef: String!
    $collectionId: String!
    $imageAdjustment: ImageAdjustmentInput
  ) {
    addNewProductSpec(
      productDef: $productDef
      collectionId: $collectionId
      imageAdjustment: $imageAdjustment
    ) {
      id
      productSpecs {
        id
        productDef {
          id
          name
        }
        adjustments {
          imageAdjustment {
            x
            y
            scale
            angle
          }
        }
      }
    }
  }
`

export const UPDATE_PRODUCT_SPEC = gql`
  mutation UpdateProductSpec(
    $productDef: String!
    $collectionId: String!
    $imageAdjustment: ImageAdjustmentInput
  ) {
    updateProductSpec(
      productDef: $productDef
      collectionId: $collectionId
      imageAdjustment: $imageAdjustment
    ) {
      id
      productSpecs {
        id
        productDef {
          id
          name
        }
        adjustments {
          imageAdjustment {
            x
            y
            scale
            angle
          }
        }
      }
    }
  }
`

export const DELETE_PRODUCT_SPEC = gql`
  mutation DeleteProductSpec($id: String!, $collectionId: String!) {
    deleteProductSpec(id: $id, collectionId: $collectionId)
  }
`

export const UPDATE_IS_ACTIVE = gql`
  mutation UpdateIsActive($collectionId: String!, $isActive: Boolean) {
    updateIsActive(collectionId: $collectionId, isActive: $isActive) {
      id
      isActive
    }
  }
`

export const ADD_NEW_COLLECTION = gql`
  mutation AddNewCollection(
    $identifier: String!
    $initials: String!
    $chain: String
    $owner: String
    $ownerCommission: Float
    $contract: String!
    $token: String!
    $slug: String
    $linkSample: String!
    $options: InputCollectionOptions
    $isActive: Boolean
    $copyFromCollection: String
  ) {
    addNewCollection(
      identifier: $identifier
      initials: $initials
      chain: $chain
      owner: $owner
      ownerCommission: $ownerCommission
      contract: $contract
      token: $token
      slug: $slug
      linkSample: $linkSample
      options: $options
      isActive: $isActive
      copyFromCollection: $copyFromCollection
    ) {
      id
      identifier
      initials
      chain
      owner {
        id
        userName
        userId
      }
      ownerCommission
      contract
      linkSample
      token
      isActive
      options {
        removeBackground
        useLinkProvided
      }
    }
  }
`

export const EDIT_COLLECTION = gql`
  mutation EditCollection(
    $id: String!
    $identifier: String!
    $initials: String!
    $chain: String
    $owner: String
    $ownerCommission: Float
    $contract: String!
    $token: String
    $slug: String
    $linkSample: String!
    $options: InputCollectionOptions
    $isActive: Boolean
    $copyFromCollection: String
  ) {
    editCollection(
      id: $id
      identifier: $identifier
      initials: $initials
      chain: $chain
      owner: $owner
      ownerCommission: $ownerCommission
      contract: $contract
      token: $token
      slug: $slug
      linkSample: $linkSample
      options: $options
      isActive: $isActive
      copyFromCollection: $copyFromCollection
    ) {
      id
      identifier
      initials
      slug
      contract
      linkSample
      token
      chain
      owner {
        id
        userName
        userId
      }
      ownerCommission
      isActive
      options {
        removeBackground
        useLinkProvided
      }
    }
  }
`
export const GET_MORALIS_METADATA = gql`
  query GetMoralisContractMetadata($moralisContractMetadataInput: MoralisContractMetadataInput) {
    moralisContractMetadata(moralisContractMetadataInput: $moralisContractMetadataInput) {
      name
      symbol
      synced_at
      token_address
      contract_type
    }
  }
`

export const GET_COLLECTIONS_FOR_SPEC_COPY = gql`
  query GetCollectionsForSpecCopy {
    collections {
      id
      identifier
    }
  }
`

export const DELETE_COLLECTION_BY_ID = gql`
  mutation DeleteCollection($id: String) {
    deleteCollection(id: $id)
  }
`
