import React, { useState } from 'react'
import './App.css'
import {
  Nav,
  NavLink as NavLinkBS,
  NavItem,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavbarText,
  Collapse,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'
import { connect } from 'react-redux'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Collection from '../Collection'
import NFTRequests from '../NFTRequest'
import CollectionViewer from '../Collection/viewer'
import Product from '../Product'
import LoginPage from '../User/login'
import { FiUser } from 'react-icons/fi'
import LogsViewer from '../../containers/LogsViewer'
import ProductViewer from '../Product/viewer'
import { useQuery } from '@apollo/client'
import { Switch, Route, NavLink, Redirect } from 'react-router-dom'
import logo from '../../assets/logo.svg'
import { VERIFY_SESSION } from '../../actions'
import ArtistsRequests from '../Artwork'
import OfficialMerch from '../OfficialMerch'
import OfficialMerchEditor from '../OfficialMerch/editor'
import ArtworkEditor from '../Artwork/ArtworkEditor'
import Updater from '../Updater'
import { authSuccess, verifySuccess } from '../User/actions'
import Packages from '../Packages'
import PackageViewer from '../Packages/viewer'
import OrderList from '../Order'
import CommissionList from '../Commission'
import OrderEditor from '../Order/editor'
import {
  isAdminGroup,
  isCommissionGroup,
  isSupportGroup,
} from '../../utils/permissions'
import PaymentsList from '../Commission/payments/list'
import CleanStoreButton from '../../components/CleanStoreButton'
import CollectionDowloader from '../Collection/downloader'

const HomePage = ({ onVerifySuccess, user }) => {
  const [navBarIsOpen, setNavBarIsOpen] = useState(false)

  const toggleNavbar = () => setNavBarIsOpen(!navBarIsOpen)

  const { error: errorVerifySession } = useQuery(VERIFY_SESSION, {
    pollInterval: 10 * 60 * 1000, // polling every 10min to check session
    fetchPolicy: 'network-only',
    onCompleted: ({ verify }) => {
      onVerifySuccess({ ...verify })
    },
    onError: () => {
      localStorage.removeItem('AUTH_TOKEN')
    },
  })

  if (errorVerifySession?.message === 'Unauthorized') {
    return <Redirect to="/login" />
  }

  return (
    <div className="App">
      <ToastContainer />
      <Navbar
        color="light"
        light
        expand="md"
        className="sticky-top border-bottom"
      >
        <NavbarBrand tag={NavLink} to="/">
          <img width="150em" alt="Maddies logo" src={logo} />
        </NavbarBrand>
        <NavbarToggler onClick={toggleNavbar} />
        <Collapse isOpen={navBarIsOpen} navbar>
          <Nav className="me-auto" navbar>
            {isSupportGroup(user?.type) && (
              <>
                <UncontrolledDropdown inNavbar nav>
                  <DropdownToggle caret nav>
                    Requests
                  </DropdownToggle>
                  <DropdownMenu end>
                    <DropdownItem tag={NavLink} to="/nft-requests">
                      NFTs
                    </DropdownItem>
                    <DropdownItem tag={NavLink} to="/official-merch">
                      Official Shop
                    </DropdownItem>
                    <DropdownItem tag={NavLink} to="/artwork">
                      Artworks
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <UncontrolledDropdown inNavbar nav>
                  <DropdownToggle caret nav>
                    Support
                  </DropdownToggle>
                  <DropdownMenu end>
                    <DropdownItem tag={NavLink} to="/collections">
                      Collections
                    </DropdownItem>
                    <DropdownItem tag={NavLink} to="/package">
                      Packages
                    </DropdownItem>
                    <DropdownItem tag={NavLink} to="/product-updater">
                      Updater
                    </DropdownItem>
                    <DropdownItem tag={NavLink} to="/downloader">
                      Downloader
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </>
            )}
            {isCommissionGroup(user?.type) && (
              <>
                <UncontrolledDropdown inNavbar nav>
                  <DropdownToggle caret nav>
                    Financial
                  </DropdownToggle>
                  <DropdownMenu end>
                    <DropdownItem tag={NavLink} to="/orders">
                      Orders
                    </DropdownItem>
                    <DropdownItem tag={NavLink} to="/commissions">
                      Commissions
                    </DropdownItem>
                    <DropdownItem tag={NavLink} to="/commission/payments">
                      Payments
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </>
            )}
            <NavItem>
              <NavLinkBS tag={NavLink} to="/products">
                Products
              </NavLinkBS>
            </NavItem>
            {isAdminGroup(user?.type) && (
              <NavItem>
                <NavLinkBS tag={NavLink} to="/logs-viewer">
                  Logs
                </NavLinkBS>
              </NavItem>
            )}
          </Nav>

          <NavbarText>
            <span className="d-flex gap-2 align-items-center">
              <CleanStoreButton />
              <span>
                <Button size="sm">
                  <FiUser /> {user?.username}
                </Button>
              </span>
            </span>
          </NavbarText>
        </Collapse>
      </Navbar>
      <Switch>
        <Route
          path="/commission/payments"
          exact
          render={() => {
            if (isCommissionGroup(user?.type)) {
              return (
                <div className="m-4">
                  <PaymentsList />
                </div>
              )
            }
          }}
        />
        <Route
          path="/collections"
          exact
          render={() => {
            if (isSupportGroup(user?.type)) {
              return (
                <div className="m-4">
                  <Collection />
                </div>
              )
            }
          }}
        />
        <Route
          path="/downloader"
          exact
          render={() => {
            if (isSupportGroup(user?.type)) {
              return (
                <div className="m-4">
                  <CollectionDowloader />
                </div>
              )
            }
          }}
        />
        <Route
          path="/nft-requests"
          exact
          render={() => {
            if (isSupportGroup(user?.type)) {
              return (
                <div className="m-4">
                  <NFTRequests />
                </div>
              )
            }
          }}
        />
        <Route
          path="/artwork"
          exact
          render={() => {
            if (isSupportGroup(user?.type)) {
              return (
                <div className="m-4">
                  <ArtistsRequests />
                </div>
              )
            }
          }}
        />
        <Route
          path="/artwork/:id"
          render={() => {
            if (isSupportGroup(user?.type)) {
              return (
                <div className="p-4">
                  <ArtworkEditor />
                </div>
              )
            }
          }}
        />
        <Route
          exact
          path="/official-merch"
          render={() => {
            if (isSupportGroup(user?.type)) {
              return (
                <div className="p-4">
                  <OfficialMerch />
                </div>
              )
            }
          }}
        />
        <Route
          path="/official-merch/:id"
          render={() => {
            if (isSupportGroup(user?.type)) {
              return (
                <div className="p-4">
                  <OfficialMerchEditor />
                </div>
              )
            }
          }}
        />
        <Route
          path="/collections/:id"
          render={() => {
            if (isSupportGroup(user?.type)) {
              return (
                <div className="m-4">
                  <CollectionViewer />
                </div>
              )
            }
          }}
        />

        <Route
          path="/package"
          exact
          render={() => {
            if (isSupportGroup(user?.type)) {
              return <Packages />
            }
          }}
        />

        <Route
          path="/package/:id"
          render={() => {
            if (isSupportGroup(user?.type)) {
              return <PackageViewer />
            }
          }}
        />

        <Route
          path="/orders"
          exact
          render={() => {
            if (isCommissionGroup(user?.type)) {
              return <OrderList />
            }
          }}
        />
        <Route
          path="/order/:id"
          exact
          render={() => {
            if (isCommissionGroup(user?.type)) {
              return <OrderEditor />
            }
          }}
        />

        <Route
          path="/commissions"
          exact
          render={() => {
            if (isCommissionGroup(user?.type)) {
              return <CommissionList />
            }
          }}
        />
        <Route path="/products" exact>
          <Product />
        </Route>
        <Route path="/products/:id">
          <ProductViewer />
        </Route>
        <Route
          path="/logs-viewer"
          render={() => {
            if (isAdminGroup(user?.type)) {
              return <LogsViewer />
            }
          }}
        />

        <Route
          path="/product-updater"
          render={() => {
            if (isSupportGroup(user?.type)) {
              return <Updater />
            }
          }}
        />
        <Route path="/" exact>
          <Redirect to="/collections" />
        </Route>

        <Route path="/login" exact>
          <LoginPage />
        </Route>
      </Switch>
    </div>
  )
}

const mapStateToProps = (state) => ({
  user: state.user.user,
  isLogged: state.user.isLogged,
})

const mapDispatchToProps = (dispatch) => ({
  onAuthSuccess: ({ token, username, type }) => {
    dispatch(authSuccess({ token, username, type }))
  },
  onVerifySuccess: ({ username, type }) => {
    dispatch(verifySuccess({ username, type }))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(HomePage)
