/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { useQuery, useMutation, gql } from '@apollo/client'
import Select from 'react-select'
import {
  Button,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
} from 'reactstrap'
import AppSplash from '../../components/AppSplash'
import { GET_PRODUCTS } from '../Product/actions'
import { ADD_NEW_PACKAGE, DELETE_PACKAGE, EDIT_PACKAGE } from './actions'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import Image from '../../components/Image'

export default function PackageEditor({
  id,
  name: nameProp = '',
  products: productsArg = [],
  isCreating = false,
  onPackageAdded,
  onPackageRemoved,
  onPackageEdited,
}) {
  const history = useHistory()
  const [name, setName] = useState(nameProp)
  const [products, setProducts] = useState(productsArg?.map((p) => p.id))

  const { loading, data: { products: productsFromDB } = {} } =
    useQuery(GET_PRODUCTS)

  const [deletePackage] = useMutation(DELETE_PACKAGE, {
    onCompleted: () => {
      toast.success(`Package ${name} removed!`)
      if (onPackageRemoved) {
        onPackageRemoved()
      }
      history.push('/package')
    },
    onError: (e) => {
      toast.error(`Error adding package. ${e.message}`)
    },
    update(cache) {
      const normalizedId = cache.identify({ id, __typename: 'Package' })
      cache.evict({ id: normalizedId })
      cache.gc()
    },
  })

  const [editPackage] = useMutation(EDIT_PACKAGE, {
    onCompleted: () => {
      toast.success(`Package ${name} edited!`)
      if (onPackageEdited) {
        onPackageEdited()
      }
    },
    onError: (e) => {
      toast.error(`Error editing package. ${e.message}`)
    },
  })

  const [addNewPackage] = useMutation(ADD_NEW_PACKAGE, {
    onCompleted: () => {
      toast.success(`Package ${name} created!`)
      if (onPackageAdded) {
        onPackageAdded()
      }
    },
    onError: (e) => {
      toast.error(`Error adding package. ${e.message}`)
    },
    update(cache, { data: { addNewPackage } }) {
      cache.modify({
        fields: {
          packages(existingPackages = []) {
            const newPackageRef = cache.writeFragment({
              data: addNewPackage,
              fragment: gql`
                fragment NewPackage on Package {
                  id
                  name
                  products {
                    id
                    name
                    image
                  }
                }
              `,
            })
            return [...existingPackages, newPackageRef]
          },
        },
      })
    },
  })

  if (loading) {
    return <AppSplash />
  }

  const productsOptions = productsFromDB?.map((p) => ({
    value: p.id,
    label: (
      <div className="d-flex">
        
          <div style={{ maxWidth: '3em', marginRight: '0.5em' }}>
            <Image src={p.image} />
          </div>
        
        <div className="pt-2">{p.name}</div>
      </div>
    ),
  }))

  const productsValue = products.map((pId) =>
    productsOptions?.find((p) => p.value === pId)
  )

  return (
    <Container className="mt-2 mb-2">
      <Form inline>
        <FormGroup>
          <Label for="nameField">Name</Label>
          <Input
            id="nameField"
            value={name}
            onChange={({ target: { value } }) => setName(value)}
          />
        </FormGroup>{' '}
        <FormGroup>
          <Label for="nameField">Products</Label>
          <Select
            isMulti
            value={productsValue}
            options={productsOptions}
            closeMenuOnSelect={false}
            onChange={(selected) => {
              setProducts(selected?.map((p) => p.value))
            }}
          />
        </FormGroup>{' '}
      </Form>
      <hr />
      <Button
        color="primary"
        className="float-end"
        onClick={() => {
          if (isCreating) {
            addNewPackage({
              variables: {
                name,
                products,
              },
            })
          } else {
            editPackage({
              variables: {
                id,
                name,
                products,
              },
            })
          }
        }}
      >
        {isCreating ? 'Create' : 'Save'}
      </Button>
      {!isCreating && (
        <Button
          color="danger"
          onClick={() => {
            if (
              window.confirm(`Do you want to remove the package '${name}'?`)
            ) {
              deletePackage({ variables: { id } })
            }
          }}
        >
          Remove
        </Button>
      )}
    </Container>
  )
}
