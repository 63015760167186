/* eslint-disable react/prop-types */
import { useMutation, useQuery } from '@apollo/client'
import axios from 'axios'
import React, { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import Select from 'react-select'
import { toast } from 'react-toastify'
import {
  Alert,
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from 'reactstrap'
import AppSplash from '../../components/AppSplash'
import {
  OFFICIAL_MERCH_ADD_NEW_PRODUCT_SPEC,
  OFFICIAL_MERCH_DELETE_PRODUCT_SPEC,
  OFFICIAL_MERCH_UPDATE_PRODUCT_SPEC,
  EDIT_OFFICIAL_MERCH,
  GET_OFFICIAL_MERCHS_ONLY_IMAGES,
  COPY_OFFICIAL_MERCH_ADJUSTMENTS,
} from './actions'
import ProductSpecEditor from '../Product/ProductSpecEditor'
import { GET_OFFICIAL_MERCH } from './actions'
import Image from '../../components/Image'
import { Status } from '../../utils/status'
import Confirm from '../../components/Confirm'
import { FaRocket } from 'react-icons/fa'
import { BiTestTube } from 'react-icons/bi'
import { SpecSpinner } from '../Collection/viewer'
import { GET_PRODUCTS } from '../Product/actions'
import { connect } from 'react-redux'

const OfficialMerchEditor = () => {
  const { id } = useParams()
  const [collection, setCollection] = useState()
  const [user, setUser] = useState()
  const [openseaLink, setOpenseaLink] = useState('')
  const [contract, setContract] = useState('')
  const [websiteLink, setWebsiteLink] = useState('')
  const [image, setImage] = useState([])
  const [status, setStatus] = useState(Status.ONHOLD)
  const [shopId, setShopId] = useState('')
  const [description, setDescription] = useState('')
  const [skipProducts, setSkipProducts] = useState([])
  const [addProducts, setAddProducts] = useState([])
  const [updater, setUpdater] = useState(false)
  const [selectedOfficialMerchToCopy, setSelectedOfficialMerchToCopy] =
    useState()
  const [copyAdjustmentsIsOpen, setCopyAdjustmentsIsOpen] = useState(false)

  const [pack, setPackage] = useState()
  const [
    createProductsOnProductionConfirmIsOpen,
    setCreateProductsOnProductionConfirmIsOpen,
  ] = useState(false)

  const { loading, error, data } = useQuery(GET_OFFICIAL_MERCH, {
    variables: { id },
    onCompleted: ({ officialMerch }) => {
      setCollection(officialMerch.nftCollection)
      setUser(officialMerch.user)
      setOpenseaLink(officialMerch.openseaLink)
      setContract(officialMerch.contract)
      setWebsiteLink(officialMerch.websiteLink)
      setImage(officialMerch.image)
      setStatus(officialMerch.status)
      setShopId(officialMerch.shopId)
      setDescription(officialMerch.description)
      setSkipProducts(officialMerch.skipProducts)
      setAddProducts(officialMerch.addProducts)
      setPackage(officialMerch.package)
    },
    fetchPolicy: 'network-only',
  })

  const {
    loading: loadingProductsToAdd,
    data: { products: productsToAdd } = {},
  } = useQuery(GET_PRODUCTS, {
    variables: {
      notInPackage: pack?.id,
    },
  })

  const {
    data: { officialMerchs } = {},
    loading: loadingOfficialMerchsToCopy,
  } = useQuery(GET_OFFICIAL_MERCHS_ONLY_IMAGES, {
    variables: {
      package: pack?.id,
    },
  })

  const [addNewSpec, { loading: isSavingSpec }] = useMutation(
    OFFICIAL_MERCH_ADD_NEW_PRODUCT_SPEC,
    {
      onCompleted: () => {
        toast.success('Adjustment created!')
      },
    }
  )

  const [updateSpec, { loading: isUpdatingSpec }] = useMutation(
    OFFICIAL_MERCH_UPDATE_PRODUCT_SPEC,
    {
      onCompleted: () => {
        toast.success('Adjustment updated!')
      },
    }
  )

  const [updateOfficialMerch, { loading: updatingOfficialMerch }] = useMutation(
    EDIT_OFFICIAL_MERCH,
    {
      onCompleted: () => {
        toast.success('Official Merch saved successfully!')
      },
      onError: (error) => {
        toast.error(`Error saving official merch. ${error.message}`)
      },
    }
  )

  const [deleteSpec, { loading: isDeletingSpec }] = useMutation(
    OFFICIAL_MERCH_DELETE_PRODUCT_SPEC,
    {
      onCompleted: () => {
        setUpdater(!updater)
        toast.success('Adjustment removed!')
      },
      update(cache, { data: { officialMerchDeleteProductSpec } }) {
        const normalizedId = cache.identify({
          id: officialMerchDeleteProductSpec,
          __typename: 'ProductSpec',
        })
        console.log({ normalizedId })
        cache.evict({ id: normalizedId })
        cache.gc()
      },
    }
  )

  const [
    copyOfficialMerchAdjustments,
    { loading: isCopyingOfficialMerchAdjustments },
  ] = useMutation(COPY_OFFICIAL_MERCH_ADJUSTMENTS, {
    onCompleted: () => {
      setUpdater(!updater)
      toast.success('Adjustment copied!')
    },
  })

  if (loading) {
    return <AppSplash />
  }

  if (error) {
    return (
      <Alert color="danger">
        Error loading data from server :( ${error.message}
      </Alert>
    )
  }

  const statusOptions = [
    { value: 'error', label: 'Error' },
    { value: 'onhold', label: 'On Hold' },
    { value: 'inprogress', label: 'In Progress' },
    { value: 'creating', label: 'Creating' },
    { value: 'invalid', label: 'Invalid' },
    { value: 'duplicated', label: 'Duplicated' },
    { value: 'done', label: 'Done' },
  ]

  const testProduct = ({ productDef, imageAdjustment }) => {
    const data = {
      officialMerchId: id,
      isStaging: true,
      userName: 'staging-api',
      userId: '1',
    }

    if (productDef) {
      data.productFilter = productDef
    }

    if (imageAdjustment) {
      data.adjustmentsFilter = { imageAdjustment }
    }

    axios
      .post(
        // eslint-disable-next-line no-undef
        process.env.REACT_APP_API_URL + '/official-merch/products/create',
        data
      )
      .then(() => toast.success(`Request sent successfully!`))
      .catch((e) => toast.error(`Error creating products. ${e.message}`))
  }

  const createProductionProducts = ({ officialMerchId }) => {
    const data = { officialMerchId }

    axios
      .post(
        // eslint-disable-next-line no-undef
        process.env.REACT_APP_API_URL + '/official-merch/products/create',
        data
      )
      .then(() => toast.success(`Request sent successfully!`))
      .catch((e) => toast.error(`Error creating products. ${e.message}`))
  }

  const products = pack?.products || []

  const productOptions = products?.map((p) => ({
    value: p.id,
    label: (
      <div className="d-flex">
        <div style={{ maxWidth: '3em', marginRight: '0.5em' }}>
          <Image src={p.image} />
        </div>

        <div className="pt-2">{p.name}</div>
      </div>
    ),
  }))

  const userInfo = `${user?.userId} - ${user?.userName}`

  const { productSpecs } = data.officialMerch

  const toggleCreateProductsOnProductionConfirm = () =>
    setCreateProductsOnProductionConfirmIsOpen(
      !createProductsOnProductionConfirmIsOpen
    )

  const toggleCopyAdjustmentsConfirm = () =>
    setCopyAdjustmentsIsOpen(!copyAdjustmentsIsOpen)

  const OmItem = ({ image, status }) => (
    <div className="d-flex">
      <div style={{ maxWidth: '3em', marginRight: '0.5em' }}>
        <Image
          src={image}
          lowRes
          resizeOptions={{ height: 32 }}
          blackBackground
        />
      </div>

      <div className="pt-2 text-dark">{status}</div>
    </div>
  )

  const officialMerchsOptionsToCopy = officialMerchs
    ?.filter((om) => om.id !== id)
    .map((om) => ({
      value: om,
      label: <OmItem image={om?.image} status={om?.status} />,
    }))

  const productsToAddOptions = productsToAdd?.map((p) => ({
    value: p?.id,
    label: (
      <div className="d-flex">
        <div style={{ maxWidth: '3em', marginRight: '0.5em' }}>
          <Image src={p?.image} />
        </div>

        <div className="pt-2 text-dark">{p?.name}</div>
      </div>
    ),
  }))

  const websiteWithHttp = !/^https?:\/\//i.test(websiteLink)
    ? `http://${websiteLink}`
    : websiteLink

  return (
    <div>
      <Row>
        <Confirm
          isOpen={createProductsOnProductionConfirmIsOpen}
          message="Do you really want to create all the products on the PRODUCTION store?"
          toggle={toggleCreateProductsOnProductionConfirm}
          onYesClicked={() =>
            createProductionProducts({
              officialMerchId: id,
            })
          }
        />
        <Confirm
          isOpen={copyAdjustmentsIsOpen}
          message="Do you really want to copy the adjustments. This will overwrite the current adjustments?"
          toggle={toggleCopyAdjustmentsConfirm}
          onYesClicked={() =>
            copyOfficialMerchAdjustments({
              variables: {
                id,
                copyFrom: selectedOfficialMerchToCopy?.id,
              },
            })
          }
        />
        {(isUpdatingSpec || isSavingSpec || isDeletingSpec) && <SpecSpinner />}
        <Col md={2}>
          <Row>
            <Col className="d-flex justify-content-center">
              <div style={{ maxWidth: '10em' }}>
                <Image blackBackground src={image} />
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <Button block onClick={toggleCreateProductsOnProductionConfirm}>
                <FaRocket /> Create Products
              </Button>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col>
              <Button block onClick={() => testProduct({})} color="primary">
                <BiTestTube /> Test Products
              </Button>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col>
              <Form>
                <FormGroup row>
                  <Label sm={2}>User</Label>
                  <Col sm={10}>
                    <Input disabled value={userInfo} />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm={2}>Shop ID</Label>
                  <Col sm={10}>
                    <Input disabled value={shopId} />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm={2}>Description</Label>
                  <Col sm={10}>
                    <Input
                      disabled
                      type="textarea"
                      style={{ minHeight: '7em' }}
                      value={description?.replace(/\\\\n/g, '\n')}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm={2}>Image Name</Label>
                  <Col sm={10}>
                    <Input
                      disabled
                      value={data?.officialMerch?.imageName ?? ''}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm={2}>Collection</Label>
                  <Col sm={10}>
                    {collection ? (
                      <Link to={`/collections/${collection?.id}`}>
                        <Input disabled value={collection?.identifier} />
                      </Link>
                    ) : (
                      <span>{'No collection selected'}</span>
                    )}
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm={2}>Package</Label>
                  <Col sm={10}>
                    {pack ? (
                      <Link
                        to={`/package/${pack?.id}`}
                        className="cursor-pointer"
                      >
                        <Input disabled value={pack?.name} />
                      </Link>
                    ) : (
                      <span>{'No package selected'}</span>
                    )}
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm={2}>Opensea</Label>
                  <Col sm={10}>
                    <a href={openseaLink} target="blank">
                      <Input disabled value={openseaLink} />
                    </a>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm={2}>Contract</Label>
                  <Col sm={10}>
                    <Input disabled value={contract} />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm={2}>Website</Label>
                  <Col sm={10}>
                    <a href={websiteWithHttp} target="blank">
                      <Input disabled value={websiteLink} />
                    </a>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label sm={2}>Status</Label>
                  <Col sm={10}>
                    <Select
                      value={statusOptions.find((so) => so.value === status)}
                      options={statusOptions}
                      onChange={(selected) => {
                        setStatus(selected.value)
                      }}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="exampleSelect" sm={2}>
                    Skip Products
                  </Label>
                  <Col sm={10}>
                    <Select
                      value={skipProducts?.map((spId) =>
                        productOptions?.find((p) => p.value === spId)
                      )}
                      isMulti
                      options={productOptions}
                      onChange={(selected) => {
                        const newSkipProducts = selected?.map((sv) => sv.value)
                        setSkipProducts(newSkipProducts)
                      }}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="addProductsToPackage" sm={2}>
                    Add Products
                  </Label>
                  <Col sm={10}>
                    <Select
                      value={addProducts?.map((spId) =>
                        productsToAddOptions?.find((p) => p.value === spId)
                      )}
                      isMulti
                      isLoading={loadingProductsToAdd}
                      options={productsToAddOptions}
                      onChange={(selected) => {
                        const newAddProducts = selected?.map((sv) => sv.value)
                        setAddProducts(newAddProducts)
                      }}
                    />
                  </Col>
                </FormGroup>
                <Row>
                  <Col xs={12} md={2}>
                    <Button block onClick={toggleCopyAdjustmentsConfirm}>
                      Copy adjustments{' '}
                      {isCopyingOfficialMerchAdjustments && (
                        <Spinner size="sm" />
                      )}
                    </Button>
                  </Col>
                  <Col xs={12} md={2}>
                    <Select
                      isLoading={loadingOfficialMerchsToCopy}
                      options={officialMerchsOptionsToCopy}
                      value={
                        selectedOfficialMerchToCopy && {
                          value: selectedOfficialMerchToCopy,
                          label: (
                            <OmItem
                              image={selectedOfficialMerchToCopy?.image}
                              status={selectedOfficialMerchToCopy?.status}
                            />
                          ),
                        }
                      }
                      onChange={(selected) => {
                        setSelectedOfficialMerchToCopy(selected.value)
                      }}
                    />
                  </Col>
                  <Col xs={12} md={{ size: 2, offset: 6 }}>
                    <Button
                      color="primary"
                      className="float-end"
                      onClick={() => {
                        updateOfficialMerch({
                          variables: {
                            id,
                            skipProducts,
                            addProducts,
                            status,
                          },
                        })
                      }}
                    >
                      {updatingOfficialMerch && <Spinner size="sm" />} Save
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
      <hr />
      {!isCopyingOfficialMerchAdjustments && !updatingOfficialMerch && (
        <ProductSpecEditor
          officialMerchId={id}
          packageId={pack?.id}
          testProductWithAdjustment={testProduct}
          productSpecs={productSpecs}
          addNewSpec={({ productDef, imageAdjustment }) => {
            addNewSpec({
              variables: {
                productDef,
                imageAdjustment,
                officialMerchId: id,
              },
            })
          }}
          updateSpec={({ productDef, imageAdjustment }) => {
            updateSpec({
              variables: {
                productDef,
                imageAdjustment,
                officialMerchId: id,
              },
            })
          }}
          deleteSpec={({ id: specId }) => {
            deleteSpec({
              variables: {
                officialMerchId: id,
                id: specId,
              },
            })
          }}
        />
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  userInSessionType: state?.user?.user?.type,
})

export default connect(mapStateToProps)(OfficialMerchEditor)
