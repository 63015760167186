/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react'
import {
  Col,
  FormGroup,
  Label,
  Input,
  Card,
  CardBody,
  InputGroup,
  InputGroupText,
  UncontrolledTooltip,
  ListGroup,
  CardHeader,
  Badge,
  Button,
  Modal,
  Row,
} from 'reactstrap'
import { isEqual, map, omit } from 'lodash'
import { useLazyQuery } from '@apollo/client'
import Select from 'react-select'
import { IoSettingsSharp } from 'react-icons/io5'
import { BsFillCircleFill } from 'react-icons/bs'
import { FaInfoCircle, FaCheck, FaDollarSign } from 'react-icons/fa'
import { numberToComma } from '../../utils'
import { toast } from 'react-toastify'
import ImgAdjustmentEditor from '../../containers/Product/ImgAdjustmentEditor'

import { GET_BLUEPRINT_PROVIDERS, GET_PRODUCT_VARIANTS } from './actions'
import VariantEditor from './VariantEditor'
import ReactTooltip from 'react-tooltip'
import { generateVariantOptions } from '../../utils/variants'

const PrintifyInfo = ({
  price,
  maddiesCost,
  blueprintId,
  provider,
  onInfoChange,
  imageAdjustment,
  variants,
  variantsProps,
  variantsPropsKey,
}) => {
  const defaultImageAdjustment = {
    scale: null,
    y: null,
    x: null,
    angle: null,
  }

  const [variantKeyToSetup, setVariantKeyToSetup] = useState()
  const [variantEditorIsOpen, setVariantEditorIsOpen] = useState(false)
  const variantsPropsOriginal = Object.fromEntries(
    Object.entries(omit(variantsProps ?? {}, ['__typename'])).map(
      ([key, value]) => [key, value?.map((v) => omit(v, ['__typename']))]
    )
  )
  const [printifyInfoState, setPrintifyInfoState] = useState({
    price,
    maddiesCost,
    blueprint_id: blueprintId,
    provider,
    variants: omit(variants ?? {}, ['__typename']),
    imageAdjustment: imageAdjustment
      ? omit(imageAdjustment, ['__typename'])
      : defaultImageAdjustment,
    variantsProps: variantsPropsOriginal,
    variantsPropsKey,
  })

  const [providerOptions, setProviderOptions] = useState([])

  const [getBlueprintProviders, { loading: loadingProviders }] = useLazyQuery(
    GET_BLUEPRINT_PROVIDERS,
    {
      onCompleted: ({ blueprintProviders }) => {
        const newProvidersOptions = blueprintProviders
          ? blueprintProviders.map((provider) => ({
              value: provider.id,
              label: provider.title,
            }))
          : []

        setProviderOptions(newProvidersOptions)

        if (
          printifyInfoState.provider &&
          !blueprintProviders.find((p) => p.id === printifyInfoState.provider)
        ) {
          toast.warn(
            `We haven't found the provider ${printifyInfoState.provider} on the list of possible providers. Please review`,
            { autoClose: false }
          )
        }

        if (newProvidersOptions && newProvidersOptions.length === 1) {
          setPrintifyInfoState({
            ...printifyInfoState,
            provider: newProvidersOptions[0].value,
          })
        }
      },
    }
  )

  const [variantsOptions, setVariantsOptions] = useState([])

  const [getProductVariants, { loading: loadingVariants }] = useLazyQuery(
    GET_PRODUCT_VARIANTS,
    {
      onCompleted: ({ productVariants }) => {
        const variantsLoaded = productVariants.variants || []

        const variantOptions = generateVariantOptions(variantsLoaded)
        setVariantsOptions(variantOptions)
      },
    }
  )

  useEffect(() => {
    const blueprintTimeOutId = setTimeout(() => {
      getBlueprintProviders({
        variables: {
          blueprintId: printifyInfoState.blueprint_id,
        },
      })
    }, 1000)
    return () => clearTimeout(blueprintTimeOutId)
  }, [printifyInfoState.blueprint_id, getBlueprintProviders])

  useEffect(() => {
    const productVariantsTimeOutId = setTimeout(() => {
      getProductVariants({
        variables: {
          blueprintId: printifyInfoState.blueprint_id,
          provider: printifyInfoState.provider,
        },
      })
    }, 1000)
    return () => clearTimeout(productVariantsTimeOutId)
  }, [
    printifyInfoState.blueprint_id,
    printifyInfoState.provider,
    getProductVariants,
  ])

  useEffect(() => {
    if (onInfoChange) {
      onInfoChange(printifyInfoState)
    }
  }, [printifyInfoState, onInfoChange])

  const imageAdjustmentValue =
    printifyInfoState.imageAdjustment || defaultImageAdjustment

  const toggleVariantEditor = () => setVariantEditorIsOpen(!variantEditorIsOpen)

  const getVariantProps = (vKey) => {
    const variantPropsLoaded = printifyInfoState.variantsProps
      ? printifyInfoState.variantsProps[vKey]
      : []

    let possibleVariantProps =
      variantsOptions[vKey]?.map((vItem) => ({
        variant: vItem,
        price: '',
        contrast: '',
      })) ?? []

    possibleVariantProps = possibleVariantProps?.map((vProp) => {
      const props = variantPropsLoaded?.find(
        (vLoaded) => vLoaded.variant === vProp.variant
      )
      if (props) {
        return props
      }
      return vProp
    })

    return possibleVariantProps
  }

  const getVariantLabel = (vKey, variant) => {
    if (printifyInfoState?.variantsProps) {
      const vKeyProps = printifyInfoState?.variantsProps[vKey]
      const variantProp = vKeyProps?.find((p) => p.variant === variant)
      if (!vKeyProps || !variantProp || !variantProp.price) {
        return variant
      }
      return `${variant} - $${numberToComma(variantProp.price)}`
    }
  }

  const getVariantPricesBadges = (vKey) => {
    if (printifyInfoState?.variantsProps) {
      const vKeyProps = printifyInfoState?.variantsProps[vKey]
      return vKeyProps
        ?.filter((p) => p.price)
        .map((p) => (
          <span key={p.variant}>
            <Badge>{`${p.variant} - $${numberToComma(p.price)}`}</Badge>{' '}
          </span>
        ))
    }
  }

  return (
    <Card>
      <Modal isOpen={variantEditorIsOpen} toggle={toggleVariantEditor}>
        <VariantEditor
          generalPrice={printifyInfoState?.price}
          variantKey={variantKeyToSetup}
          variantsSelected={printifyInfoState?.variants[variantKeyToSetup]}
          variantsProps={getVariantProps(variantKeyToSetup)}
          onChange={(variantProps) => {
            const newState = {
              ...printifyInfoState,
              variantsProps: {
                ...printifyInfoState.variantsProps,
                [variantKeyToSetup]: variantProps.map((vObj) => ({
                  ...vObj,
                  price: typeof vObj.price === 'number' ? vObj.price : null,
                })),
              },
            }
            setPrintifyInfoState(newState)
            if (onInfoChange) {
              onInfoChange(newState)
            }
          }}
        />
      </Modal>
      <CardHeader>Printify Info</CardHeader>
      <CardBody>
        <FormGroup row>
          <Label for="priceField" sm={2}>
            Price
          </Label>
          <Col sm={10}>
            <InputGroup>
              <InputGroupText className="bg-secondary text-white">
                {numberToComma(printifyInfoState.price)}
              </InputGroupText>
              <Input
                id="priceField"
                onChange={(e) => {
                  const newState = {
                    ...printifyInfoState,
                    price: parseInt((e.target.value + '').replace(/\b0+/g, '')),
                  }

                  setPrintifyInfoState(newState)
                }}
                value={printifyInfoState.price || ''}
              />
            </InputGroup>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="maddiesCostField" sm={2}>
            Maddies Cost
          </Label>
          <Col sm={10}>
            <Input
              id="maddiesCostField"
              onChange={(e) => {
                const newState = {
                  ...printifyInfoState,
                  maddiesCost: e.target.valueAsNumber,
                }

                setPrintifyInfoState(newState)
              }}
              type="number"
              value={printifyInfoState.maddiesCost || ''}
            />
          </Col>
        </FormGroup>

        <FormGroup row>
          <UncontrolledTooltip target="blueprintLabelField">
            Printify blueprint id
          </UncontrolledTooltip>
          <Label id="blueprintLabelField" for="blueprintField" sm={2}>
            Product ID
          </Label>
          <Col sm={10}>
            <Input
              id="blueprintField"
              type="number"
              onWheel={(e) => {
                e.target.blur()
              }}
              onChange={(e) => {
                const newBlueprintValue = e.target.value

                const newState = {
                  ...printifyInfoState,
                  blueprint_id: parseInt(newBlueprintValue),
                }

                if (onInfoChange) {
                  onInfoChange(newState)
                }
                setPrintifyInfoState(newState)
              }}
              value={printifyInfoState.blueprint_id || ''}
            />
          </Col>
        </FormGroup>
        {providerOptions && (
          <FormGroup row>
            <Label for="providerField" sm={2}>
              Provider
            </Label>
            <Col sm={10}>
              <Select
                id="providerField"
                options={providerOptions}
                value={
                  printifyInfoState.provider
                    ? providerOptions.find(
                        (p) => p.value === printifyInfoState.provider
                      )
                    : null
                }
                onChange={(selected) => {
                  const newState = {
                    ...printifyInfoState,
                    provider: selected ? selected.value : null,
                  }

                  setPrintifyInfoState(newState)
                }}
                isLoading={loadingProviders}
                isClearable
              />
            </Col>
          </FormGroup>
        )}

        {Object.keys(variantsOptions).map((variantKey) => (
          <FormGroup row key={variantKey}>
            <Label for="variantsIndexField" sm={2}>
              {variantKey} <Badge>{variantsOptions[variantKey]?.length}</Badge>
            </Label>
            <Col sm={8}>
              <Row>
                <Col>
                  <Select
                    styles={{
                      multiValue: (styles, { data }) => {
                        const result = {
                          ...styles,
                        }
                        if (!variantsOptions[variantKey].includes(data.value)) {
                          result.backgroundColor = 'rgba(247, 88, 59, 0.63)'
                        }
                        return result
                      },
                    }}
                    options={variantsOptions[variantKey].map((o) => ({
                      value: o,
                      label: getVariantLabel(variantKey, o),
                    }))}
                    isClearable
                    isMulti
                    closeMenuOnSelect={false}
                    isLoading={loadingVariants}
                    onChange={(selected) => {
                      const newState = {
                        ...printifyInfoState,
                        variants: {
                          ...printifyInfoState.variants,
                          [variantKey]: selected.map((s) => s.value),
                        },
                      }

                      if (onInfoChange) {
                        onInfoChange(newState)
                      }
                      setPrintifyInfoState(newState)
                    }}
                    value={
                      Array.isArray(printifyInfoState.variants[variantKey])
                        ? printifyInfoState.variants[variantKey].map((v) => ({
                            value: v,
                            label: getVariantLabel(variantKey, v),
                          }))
                        : []
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  {printifyInfoState?.variants &&
                    printifyInfoState?.variants[variantKey]?.length > 0 &&
                    getVariantPricesBadges(variantKey)?.length > 0 && (
                      <span>
                        <ReactTooltip
                          id={`${variantKey}-tooltip-var-props-badges`}
                          effect="solid"
                        >
                          When variants are selected the properties
                          <div>for the ones not selected are ignored.</div>
                        </ReactTooltip>
                        <FaInfoCircle
                          className="text-info"
                          data-tip=""
                          data-for={`${variantKey}-tooltip-var-props-badges`}
                        />{' '}
                      </span>
                    )}
                  {getVariantPricesBadges(variantKey)}
                </Col>
              </Row>
            </Col>
            <Col>
              <ReactTooltip id={`configure-variant-props-button-${variantKey}`}>
                Configure prices per variant
              </ReactTooltip>
              <Button
                data-tip=""
                data-for={`configure-variant-props-button-${variantKey}`}
                block
                onClick={() => {
                  setVariantKeyToSetup(variantKey)
                  toggleVariantEditor()
                }}
              >
                <IoSettingsSharp />{' '}
                {!isEqual(
                  printifyInfoState?.variantsProps[variantKey] ?? [],
                  (variantsPropsOriginal ?? {})[variantKey] ?? []
                ) && (
                  <span className="text-warning">
                    <BsFillCircleFill />
                  </span>
                )}
              </Button>
            </Col>
            <Col>
              <ReactTooltip
                id={`variant-checkbox-${variantKey}`}
                effect="solid"
                className="text-center"
              >
                Use this variant type to define prices.
              </ReactTooltip>
              <Button
                outline={variantKey !== printifyInfoState?.variantsPropsKey}
                color={
                  printifyInfoState?.variantsPropsKey === variantKey
                    ? 'success'
                    : 'secondary'
                }
                onClick={() => {
                  setPrintifyInfoState({
                    ...printifyInfoState,
                    variantsPropsKey: variantKey,
                  })
                }}
                block
                data-tip=""
                data-for={`variant-checkbox-${variantKey}`}
              >
                <FaDollarSign /> <FaCheck />
              </Button>
            </Col>
          </FormGroup>
        ))}
        <FormGroup>
          <Label>Default Image Adjustments</Label>
          <ListGroup>
            {map(imageAdjustmentValue, (value, key) => (
              <ImgAdjustmentEditor
                key={key}
                propValue={value}
                propKey={key}
                onValueChange={(e) => {
                  const newState = {
                    ...printifyInfoState,
                    imageAdjustment: {
                      ...(printifyInfoState.imageAdjustment ||
                        defaultImageAdjustment),
                      [key]:
                        e.target.value && !isNaN(e.target.value)
                          ? parseFloat(e.target.value)
                          : '',
                    },
                  }
                  setPrintifyInfoState(newState)
                }}
              />
            ))}
          </ListGroup>
        </FormGroup>
      </CardBody>
    </Card>
  )
}

export default PrintifyInfo
