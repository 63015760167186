import { gql } from '@apollo/client'
export const AUTHENTICATE = gql`
  mutation Authenticate($username: String!, $password: String!) {
    authenticate(username: $username, password: $password) {
      id
      username
      type
      token
    }
  }
`

export const AUTH_SUCCESS = 'AUTH_SUCCESS'

export const authSuccess = ({ token, username, type }) => ({
  type: AUTH_SUCCESS,
  token,
  username,
  userType: type,
})

export const AUTH_ERROR = 'AUTH_ERROR'

export const authError = () => ({
  type: AUTH_ERROR,
})

export const VERIFY_SUCCESS = 'VERIFY_SUCCESS'

export const verifySuccess = ({ username, type }) => ({
  type: VERIFY_SUCCESS,
  username,
  userType: type,
})
