/* eslint-disable react/prop-types */
import { omit } from 'lodash'
import React, { useEffect, useState } from 'react'
import {
  Card,
  CardHeader,
  CardBody,
  Table,
  Input,
  InputGroup,
  InputGroupText,
} from 'reactstrap'

const ShippingPricesEditor = ({ defaultShippingPrices = {}, onChange }) => {
  const [shippingPrices, setShippingPrices] = useState({
    us: {},
    canada: {},
    others: {},
    ...omit(defaultShippingPrices, ['__typename']),
  })

  const titles = {
    us: 'United States',
    canada: 'Canada',
    others: 'Rest of World',
  }

  useEffect(() => {
    if (onChange) {
      onChange(shippingPrices)
    }
  }, [shippingPrices, onChange])

  return (
    <Card className="p-0">
      <CardHeader>Shipping Prices</CardHeader>
      <CardBody>
        <Table>
          <thead>
            <tr>
              <th>Shipping to</th>
              <th>First Item</th>
              <th>Additional Item</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(shippingPrices).map((country) => {
              const sp = shippingPrices[country]
              return (
                <tr key={country}>
                  <td>{titles[country]}</td>
                  <td>
                    <InputGroup>
                      <InputGroupText className="bg-secondary text-white">
                        $
                      </InputGroupText>
                      <Input
                        type="number"
                        value={sp.firstItem ?? null}
                        onChange={(e) => {
                          setShippingPrices((value) => ({
                            ...value,
                            [country]: {
                              additionalItem: value[country].additionalItem,
                              firstItem: e.target.valueAsNumber,
                            },
                          }))
                        }}
                      />
                    </InputGroup>
                  </td>
                  <td>
                    <InputGroup>
                      <InputGroupText className="bg-secondary text-white">
                        $
                      </InputGroupText>
                      <Input
                        type="number"
                        value={sp.additionalItem ?? null}
                        onChange={(e) =>
                          setShippingPrices((value) => ({
                            ...value,
                            [country]: {
                              firstItem: value[country].firstItem,
                              additionalItem: e.target.valueAsNumber,
                            },
                          }))
                        }
                      />
                    </InputGroup>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  )
}

export default ShippingPricesEditor
