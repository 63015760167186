import { gql } from '@apollo/client'

export const GET_PRODUCTS = gql`
  query GetProducts($notInPackage: String, $showInactive: Boolean) {
    products(notInPackage: $notInPackage, showInactive: $showInactive) {
      id
      name
      image
      type
      excludeCollections
      includeCollections
      platformType
      supplier
      validation {
        id
        isValid
        message
        invalidVariants
      }
      isActive
      isArchived
      artworkEnabled
      officialMerchEnabled
      printifyInfo {
        price
        maddiesCost
      }
    }
  }
`

export const GET_PRODUCTS_FOR_SPEC = gql`
  query GetProductsForSpec($packageId: String, $officialMerchId: String) {
    productsForSpec(packageId: $packageId, officialMerchId: $officialMerchId) {
      id
      name
      image
      type
      platformType
      isActive
      isArchived
    }
  }
`

export const GET_PRODUCT_BY_ID = gql`
  query GetProductById($id: String) {
    productById(id: $id) {
      id
      name
      image
      type
      excludeCollections
      includeCollections
      isActive
      isArchived
      supplier
      shippingPrices {
        us {
          firstItem
          additionalItem
        }
        canada {
          firstItem
          additionalItem
        }
        others {
          firstItem
          additionalItem
        }
      }
      artworkEnabled
      officialMerchEnabled
      storeCreatorEnabled
      validation {
        id
        isValid
        message
        invalidVariants
      }
      madswagInfo {
        variants {
          color
          size
          shape
          surface
        }
        variantsPropsKey
        variantsProps {
          color {
            label
            variant
            price
            contrast
            maddiesCost
            colorValue
            views {
              id
              image
            }
          }
          size {
            label
            variant
            price
            contrast
            maddiesCost
            colorValue
          }
          shape {
            label
            variant
            price
            contrast
            maddiesCost
            colorValue
          }
          surface {
            label
            variant
            price
            contrast
            maddiesCost
            colorValue
          }
        }
        maddiesCost
        views {
          id
          label
          image
          command
          canvas {
            width
            height
          }
          size {
            width
            height
          }
          viewport {
            width
            height
          }
          offset {
            x
            y
          }
        }
        defaults {
          view
          variant {
            key
            value
          }
        }
        extraLayerCost
      }
      printifyInfo {
        blueprint_id
        provider
        variants {
          color
          size
          shape
          surface
        }
        variantsPropsKey
        variantsProps {
          color {
            label
            variant
            price
            contrast
            maddiesCost
          }
          size {
            label
            variant
            price
            contrast
            maddiesCost
          }
          shape {
            label
            variant
            price
            contrast
            maddiesCost
          }
          surface {
            label
            variant
            price
            contrast
            maddiesCost
          }
        }
        price
        maddiesCost
        imageAdjustment {
          scale
          y
          x
          angle
        }
      }
      platformType
      wooCategories {
        id
        name
        parent
      }
      tags
      showcaseImageIndex
    }
  }
`

export const GET_PRODUCT_IMAGE = gql`
  query GetPrintifyProductImage($blueprintId: Int) {
    printifyProductInfo(blueprintId: $blueprintId) {
      id
      images
    }
  }
`

export const GET_PRINTIFY_PRODUCT_NAME = gql`
  query GetPrintifyProductInfo($blueprintId: Int) {
    printifyProductInfo(blueprintId: $blueprintId) {
      id
      title
      brand
      model
    }
  }
`

export const GET_WOO_CATEGORIES = gql`
  query GetWooCategories {
    getWooCategories {
      id
      name
      parent
    }
  }
`

export const ADD_NEW_PRODUCT = gql`
  mutation AddNewProduct(
    $name: String!
    $image: String!
    $type: String!
    $supplier: String!
    $includeCollections: [String]
    $excludeCollections: [String]
    $printifyInfo: InputPrintifyInfo
    $madswagInfo: InputMadswagInfo
    $platformType: String
    $shippingPrices: ShippingPricesInput
    $isActive: Boolean
    $isArchived: Boolean
    $artworkEnabled: Boolean
    $storeCreatorEnabled: Boolean
    $officialMerchEnabled: Boolean
    $wooCategories: [WooCategoryInput]
    $tags: [String]
    $showcaseImageIndex: Int
  ) {
    addNewProductDef(
      name: $name
      image: $image
      type: $type
      supplier: $supplier
      includeCollections: $includeCollections
      excludeCollections: $excludeCollections
      printifyInfo: $printifyInfo
      madswagInfo: $madswagInfo
      platformType: $platformType
      shippingPrices: $shippingPrices
      isActive: $isActive
      isArchived: $isArchived
      artworkEnabled: $artworkEnabled
      storeCreatorEnabled: $storeCreatorEnabled
      officialMerchEnabled: $officialMerchEnabled
      wooCategories: $wooCategories
      tags: $tags
      showcaseImageIndex: $showcaseImageIndex
    ) {
      id
      name
      type
      excludeCollections
      includeCollections
      image
      printifyInfo {
        blueprint_id
      }
    }
  }
`

export const EDIT_PRODUCT = gql`
  mutation EditProduct(
    $id: String!
    $name: String!
    $image: String
    $type: String!
    $supplier: String!
    $includeCollections: [String]
    $excludeCollections: [String]
    $printifyInfo: InputPrintifyInfo
    $madswagInfo: InputMadswagInfo
    $platformType: String
    $shippingPrices: ShippingPricesInput
    $isActive: Boolean
    $isArchived: Boolean
    $artworkEnabled: Boolean
    $storeCreatorEnabled: Boolean
    $officialMerchEnabled: Boolean
    $wooCategories: [WooCategoryInput]
    $tags: [String]
    $showcaseImageIndex: Int
  ) {
    editProductDef(
      id: $id
      name: $name
      image: $image
      type: $type
      supplier: $supplier
      includeCollections: $includeCollections
      excludeCollections: $excludeCollections
      printifyInfo: $printifyInfo
      madswagInfo: $madswagInfo
      platformType: $platformType
      shippingPrices: $shippingPrices
      isActive: $isActive
      isArchived: $isArchived
      artworkEnabled: $artworkEnabled
      storeCreatorEnabled: $storeCreatorEnabled
      officialMerchEnabled: $officialMerchEnabled
      wooCategories: $wooCategories
      tags: $tags
      showcaseImageIndex: $showcaseImageIndex
    ) {
      id
      name
      type
      excludeCollections
      includeCollections
      printifyInfo {
        blueprint_id
      }
    }
  }
`

export const DELETE_STAGING_PRODUCTS = gql`
  mutation DeleteStagingProducts {
    deleteStagingProducts {
      removedProducts
    }
  }
`

export const DELETE_PRODUCT = gql`
  mutation DeleteProductDef($id: String!) {
    deleteProductDef(id: $id) {
      productId
      collectionsUpdated
    }
  }
`

export const CLONE_PRODUCT_DEF = gql`
  mutation CloneProductDef($id: String!) {
    cloneProductDef(id: $id) {
      id
    }
  }
`
