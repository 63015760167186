import moment from 'moment'
import React from 'react'
import { BsCheck2Square } from 'react-icons/bs'
import { VscTriangleDown, VscTriangleUp } from 'react-icons/vsc'
import { Badge, Table } from 'reactstrap'

const PaymentViewer = ({
  summary,
  period,
  transactionDetails,
  fromAccount,
  toAccount,
  platform,
  vendor,
  totalCommissionsSelected,
  totalPaid,
}) => {
  return (
    <div>
      {period && (
        <h5 className="m-0 mt-2 mb-3 text-center">
          <Badge>
            {period?.startDate &&
              moment.utc(period?.startDate).format('YYYY-MM-DD')}
          </Badge>{' '}
          -{' '}
          <Badge>
            {period?.endDate && (
              <span>{moment.utc(period?.endDate).format('YYYY-MM-DD')}</span>
            )}
          </Badge>
        </h5>
      )}
      <div>
        <Table bordered>
          <tbody>
            <tr>
              <td>
                <b>Summary</b>
              </td>
              <td>{summary}</td>
            </tr>
            <tr>
              <td>
                <b>Transaction Details</b>
              </td>
              <td>{transactionDetails}</td>
            </tr>
            <tr>
              <td>
                <b>
                  From Account{' '}
                  <VscTriangleDown size="1.3em" className="text-danger" />
                </b>
              </td>
              <td>{fromAccount}</td>
            </tr>
            <tr>
              <td>
                <b>
                  To Account{' '}
                  <VscTriangleUp size="1.3em" className="text-success" />
                </b>
              </td>
              <td>{toAccount}</td>
            </tr>
            <tr>
              <td>
                <b>Platform</b>
              </td>
              <td>{platform}</td>
            </tr>
            <tr>
              <td>
                <b>Vendor</b>
              </td>
              <td>{vendor?.userName}</td>
            </tr>
            <tr>
              <td>
                <b>Total</b>
              </td>
              <td>
                <b className="text-success">$ {totalPaid?.toFixed(2)} </b>{' '}
                {totalCommissionsSelected > 0 && (
                  <Badge color="primary" className="float-end">
                    <BsCheck2Square /> {totalCommissionsSelected}
                  </Badge>
                )}
                {totalCommissionsSelected === 0 && (
                  <div className="float-end">
                    <Badge color="primary">all in period</Badge>
                  </div>
                )}
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  )
}

export default PaymentViewer
