export const generateVariantOptions = (variantsLoaded) => {
  const variantOptions = {}
  if (variantsLoaded.length > 0) {
    variantsLoaded.forEach((v) => {
      Object.keys(v.options).forEach((optionKey) => {
        if (optionKey === '__typename') {
          return
        }
        if (v.options[optionKey]) {
          if (!variantOptions[optionKey]) {
            variantOptions[optionKey] = new Set([v.options[optionKey]])
          } else {
            variantOptions[optionKey].add(v.options[optionKey])
          }
        }
      })
    })
  }
  // Transform Set to Array
  Object.keys(variantOptions).forEach((key) => {
    variantOptions[key] = Array.from(variantOptions[key])
  })
  return variantOptions
}
