/* eslint-disable react/prop-types */
import React from 'react'
import {
  ListGroupItem,
  InputGroup,
  InputGroupText,
  Input,
} from 'reactstrap'

const ImgAdjustmentEditor = ({
  propKey,
  propValue,
  onValueChange,
  origValue,
}) => {
  return (
    <ListGroupItem style={{ padding: 0 }}>
      <InputGroup>
          <InputGroupText
            style={{
              minWidth: '4em',
              border: 0,
              borderRadius: 0,
            }}
          >
            {propKey}
          </InputGroupText>
        <Input
          style={{ border: 0 }}
          type="number"
          value={
            typeof propValue === 'number' && !isNaN(propValue) ? propValue : ''
          }
          onChange={onValueChange}
        />
        {propValue !== origValue && origValue && (
            <InputGroupText
              style={{
                minWidth: '6em',
                border: 0,
                borderRadius: 0,
              }}
            >
              old: {origValue}
            </InputGroupText>
        )}
      </InputGroup>
    </ListGroupItem>
  )
}

export default ImgAdjustmentEditor
