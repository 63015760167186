/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import styled from 'styled-components'
import { getLowResolutionImage } from '../../utils'
import FallbackImage from '../../assets/fallbackImage.png'

const Subtitle = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  font-size: 0.7rem;
  background: #000000ad;
  color: white;
  padding: 0.2em;
  border: 1px solid white;
  border-radius: 0 3px 0 3px;
`

const Wrapper = styled.div`
  position: relative;
  text-align: center;
  ${({ borderColor }) => (!borderColor ? 'border-color: #a9a9a9;' : '')}
  ${({ blackBackground }) => (blackBackground ? 'background: #00000047;' : '')}
`
const Image = ({
  src,
  height,
  subtitle,
  blackBackground,
  border = true,
  borderColor,
  lowRes,
  resizeOptions,
}) => {
  const [imageError, setImageError] = useState(false)

  const linkProcessed = lowRes ? getLowResolutionImage(src, resizeOptions) : src

  useEffect(() => {
    setImageError(false)
  }, [src])

  return (
    <Wrapper
      className={
        border
          ? `rounded border ${borderColor ? `border-${borderColor}` : ''}`
          : ''
      }
      border
      blackBackground={blackBackground}
    >
      <LazyLoadImage
        alt="maddies img"
        style={{ width: '100%' }}
        height={height}
        src={imageError ? FallbackImage : linkProcessed} // use normal <img> attributes as props
        onError={() => setImageError(true)}
      />
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
    </Wrapper>
  )
}

Image.propTypes = {
  src: PropTypes.string,
  height: PropTypes.string,
  width: PropTypes.string,
}

export default Image
